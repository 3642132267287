import React, { useState } from 'react';
import AccessControl from '../Dashboard/AccessControl';
import { AGENCY_USER, AGENT, CARRIER, MEMBER } from '../../helpers/constants';
import { activeTabLookUp } from './constants';
import { useTranslation } from 'react-i18next';

export default function MyAccount() {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('personalInfo');

  const myAccountTabs = [
    {
      label: 'Profile Info',
      path: 'personalInfo',
      allowedRoles: [AGENCY_USER, AGENT, CARRIER, MEMBER],
    },
    {
      label: 'Agency Info',
      path: 'agency',
      allowedRoles: [AGENCY_USER, AGENT],
    },
    {
      label: 'KYC',
      path: 'kyc',
      allowedRoles: [],
    },
    {
      label: 'ChangePassword',
      path: 'changePassword',
      allowedRoles: [AGENT, CARRIER, MEMBER, AGENCY_USER],
    },
    {
      label: 'Bank Account Details',
      path: 'bankAccount',
      allowedRoles: [AGENT, AGENCY_USER],
    },
    {
      label: 'Documents',
      path: 'document',
      allowedRoles: [AGENT, AGENCY_USER],
    },
  ];
  return (
    <>
      <div className="d--flex gap--lg profilePageTabe  border-bottom--black-100 m-b--md overflow--auto">
        {myAccountTabs.map(({ path, label, allowedRoles }, index) => {
          return (
            <AccessControl allowedRoles={allowedRoles}>
              <span
                className={`p-l--md p-r--md p-b--md c--pointer font--sm font--600 white-space--nowrap  white--space-none  ${
                  activeTab === path
                    ? 'border-bottom--black text--black'
                    : 'text--black-600'
                }`}
                onClick={() => {
                  setActiveTab(path);
                }}
              >
                {t(`translation:labels.${label}`)}
              </span>
            </AccessControl>
          );
        })}
      </div>
      <div className="d--flex gap--md flex--column profilePage  bg--white radius--sm myAccountContent">
        {activeTabLookUp?.[activeTab]}
      </div>
    </>
  );
}
