import React from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import { useAlert } from '../../../hooks/useAlert';
import { webhookInboundTest } from '../../../api';
import Spinner from '../../../components/Spinner';
import { useAuth } from '../../../hooks/useAuth';
// import { useAuth } from '../../../hooks/useAuth';

const initialFormValues = {
  agentNpn: '',
  consumerEmail: '',
  consumerPhone: '',
};
const ShowNpnInfo = () =>
  // {
  // handleClose = () => {
  //   return;
  // },
  // },
  {
    // const { user } = useAuth();
    // const [webhookData, setWebhookData] = useState(null);
    const { user } = useAuth();

    // const { mutate, isLoading } = useMutation({
    //   mutationFn: (value) =>
    //     handleWebhook({
    //       ...value,
    //       webhook: `https://flow.zoho.com/853586997/flow/webhook/incoming?zapikey=1001.418d3c4d476a5f74b23f4fb7f3b8a200.f8d34031dc3715126d094ac1f986af33&isdebug=false`,
    //     }),
    //   mutationKey: 'handleWebhook',
    //   onSuccess: ({ data }) => {
    //     setWebhookData(data?.data);
    //   },
    //   onError: (error) => {
    //     const errMsg = error?.message ?? 'unexpected error';
    //     showAlert({ type: 'danger', message: errMsg });
    //     setWebhookData(null);
    //   },
    // });

    const { data: webhookData, isLoading } = useQuery({
      queryKey: ['webhookInboundTest'],
      queryFn: () =>
        webhookInboundTest({
          webhookUrl: user?.inboundWebhookUrl,
          agentDetail: {
            agentName: 'Saif Umar',
            agentNpn: '123456789',
            agentEmail: 'saif.umar@yahoo.in',
            agentPhone: '+12345424555',
          },
          consumerDetail: {
            consumerName: 'Pankaj Singh',
            consumerEmail: 'pankaj.singh@gmail.com',
            consumerPhone: '+44562255222555',
          },
        }),
      select: (data) => data?.data,
      enabled: Boolean(user?.inboundWebhookUrl),
    });

    // console.log('🚀 ~ notificationsFromDb:', notificationsFromDb);
    // const { control, handleSubmit } = useForm({
    //   defaultValues: initialFormValues,
    //   mode: 'onChange',
    // });

    // const onSubmit = (values) => {
    //   mutate({ ...values, search: true });
    // };

    // useEffect(() => {
    //   mutate(initialFormValues);

    //   return () => {
    //     setWebhookData(null);
    //   };
    // }, []);

    return (
      <div className="w--full h--full p--md d--flex flex--column gap--md">
        {isLoading ? (
          <div className="d--flex align-items--center justify-content--center h--full w--full">
            <Spinner size="lg" />
          </div>
        ) : webhookData ? (
          <div className="overflow--auto npn-info-json p--sm bg--black radius--sm h--full w--full text--white">
            <pre>
              <code>{JSON.stringify(webhookData, null, 2)}</code>
            </pre>
          </div>
        ) : null}
        {/* <form
        className="d--flex flex--column gap--md"
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
      >
        <div className="d--flex  gap--md">
          <div className="w--full">
            <Controller
              name="agentNpn"
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  type="text"
                  label="Agent NPN"
                  placeholder="Enter NPN"
                  required={false}
                  error={undefined}
                  height="40"
                />
              )}
            />
          </div>
          <div className="w--full">
            <Controller
              name="consumerEmail"
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  type="text"
                  label="Consumer Email"
                  placeholder="Enter Email"
                  required={false}
                  error={undefined}
                  height="40"
                />
              )}
            />
          </div>
          <div className="w--full">
            <Controller
              name="consumerPhone"
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  type="text"
                  required={false}
                  label="Consumer Phone"
                  placeholder="Enter Phone"
                  error={undefined}
                  height="40"
                />
              )}
            />
          </div>
        </div>
        <div className="d--flex justify-content--between gap--md  border-top--primary-100">
          <CancelButton disabled={false} onClick={() => handleClose()}>
            Cancel
          </CancelButton>
          <SubmitButton disabled={false}>Submit</SubmitButton>
        </div>
      </form> */}
      </div>
    );
  };

export default ShowNpnInfo;
