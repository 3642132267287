import React, { useEffect, useRef, useState } from 'react';
import FormInput from '../../components/FormInput';
import { useSocket } from '../../hooks/useSocket';
import { useParams } from 'react-router-dom';
import { useChat } from '../../hooks/useChat';
import { useAuth } from '../../hooks/useAuth';
import Button from '../../components/Button';
import ViewCarrierDetails from '../Claims/View/ViewCarrierDetails';
import { emojiRegex, urlPattern } from '../../helpers/constants';
import Avatar from '../../components/Avatar';
import EmojiPicker from 'emoji-picker-react';
import {
  downloadFileFromURL,
  formatUTCDate,
  generateUniqueId,
} from '../../helpers/functions';
import moment from 'moment';
import FilePreview, { renderIcon } from './FilePreview';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getChatFiles, uploadChatDoc } from '../../api';
import { useAlert } from '../../hooks/useAlert';
import Spinner from '../../components/Spinner';
import useIcons, {
  CrossRectengalIcon,
  GalleryIcon,
  InfoStorkIcon,
  NotesIcon,
} from '../../assets/icons/useIcons';
import Dropdown from '../../components/Dropdown';
import ChatViewDetails from './ChatViewDetails';
import { useTranslation } from 'react-i18next';

let typingTimeout = null;

const ChatFilesPreview = ({
  attachment = null,
  format = 'block',
  msgposition = 'right',
}) => {
  const { DownLoadIcon } = useIcons();
  const [isDownloading, setIsDownloading] = useState(false);

  const downloadbtnCss =
    format === 'block'
      ? 'c--pointer d--flex align-items--center justify-content--center position--absolute top--0 right--0  w-min--20 h-min--20 h-max--20 w-max--20 radius--full bg--black-100 text--black '
      : 'w-min--36 h-min--36 w-max--36 h-max--36 bg--white-400 text--black d--flex align-items--center justify-content--center radius--full';

  const mainDivCss =
    format === 'block'
      ? 'd--flex flex--column position--relative w-min--40 h-min--40 w-max--40 h-max--40 '
      : 'd--flex gap--md w--full align-items--center position--relative';

  if (!attachment) return;

  return (
    <div key={attachment} className={mainDivCss}>
      <div className="w-min--40 h-min--40 w-max--40">
        {renderIcon(attachment?.split('.')?.pop(), true, false)}
      </div>
      {format === 'list' && (
        <span className="w--full font--400 font--sm">{attachment}</span>
      )}
      <div className="c--pointer d--flex align-items--center justify-content--center position--absolute top--0 right--0 ">
        {isDownloading ? (
          <span className="w--full h--full d--flex">
            <Spinner />
          </span>
        ) : (
          <span
            data-tooltip={`Download ${attachment}`}
            tooltip-position={msgposition === 'left' ? 'right' : 'left'}
            className={downloadbtnCss}
            onClick={() =>
              downloadFileFromURL(
                `https://conxent-api.nextere.com/files/${attachment}`,
                setIsDownloading,
              )
            }
          >
            <DownLoadIcon width={format === 'block' ? 16 : 20} />
          </span>
        )}
      </div>
    </div>
  );
};

const ChatFiles = () => {
  const { chatId } = useParams();
  const { data: chatFiles, isLoading } = useQuery({
    queryFn: ({ queryKey }) => getChatFiles(queryKey[1] || {}),
    queryKey: ['getChatFiles', { claimUuId: chatId }],
    enabled: Boolean(chatId),
    select: (data) => data?.data?.data,
  });

  if (isLoading) {
    return (
      <div className="d--flex align-items--center justify-content--center h--full w--full">
        <Spinner size="lg" />
      </div>
    );
  }

  return (
    <div className="d--flex flex--column w--full gap--sm ">
      <div className={`d--flex w--full gap--md flex--column`}>
        {chatFiles && chatFiles?.length > 0
          ? chatFiles?.map((file) => {
              return (
                <div className="d--flex notes-box" key={file?._id}>
                  <div className=" w--full bg--black-50 p--md radius--sm d--flex flex--column gap--sm ">
                    <div className="d--flex  gap--sm align-items--start justify-content--start flex--column gap--md">
                      <ChatFilesPreview
                        attachment={file?.filename}
                        format="list"
                      />
                    </div>
                  </div>
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
};

const LeftMessage = ({ message = {} }) => {
  const { PencilIcon } = useIcons();
  if (message?.isDeleted) return;
  const attachments = message?.attachments ?? [];
  return (
    <div className="incomingMsg w--full m-b--sm">
      <div className="w--sm ">
        <div className="w-max--500 w--full d--flex gap--sm position--relative">
          <div className="d--flex gap--sm">
            <div className="w--auto w-min--75 d--flex flex--column  align-items--start  gap--xs bg--black-50 radius--sm p-r--md p-l--md p-t--sm p-b--sm flex--wrap text--black">
              <div
                className="w--auto  d--flex   line-height--1-dot-5 align-items--center"
                dangerouslySetInnerHTML={{
                  __html: message?.message
                    ?.replace(
                      emojiRegex,
                      '<span class="d--flex word-break--all font--xl">$1</span>',
                    )
                    .replace(
                      urlPattern,
                      '<a href=$1 target="_blank" class="text--underline" >$1</a>',
                    ),
                }}
              ></div>
              {attachments && attachments.length > 0 ? (
                <div className="d--flex gap--sm bg--white p--xs radius--sm">
                  {attachments.map((attachment) => (
                    <ChatFilesPreview
                      key={attachment}
                      attachment={attachment}
                      msgposition="left"
                    />
                  ))}
                </div>
              ) : null}
              <span className=" text--black-600 font--xs text--r w--full">
                {formatUTCDate(message?.createdAt)}
              </span>
            </div>
            {/* <div className="avatarImg w-min--50 w-max--50 h-min--50 h-max--50 radius--full position--absolute bg--white border-full--white p--xs d--flex align-items--center justify-content--center">
              <img src={doctorImg} alt="doctorImg" height="32px" width="32px" className="w--full h-min--40 w-max--40 h-max--40 w-min--40 h--full object-cover radius--full" />
            </div> */}
          </div>
          {message?.isEdited ? (
            <span
              className="text--black-400 font--xs d--flex align-items--center gap--xs"
              data-tooltip={'Message has been edited'}
              tooltip-position={'right'}
            >
              <PencilIcon width={20} height={20} />{' '}
            </span>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const RightMessage = ({ message = {}, setUpdateMessage, setMessage }) => {
  const { t } = useTranslation();

  const { chatId } = useParams();
  const { MoreHIcon, TrashIcon, PencilIcon } = useIcons();
  const { socket = null } = useSocket();

  function handleDropdownAction(message, item) {
    if (item?.value === 'delete') {
      socket?.emit('delete-message', {
        messageId: message?.messageId,
        senderId: message?.senderId,
        receiverId: message?.receiverId,
        chatId,
      });
    }

    if (item?.value === 'update') {
      setMessage(message?.message);
      setUpdateMessage(message?.messageId);
    }
  }

  if (message?.isDeleted) return;

  const attachments = message?.attachments ?? [];
  return (
    <div className="outgoingMsg w--full m-b--sm d--flex justify-content--end ">
      <div className="w--sm  d--flex justify-content--end flex--column align-items--end">
        <div className="w-max--500 gap--sm  w--full d--flex flex--column position--relative row-reverse">
          <div className="d--flex gap--sm">
            <div className="w--auto w-min--75 d--flex align-items--start flex--column gap--xs bg--black-50 text--black radius--sm p-r--md p-l--md p-t--sm p-b--sm flex--wrap">
              <div
                className="w--auto  d--flex   line-height--1-dot-5 align-items--center"
                dangerouslySetInnerHTML={{
                  __html: message?.message
                    ?.replace(
                      emojiRegex,
                      '<span class=" word-break--all font--xl">$1</span>',
                    )
                    .replace(
                      urlPattern,
                      '<a href=$1 target="_blank" class="text--underline" >$1</a>',
                    ),
                }}
              ></div>
              {attachments && attachments.length > 0 ? (
                <div className="d--flex gap--sm bg--white p--xs radius--sm">
                  {attachments.map((attachment) => (
                    <ChatFilesPreview
                      key={attachment}
                      attachment={attachment}
                      msgposition="right"
                    />
                  ))}
                </div>
              ) : null}
              <span className=" text--black-600 font--xs w--full text--r">
                {formatUTCDate(message?.createdAt)}
              </span>
            </div>
          </div>
          {message?.isEdited ? (
            <span
              className="text--black-400 font--xs d--flex align-items--center gap--xs"
              data-tooltip={t('translation:title.Message has been edited')}
              tooltip-position={'left'}
            >
              <PencilIcon width={20} height={20} />{' '}
            </span>
          ) : null}
        </div>

        <div className="p-r--xs w--full text--black-600 m-t--xs d--flex align-items--center row-reverse gap--sm">
          <div className="text--black-600 c--pointer w--full font--xs font--300 w-min--36 w-max--36  radius--sm d--flex align-items--center justify-content--center dotsAction">
            <Dropdown
              closeOnClickOutside={true}
              dropList={{
                component: ({ item }) => {
                  return (
                    <div
                      className="text--secondary p--sm w--full d--flex align-items--center  justify-content--start gap--sm font--2sm"
                      onClick={() => handleDropdownAction(message, item)}
                    >
                      {item?.icon}
                      {t(`translation:button.${item?.title}`)}
                    </div>
                  );
                },

                data: [
                  {
                    title: 'Delete',
                    value: 'delete',
                    id: 1,
                    icon: <TrashIcon width={16} height={16} />,
                  },
                  {
                    title: 'Edit',
                    value: 'update',
                    id: 1,
                    icon: <PencilIcon width={16} height={16} />,
                  },
                ],
              }}
            >
              <MoreHIcon width={16} height={16} />
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  );
};

const InfoBar = ({ setInfoBar, activeTab }) => {
  const { t } = useTranslation();

  const { CrossIcon } = useIcons();
  const { chatId } = useParams();

  return (
    <div className="chatListSec w--full w-max--400 bg--black-25  d--flex flex--column radius--sm">
      <div className="font--md font--600 d--flex align-items--center justify-content--between p--md border-bottom--black-100 gap--lg h-min--60">
        <div className="d--flex w--full gap--sm">
          {activeTab === 1 && t('translation:title.Details')}
          {activeTab === 2 && t('translation:title.Notes')}
          {activeTab === 3 && t('translation:title.Gallery')}
        </div>
        <span
          className="w-min--32 w-max--32 h-min--32 h-max--32 radius--sm bg--black-50 text--black d--flex align-items--center justify-content--center font--lg c--pointer"
          onClick={() => setInfoBar(false)}
        >
          <CrossIcon width={12} height={12} />
        </span>
      </div>
      <div className="w--full h--full overflow--auto chatList claimDetailsList p--md d--flex flex--column">
        {activeTab === 3 ? (
          <ChatFiles />
        ) : (
          <ChatViewDetails rowData={{ _id: chatId }} activeTab={activeTab} />
        )}
      </div>
    </div>
  );
};

const ChatArea = () => {
  const { t } = useTranslation();

  const { showAlert } = useAlert();
  const { SendIcon, SmileIcon, CrossIcon, AttachmentIcon } = useIcons();
  const [isTyping, setIsTyping] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isUploadingFiles, setIsUploadingFiles] = useState(false);
  const [emojiSelection, setEmojiSelection] = useState(false);
  const textAreaRef = useRef(null);
  const [infoBar, setInfoBar] = useState(true);
  const [message, setMessage] = useState('');
  const {
    socket = null,
    handleTyping = () => {
      return;
    },
  } = useSocket();
  const { chats, messageList, typingList } = useChat();
  const { user } = useAuth();
  const { chatId } = useParams();
  const bottomRef = useRef(null);
  const filePicker = useRef(null);
  const [updateMessage, setUpdateMessage] = useState(null);
  const [activeTab, setActiveTab] = useState(1);

  const { mutateAsync } = useMutation({
    mutationFn: uploadChatDoc,
    mutationKey: 'uploadChatDoc',
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      setSelectedFiles([]);
      setIsUploadingFiles(false);
      showAlert({ type: 'danger', message: errMsg });
    },
  });

  const currentChatUser =
    chats && chats?.length > 0
      ? chats
          ?.filter((c) => c?.chatId === chatId)?.[0]
          ?.users?.filter((u) => u?.id !== user?._id)?.[0] ?? {}
      : {};

  const currentChatMessages =
    messageList && messageList?.length > 0
      ? messageList?.filter((c) => c?.chatId === chatId)?.[0]?.messages ?? []
      : [];

  const typingUser =
    typingList && typingList?.length > 0
      ? typingList?.filter((c) => c?.chatId === chatId)?.[0]?.users ?? {}
      : {};

  useEffect(() => {
    if (chatId) {
      setActiveTab(chatId.includes('_') ? 3 : 1);
      if (currentChatMessages && currentChatMessages?.length === 0) {
        socket?.emit('get-chat-messages', {
          chatId,
          userID: user?._id,
        });
      }
    }
  }, [chatId, socket]);

  useEffect(() => {
    if (updateMessage && textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, [updateMessage]);

  function handleMessageChange(e) {
    setMessage(e.target.value);

    if (!isTyping) {
      setIsTyping(true);
      handleTyping({
        chatId,
        receiverId: currentChatUser?.id,
        [user?._id]: true,
      });
    }

    if (typingTimeout) {
      clearTimeout(typingTimeout);
      typingTimeout = setTimeout(() => {
        setIsTyping(false);
        handleTyping({
          chatId,
          receiverId: currentChatUser?.id,
          [user?._id]: false,
        });
      }, 3000);
    } else {
      typingTimeout = setTimeout(() => {
        setIsTyping(false);
        handleTyping({
          chatId,
          receiverId: currentChatUser?.id,
          [user?._id]: false,
        });
      }, 3000);
    }
  }

  async function handleSendMessage() {
    if (isUploadingFiles) return;
    if (!message && !selectedFiles?.length) return;
    const messageToSend = message;
    let filesToUpload = [];
    setMessage('');

    let attachments = [];

    if (selectedFiles && selectedFiles.length > 0) {
      setIsUploadingFiles(true);
      filesToUpload = selectedFiles;
      setSelectedFiles([]);

      for (let i = 0; i < filesToUpload.length; i++) {
        const file = filesToUpload[i];
        const formData = new FormData();
        formData.append('file', file);
        formData.append('claimUuId', chatId);
        const data = await mutateAsync(formData);
        if (data?.data?.success) {
          attachments.push(data?.data?.data?.filename);
        }
      }

      setIsUploadingFiles(false);
    }

    if (updateMessage) {
      socket?.emit('update-message', {
        messageId: updateMessage,
        senderId: user?._id,
        receiverId: currentChatUser?.id,
        chatId,
        message: messageToSend,
      });
      setUpdateMessage(null);
    } else {
      socket?.emit('send-message', {
        chatId,
        message: messageToSend,
        attachments,
        senderId: user?._id,
        receiverId: currentChatUser?.id,
        messageId: generateUniqueId(),
        createdAt: moment().format('YYYY-MM-DD[T]HH:mm:ss.SSSZZ'),
      });
    }
  }

  function handleEmoji(e) {
    setEmojiSelection(false);
    const element = textAreaRef.current;
    if (element) {
      const messageWithEmoji = element.value + ' ' + e?.emoji;
      element.innerText = messageWithEmoji;
      setMessage(messageWithEmoji);
      element.focus();
    }
  }

  function handleFileSelect(e) {
    const files = e?.target?.files ?? {};

    if (selectedFiles && selectedFiles.length > 0) {
      setSelectedFiles((prev) => [...prev, ...files]);
    } else {
      setSelectedFiles([...files]);
    }
    e.target.value = null;
  }

  function handleRemovefile(fileToRemove) {
    if (!selectedFiles?.length) return;

    const filteredFiles = selectedFiles?.filter(
      (_, index) => index !== fileToRemove,
    );
    setSelectedFiles(filteredFiles);
  }

  const handleKeyDown = (e) => {
    if (e.shiftKey && e.key === 'Enter') return;
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSendMessage();
    }
  };

  useEffect(() => {
    bottomRef.current?.scrollIntoView();
  }, [currentChatMessages?.length]);

  const filePreviews = selectedFiles && selectedFiles.length > 0;

  return (
    <div className="d--flex  border-full--black-100 radius--sm w--full">
      <div className="w--full chatRightContent bg--white radius--sm d--flex flex--column ">
        <div className="chatHead h--full h-max--60 border-bottom--black-100 d--flex p-t--md p-b--md p-l--md p-r--md">
          <div className="d--flex gap--sm  align-items--center w--full ">
            <Avatar
              firstname={currentChatUser?.firstname ?? ''}
              lastname={currentChatUser?.lastname ?? ''}
              userId={currentChatUser?.id}
            />

            <div className="w--full d--flex flex--column  gap--xs">
              <div className="w--full font--sm font--500 ">
                {currentChatUser?.firstname ?? ''}{' '}
                {currentChatUser?.lastname ?? ''}
              </div>
              {typingUser?.[currentChatUser?.id] ? (
                <div className="w--full font--400 text--success">
                  {t('translation:button.Typing...')}
                </div>
              ) : null}
            </div>
          </div>
          <div className="w--full w--max--300 d--flex justify-content--end align-items--center">
            <div
              data-tooltip={t('translation:title.View Gallery')}
              tooltip-position={'top'}
              className="text--black c--pointer w--full font--xs font--300 w-min--36 w-max--36 h-min--36 h-max--36 radius--sm d--flex align-items--center justify-content--center"
              onClick={() => {
                setActiveTab(3);
                setInfoBar(true);
              }}
            >
              <GalleryIcon width={24} height={24} />
            </div>

            {!chatId.includes('_') ? (
              <>
                <div
                  data-tooltip={t('translation:title.View Notes')}
                  tooltip-position={'top'}
                  className="text--black c--pointer w--full font--xs font--300 w-min--36 w-max--36 h-min--36 h-max--36 radius--sm d--flex align-items--center justify-content--center"
                  onClick={() => {
                    setActiveTab(2);
                    setInfoBar(true);
                  }}
                >
                  <NotesIcon width={24} height={24} />
                </div>
                <div
                  data-tooltip={t('translation:title.View Info')}
                  tooltip-position={'top'}
                  className="text--black c--pointer w--full font--xs font--300 w-min--36 w-max--36 h-min--36 h-max--36 radius--sm d--flex align-items--center justify-content--center"
                  onClick={() => {
                    setActiveTab(1);
                    setInfoBar(true);
                  }}
                >
                  <InfoStorkIcon width={24} height={24} />
                </div>{' '}
              </>
            ) : null}
          </div>
        </div>
        <div className="chatContent claimChatPageContent  overflow--auto h--full p-t--md p-b--md p-l--xl p-r--xl position--relative">
          {currentChatMessages && currentChatMessages?.length > 0
            ? currentChatMessages?.map((m, index, arr) => {
                const currentSMSDate = moment(m?.createdAt);
                const nextSMSDate = arr?.[index + 1]
                  ? moment(arr?.[index + 1]?.createdAt)
                  : null;
                let isSameDate = true;
                if (nextSMSDate && currentSMSDate) {
                  isSameDate = currentSMSDate.isSame(nextSMSDate, 'day');
                }

                if (m?.senderId === user?._id) {
                  return (
                    <>
                      {index === 0 && (
                        <div className="w--full d--flex justify-content--center position--relative border-bottom--black-100 m-b--sm m-t--sm">
                          <div
                            className="dayItem m-b--xs  w-max--100 text--black radius--xs p-t--xs p-b--xs p-l--md p-r--md  font--2sm  z-index--md text--c position--absolute bg--white"
                            id="date-time-label-box"
                          >
                            {currentSMSDate?.format('DD MMMM')}
                          </div>
                        </div>
                      )}
                      <RightMessage
                        message={m}
                        updateMessage={updateMessage}
                        setUpdateMessage={setUpdateMessage}
                        setMessage={setMessage}
                      />
                      {!isSameDate && (
                        <div className="w--full d--flex justify-content--center position--relative border-bottom--black-100 m-b--sm m-t--sm">
                          <div
                            className="dayItem m-b--xs  w-max--100 text--black radius--xs p-t--xs p-b--xs p-l--md p-r--md  font--2sm  z-index--md text--c position--absolute bg--white"
                            id="date-time-label-box"
                          >
                            {nextSMSDate?.format('DD MMMM')}
                          </div>
                        </div>
                      )}
                    </>
                  );
                } else {
                  return (
                    <>
                      {index === 0 && (
                        <div className="w--full d--flex justify-content--center position--relative border-bottom--black-100 m-b--sm m-t--sm">
                          <div
                            className="dayItem m-b--xs  w-max--100 text--black radius--xs p-t--xs p-b--xs p-l--md p-r--md  font--2sm  z-index--md text--c position--absolute bg--white"
                            id="date-time-label-box"
                          >
                            {currentSMSDate?.format('DD MMMM')}
                          </div>
                        </div>
                      )}
                      <LeftMessage message={m} />
                      {!isSameDate && (
                        <div className="w--full d--flex justify-content--center position--relative border-bottom--black-100 m-b--sm m-t--sm">
                          <div
                            className="dayItem m-b--xs  w-max--100 text--black radius--xs p-t--xs p-b--xs p-l--md p-r--md  font--2sm  z-index--md text--c position--absolute bg--white"
                            id="date-time-label-box"
                          >
                            {nextSMSDate?.format('DD MMMM')}
                          </div>
                        </div>
                      )}
                    </>
                  );
                }
              })
            : null}
          <div ref={bottomRef} />
        </div>
        <div className="chatFooter h--full h-max--60  p-t--sm p-b--sm  p-l--md p-r--md d--flex gap--sm position--relative">
          {filePreviews ? (
            <div className=" radius--sm p--xs w--full h--auto bg--white position--absolute bottom--60 left--0">
              <div className="border-full--black-100  p--sm w--full h--full radius--sm d--flex align-items--center justify-content--between gap--md  overflow--x-auto">
                {selectedFiles && selectedFiles.length > 0 ? (
                  <div className="d--flex align-items--center gap--sm  ">
                    {selectedFiles.map((file, index) => (
                      <div
                        key={file?.name}
                        className="w-min--100 w-max--100 h-min--100 h-max--100 object--contain radius--sm bg--black-100 position--relative"
                      >
                        <FilePreview file={file} />
                        <span
                          className="c--pointer text--danger position--absolute top--0 right--0"
                          onClick={() => handleRemovefile(index)}
                        >
                          <CrossRectengalIcon width={20} height={20} />
                        </span>
                      </div>
                    ))}
                  </div>
                ) : (
                  "You don't have any files to send."
                )}
              </div>
            </div>
          ) : null}
          <div className="d--flex align-items--center w--full position--relative">
            <div className="text--black-600 position--absolute left--5 c--pointer w--full font--xs font--300 w-min--36 w-max--36  radius--sm d--flex align-items--center justify-content--center ">
              <span
                className="z-index--sm"
                onClick={() => setEmojiSelection(true)}
              >
                <SmileIcon width={20} height={20} />
              </span>
              {emojiSelection ? (
                <div className="position--absolute left--0 bottom--60">
                  <span
                    className="position--absolute top---15 right---15 w-min--28 w-max--28 h-min--28 h-max--28 radius--full bg--danger-200 text--danger d--flex align-items--center justify-content--center font--lg c--pointer z-index--sm"
                    onClick={() => setEmojiSelection(false)}
                  >
                    <CrossIcon width={14} height={14} />
                  </span>
                  <EmojiPicker
                    lazyLoadEmojis={true}
                    onEmojiClick={handleEmoji}
                    emojiStyle="native"
                    defaultSkinTone="neutral"
                    skinTonesDisabled={true}
                    width={350}
                    height={450}
                    previewConfig={{ showPreview: false }}
                    categories={['smileys_people', 'food_drink', 'activities']}
                    className="custom-emoji-picker"
                  />
                </div>
              ) : null}
            </div>
            <div className="w--full">
              <FormInput
                ref={textAreaRef}
                name="search"
                extraClasses=""
                paddingLeft={'2xl'}
                paddingRight={'3xl'}
                placeholder="Type your Message...."
                value={message}
                onKeyDown={(e) => handleKeyDown(e)}
                onChange={(e) => handleMessageChange(e)}
              />
            </div>
            {!updateMessage && !chatId.includes('_') && (
              <div
                className="text--black-600 position--absolute right--10 c--pointer w--full font--xs font--300 w-min--36 w-max--36  radius--sm d--flex align-items--center justify-content--center"
                onClick={() => filePicker?.current?.click()}
              >
                <AttachmentIcon width={20} height={20} />
                <input
                  ref={filePicker}
                  multiple
                  hidden
                  type="file"
                  accept=""
                  id="filePicker"
                  onChange={(e) => handleFileSelect(e)}
                />
              </div>
            )}
          </div>
          <div className="d--flex gap--sm align-items--center ">
            {isUploadingFiles ? (
              <div
                className={`c--pointer w--full font--xs font--300 w-min--36 w-max--36 h-min--36 h-max--36  text--white  radius--full d--flex align-items--center justify-content--center`}
              >
                <Spinner />
              </div>
            ) : (
              <div
                className={`c--pointer w--full font--xs font--300 w-min--40 w-max--40 h-min--40 h-max--40 ${
                  !message && !selectedFiles?.length
                    ? 'bg--primary-400'
                    : 'bg--primary'
                } text--black  radius--sm d--flex align-items--center justify-content--center`}
                onClick={() => handleSendMessage()}
              >
                <SendIcon width={20} height={20} />
              </div>
            )}
          </div>
        </div>
      </div>

      {infoBar ? (
        <InfoBar
          isOpen={infoBar}
          setInfoBar={setInfoBar}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      ) : null}
    </div>
  );
};

export default ChatArea;
