import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import FormInput from '../../../../components/FormInput';
import Button from '../../../../components/Button';
import {
  IBAN,
  requiredFieldFunction,
} from '../../../../helpers/yupValidations';
import { useTranslation } from 'react-i18next';
import { getObjectLength, submitBtnTxt } from '../../../../helpers/functions';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getBankInfo, setBankInfo } from '../../actions';
import { useAlert } from '../../../../hooks/useAlert';

const initialValues = {
  nameOfBank: '',
  accountName: '',
  bankAccountNumber: '',
  bicCode: '',
};

const validationSchema = yup.object().shape({
  nameOfBank: requiredFieldFunction('Name of Bank'),
  accountName: requiredFieldFunction('Account Name'),
  bankAccountNumber: IBAN,
  bicCode: requiredFieldFunction('Code'),
});

export default function BankDetails() {
  const { t } = useTranslation();
  const { showAlert } = useAlert();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onSubmit',
  });
  
  const { data } = useQuery({
    queryKey: 'getBankInfo',
    queryFn: getBankInfo,
    select: (data) => data?.data?.data,
  });

  const { mutate, isLoading } = useMutation({
    mutationKey: ['setBankInfo'],
    mutationFn: setBankInfo,
    onSuccess: ({ data }) => {
      const { message = 'Bank saved successfully.' } = data?.data || {};
      showAlert({
        type: 'success',
        message,
      });
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'danger', message: errMsg });
    },
  });

  const onSubmit = (_) => mutate(_);

  useEffect(() => {
    if (data && getObjectLength(data?.bankInformation)) {
      reset(data?.bankInformation);
    }
  }, [data]);
  return (
    <div className="w--full d--flex gap--lg flex--column-xs">
      <div className="d--flex flex--column border-full--black-100 radius--sm w--full">
        <div className="label--control font--md font--500   border-bottom--secondary-100 h-min--60 p-l--lg p-r--md d--flex align-items--center ">
          {t('translation:labels.Bank Account Details')}
        </div>
        <form
          className="w--full d--flex flex--column gap--md p--lg"
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
        >
          <Controller
            name="nameOfBank"
            control={control}
            render={({ field }) => (
              <FormInput
                {...field}
                label="Name of Bank"
                error={errors?.nameOfBank?.message}
              />
            )}
          />
          <Controller
            name="accountName"
            control={control}
            render={({ field }) => (
              <FormInput
                {...field}
                label="Account Name"
                error={errors?.accountName?.message}
              />
            )}
          />
          <Controller
            name="bankAccountNumber"
            control={control}
            render={({ field }) => (
              <FormInput
                {...field}
                label="International Bank Account Number (IBAN)"
                error={errors?.bankAccountNumber?.message}
              />
            )}
          />
          <Controller
            name="bicCode"
            control={control}
            render={({ field }) => (
              <FormInput
                {...field}
                label="SWIFT,BIC or routing code"
                error={errors?.bicCode?.message}
              />
            )}
          />

          <div className="w--full d--flex align-items--center justify-content--end">
            <Button
              btnClasses="btn  w-max--150 text--black"
              variant="primary"
              color="black"
              type="submit"
              disabled={isLoading}
            >
              {submitBtnTxt(isLoading)}
            </Button>
          </div>
        </form>
      </div>
      <div className="d--flex flex--column  radius--sm w--full"></div>
    </div>
  );
}
