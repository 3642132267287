import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import FormInput from '../../../../../components/FormInput';
import PhoneInput from 'react-phone-number-input';
import { updateConsumer } from '../../../../../api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuth } from '../../../../../hooks/useAuth';
import * as yup from 'yup';
import {
  email,
  firstName,
  lastName,
  phone,
  requiredFieldFunction,
} from '../../../../../helpers/yupValidations';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAlert } from '../../../../../hooks/useAlert';
import Button from '../../../../../components/Button';
import { UserFill } from '../../../../../assets/icons/useIcons';
import FormSelect from '../../../../../components/FormSelect';
import { City, State } from 'country-state-city';
import useUploadFile from '../../../../../hooks/apiHooks';
import { submitBtnTxt } from '../../../../../helpers/functions';
import { useTranslation } from 'react-i18next';

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  dob: '',
  state: '',
  city: '',
};
const validationSchema = yup.object().shape({
  firstName,
  lastName,
  email,
  phone,
  dob: requiredFieldFunction('Date of birth'),
  state: requiredFieldFunction('State'),
  city: requiredFieldFunction('City'),
});

const statesList = State.getStatesOfCountry('US')?.map((state) => ({
  value: state.isoCode,
  label: state.name,
}));
export default function MemberForm() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { showAlert } = useAlert();
  const [cityList, setCityList] = useState([]);

  const queryClient = useQueryClient();

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
  });

  const { mutate, isLoading } = useMutation({
    mutationKey: ['updateConsumer'],
    mutationFn: updateConsumer,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ['getUserDetails'],
        exact: false,
      });
      showAlert({
        type: 'success',
        message: data?.data?.data?.message || 'Profile updated successfully"',
      });
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'danger', message: errMsg });
    },
  });

  const onSubmit = (data) => {
    mutate(data);
  };

  let [watchState] = watch(['state']);

  useEffect(() => {
    if (watchState) {
      const cities = City.getCitiesOfState('US', watchState).map((city) => ({
        value: city.name,
        label: city.name,
      }));
      setCityList(cities);
    }
  }, [watchState]);
  const imgUrl = `${process.env.REACT_APP_BASE_API_URL}/media/${user?._id}/profile/${user?.profilePic}`;
  useEffect(() => {
    if (user) {
      const {
        firstName = '',
        middleName = '',
        lastName = '',
        email = '',
        phone = '',
        dob = '',
        state = '',
        city = '',
      } = user || {};
      reset({
        firstName,
        middleName,
        lastName,
        email,
        phone,
        dob,
        state,
        city,
      });
    }
  }, [user]);
  return (
    <div className="w--full">
      <div className="d--flex flex--column gap--lg radius--sm border-full--black-100 p--md flex--column">
        <form
          className="w--full d--flex flex--column  gap--md"
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
        >
          {/* <div className="d--flex justify-content--between w--full">
            <div className="d--flex gap--md align-items--center w--full">
              <label
                htmlFor="upload_img"
                className="w-min--75 h-min--75 w-max--75 h-max--75 radius--full bg--black-25 p--sm d--flex align-items--center justify-content--center"
              >
                <input
                  type="file"
                  accept="image/png,image/jpg"
                  hidden
                  onChange={(e) => {
                    setFile(e.target.files[0]);
                  }}
                  id="upload_img"
                />
                <div className="w-min--60 h-min--60 w-max--60 h-max--60 radius--full bg--black-50 p--sm d--flex align-items--center justify-content--center c--pointer">
                  {imgUrl ? (
                    <img
                      src={file ? URL.createObjectURL(file) : imgUrl}
                      alt="Profile Pic"
                      className="w--full h--full object-cover"
                      width={40}
                    />
                  ) : (
                    <UserFill width={30} height={30} />
                  )}
                </div>
              </label>
              <div className="d--flex flex--column gap--xs ">
                <Button
                  btnClasses="btn  w-max--140 font--2sm"
                  variant="primary-200"
                  color="black"
                  disabled={uploadMediaLoading || !file}
                  type="button"
                  size="sm"
                  borderColor="primary"
                  isOutline
                  rounded
                  onClick={async () => {
                    const res = await uploadMedia({
                      file,
                      type: 'profile',
                    });
                    setValue('profilePic', res.fileName);

                    handleSubmit(onSubmit)();
                  }}
                >
                  {uploadMediaLoading ? 'Please wait...' : 'Upload Photo'}
                </Button>
                <div className="font--xs text--black-600">
                  PNG or JPG max size of 5MB
                </div>
              </div>
            </div>
          </div> */}
          <div className=" d--flex flex--column gap--md ">
            <div className="d--flex gap--md flex--column-xs">
              <div className="w--full">
                <Controller
                  name="firstName"
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      {...field}
                      type="text"
                      label="First Name"
                      maxLength={30}
                      error={errors?.firstName?.message}
                    />
                  )}
                />
              </div>
              <div className="w--full">
                <Controller
                  name="middleName"
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      {...field}
                      type="text"
                      label="Middle Name"
                      placeholder="Enter middle name"
                      error={errors?.middleName?.message}
                      height="40"
                      required={false}
                    />
                  )}
                />
              </div>
              <div className="w--full">
                <Controller
                  name="lastName"
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      {...field}
                      type="text"
                      label="Last Name"
                      maxLength={30}
                      error={errors?.lastName?.message}
                    />
                  )}
                />
              </div>
              <div className="w--full">
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      {...field}
                      type="text"
                      label="Email"
                      error={errors?.email?.message}
                      disabled={true}
                      extraClasses="c--not-allowed"
                    />
                  )}
                />
              </div>
            </div>

            <div className="d--flex gap--md flex--column-xs">
              <div className="w--full">
                <label
                  className={`label--control font--sm font--500 m-b--sm d--inline-block ${
                    errors?.phone?.message ? 'text--danger' : 'text--grey'
                  }`}
                >
                  {errors?.phone?.message
                    ? t(`translation:errors.${errors?.phone?.message}`)
                    : t(`translation:labels.Phone`)}
                </label>
                <Controller
                  name="phone"
                  control={control}
                  render={({ field }) => (
                    <PhoneInput
                      {...field}
                      control={control}
                      defaultCountry={watch('country')}
                      international={true}
                      addInternationalOption={false}
                      withCountryCallingCode={true}
                      countryCallingCodeEditable={false}
                      placeholder="Enter your phone"
                      internationalIcon={() => null}
                      flagComponent={() => null}
                      countrySelectComponent={() => null}
                      value={getValues(`phone`)}
                      limitMaxLength={true}
                      onChange={(phone) =>
                        setValue(`phone`, phone, {
                          shouldValidate: true,
                        })
                      }
                      className={`form--control w--full h-min--36  radius--sm p-l--md p-r--md  ${
                        errors?.phone?.message
                          ? 'border-full--danger'
                          : 'border-full--black-200'
                      }`}
                    />
                  )}
                />
              </div>
              <div className="w--full">
                <Controller
                  name="dob"
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      {...field}
                      type="Date"
                      label="Date of birth"
                      error={errors?.dob?.message}
                    />
                  )}
                />
              </div>

              <div className="w--full">
                <Controller
                  name="state"
                  control={control}
                  rules={{
                    onChange() {
                      setValue('city', '');
                    },
                  }}
                  render={({ field }) => (
                    <FormSelect
                      {...field}
                      label="State"
                      error={errors?.state?.message}
                      height="40"
                      options={statesList}
                      placeholder="Select State"
                    />
                  )}
                />
              </div>
              <div className="w--full">
                <Controller
                  name="city"
                  control={control}
                  render={({ field }) => (
                    <FormSelect
                      {...field}
                      label="City"
                      error={errors?.city?.message}
                      height="40"
                      options={cityList}
                      placeholder="Select City"
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="w--full d--flex align-items--center justify-content--end m-t--md">
            <Button
              btnClasses="btn  w-max--140 text--black"
              variant="primary"
              color="black"
              disabled={isLoading}
              type="submit"
            >
              {submitBtnTxt(isLoading, t('translation:button.UpdateProfile'))}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
