import { useCallback, useState, useTransition } from 'react';
import useIcons, {
  ClaimCompletedIcon,
  ClaimInprogressIcon,
  ClaimNewIcon,
  ClaimTotalIcon,
  ClaimUholdIcon,
  SignedConsents,
  SignedEligibilities,
} from '../../../assets/icons/useIcons';
import { useQuery } from '@tanstack/react-query';
import { getDashboardSates } from '../actions';
import ViewCarrierDetails from '../../Claims/View/ViewCarrierDetails';
import ReplyClaim from '../../Claims/View/ReplyClaim';
import AccessControl from '../AccessControl';
import { AGENCY_USER, AGENT, CARRIER } from '../../../helpers/constants';
import CustomChart from '../../../components/Chart';
import AsideModal from '../../../components/AsideModal';
import Modal from '../../../components/Modal';
import UploadClaim from '../../Claims/View/UploadClaim';
import { useTranslation } from 'react-i18next';

export default function AllDashboard() {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [rowData, setRowData] = useState(false);

  const { AgentsIcon, MemberIcon } = useIcons();

  const { data: dashboardData } = useQuery({
    queryKey: 'getDashboardSates',
    queryFn: () => getDashboardSates(),
    select: (data) => data?.data?.data,
  });
  // const { data: dashboardData } = useQuery({
  //   queryKey: 'getDashboardClaimSates',
  //   queryFn: () => getDashboardClaimSates(),
  //   select: (data) => data?.data?.data,
  // });
  const {
    dashboardSatesResponseData,
    totalAgents = 0,
    totalMembers = 0,
    totalSignedConsents = 0,
    totalSendConsents = 0,
    totalSignedEligibilities = 0,
    totalSendEligibilities = 0,
    totalClaim = 0,
    totalCompletedClaim = 0,
    totalInProgressClaim = 0,
    totalNewClaim = 0,
    totalOnHoldClaim = 0,
  } = dashboardData || {};

  const handleClose = useCallback(() => {
    setOpenModal(false);
  }, []);

  const modalLookUp = {
    view: {
      component: (
        <ViewCarrierDetails handleClose={handleClose} rowData={rowData} />
      ),
      width: 'lg',
      size: 1200,
      title: 'Claim Details',
    },
    reply: {
      component: <ReplyClaim handleClose={handleClose} rowData={rowData} />,
      width: 'lg',
      size: 1200,
      title: 'Claim Chat',
    },
  };
  return (
    <div className="w--full h--full d--flex flex--column gap--lg dashboard-page ">
      <div className="d--flex w--full gap--lg">
        <div className="d--flex flex--column w--full gap--lg">
          <div className="d--flex align-items--center justify-content--start gap--lg flex--column-xs grid--2-xs">
            <AccessControl allowedRoles={[CARRIER]}>
              <div className="border-full--black-100 w--full bg--white radius--md p--md gap--md d--flex  align-items--center justify-content--start  h-max--100 h--full c--pointer overflow--hidden dashboard-page-item">
                <div className="dashboard-page-item-icon bg--info-100 text--info h--full w--full w-max--50 h-min--50 radius--full d--flex  align-items--center justify-content--center">
                  <ClaimTotalIcon width={30} height={30} />
                </div>
                <div className="dashboard-page-item-content">
                  <h3 className="m-b--xs font--2xl font--600 text--black">
                    {' '}
                    {totalClaim}
                  </h3>
                  <h6 className=" font--2sm font--400 text--secondary">
                    Total Claim
                  </h6>
                </div>
              </div>
            </AccessControl>
            <AccessControl allowedRoles={[CARRIER]}>
              <div className="border-full--black-100 w--full bg--white radius--md p--md gap--md d--flex  align-items--center justify-content--start  h-max--100 h--full c--pointer overflow--hidden dashboard-page-item">
                <div className="dashboard-page-item-icon bg--primary-200 text--success-800 h--full w--full w-max--50 h-min--50 radius--full d--flex  align-items--center justify-content--center">
                  <ClaimNewIcon width={30} height={30} />
                </div>
                <div className="dashboard-page-item-content">
                  <h3 className="m-b--xs font--2xl font--600 text--black">
                    {' '}
                    {totalNewClaim}
                  </h3>
                  <h6 className=" font--2sm font--400 text--secondary">New</h6>
                </div>
              </div>
            </AccessControl>
            <AccessControl allowedRoles={[CARRIER]}>
              <div className="border-full--black-100 w--full bg--white radius--md p--md gap--md d--flex  align-items--center justify-content--start  h-max--100 h--full c--pointer overflow--hidden dashboard-page-item">
                <div className="dashboard-page-item-icon bg--warning-100 text--warning h--full w--full w-max--50 h-min--50 radius--full d--flex  align-items--center justify-content--center">
                  <ClaimInprogressIcon width={30} height={30} />
                </div>
                <div className="dashboard-page-item-content">
                  <h3 className="m-b--xs font--2xl font--600 text--black">
                    {' '}
                    {totalInProgressClaim}
                  </h3>
                  <h6 className=" font--2sm font--400 text--secondary">
                    In Progress{' '}
                  </h6>
                </div>
              </div>
            </AccessControl>
            <AccessControl allowedRoles={[CARRIER]}>
              <div className="border-full--black-100 w--full bg--white radius--md p--md gap--md d--flex  align-items--center justify-content--start  h-max--100 h--full c--pointer overflow--hidden dashboard-page-item">
                <div className="dashboard-page-item-icon bg--danger-100 text--danger h--full w--full w-max--50 h-min--50 radius--full d--flex  align-items--center justify-content--center">
                  <ClaimUholdIcon width={30} height={30} />
                </div>
                <div className="dashboard-page-item-content">
                  <h3 className="m-b--xs font--2xl font--600 text--black">
                    {totalOnHoldClaim}{' '}
                  </h3>
                  <h6 className=" font--2sm font--400 text--secondary">
                    On Hold{' '}
                  </h6>
                </div>
              </div>
            </AccessControl>
            <AccessControl allowedRoles={[CARRIER]}>
              <div className="border-full--black-100 w--full bg--white radius--md p--md gap--md d--flex  align-items--center justify-content--start  h-max--100 h--full c--pointer overflow--hidden dashboard-page-item">
                <div className="dashboard-page-item-icon bg--success-100 text--success h--full w--full w-max--50 h-min--50 radius--full d--flex  align-items--center justify-content--center">
                  <ClaimCompletedIcon width={30} height={30} />
                </div>
                <div className="dashboard-page-item-content">
                  <h3 className="m-b--xs font--2xl font--600 text--black">
                    {' '}
                    {totalCompletedClaim}
                  </h3>
                  <h6 className=" font--2sm font--400 text--secondary">
                    Completed{' '}
                  </h6>
                </div>
              </div>
            </AccessControl>
            {/* <AccessControl allowedRoles={[AGENCY_USER]}>
              <div className="border-full--black-100 w--full bg--white radius--md p--md gap--md d--flex  align-items--center justify-content--start  h-max--100 h--full c--pointer overflow--hidden dashboard-page-item">
                <div className="dashboard-page-item-icon bg--black-50 h--full w--full w-max--50 h-min--50 h-max--50 radius--full d--flex  align-items--center justify-content--center">
                  <AgencyIcon width={30} height={30} />
                </div>
                <div className="dashboard-page-item-content">
                  <h3 className="m-b--sm font--lg font--600"> {totalAgencies}</h3>
                  <h6 className=" font--sm font--400">Agencies</h6>
                </div>
              </div>
            </AccessControl> */}

            <AccessControl allowedRoles={[AGENCY_USER]}>
              <div className="border-full--black-100 w--full bg--white radius--md p--md gap--md d--flex  align-items--center justify-content--start  h-max--100 h--full c--pointer overflow--hidden dashboard-page-item">
                <div className="dashboard-page-item-icon bg--black-50 h--full w--full w-max--50 h-min--50 h-max--50 radius--full d--flex  align-items--center justify-content--center">
                  <AgentsIcon width={30} height={30} />
                </div>
                <div className="dashboard-page-item-content">
                  <h3 className="m-b--xs font--2xl font--600 text--black">
                    {' '}
                    {totalAgents || 0}
                  </h3>
                  <h6 className=" font--2sm font--400 text--secondary">
                    {t('translation:header.Agents')}
                  </h6>
                </div>
              </div>
            </AccessControl>

            <AccessControl allowedRoles={[AGENCY_USER, AGENT]}>
              <div className="border-full--black-100 w--full bg--white radius--md p--md gap--md d--flex  align-items--center justify-content--start  h-max--100 h--full c--pointer overflow--hidden dashboard-page-item">
                <div className="dashboard-page-item-icon bg--black-50 h--full w--full w-max--50 h-min--50 h-max--50 radius--full d--flex  align-items--center justify-content--center">
                  <MemberIcon width={30} height={30} />
                </div>
                <div className="dashboard-page-item-content">
                  <h3 className="m-b--xs font--2xl font--600 text--black">
                    {' '}
                    {totalMembers || 0}
                  </h3>
                  <h6 className=" font--2sm font--400 text--secondary">
                    {t('translation:header.Consumer')}
                  </h6>
                </div>
              </div>
            </AccessControl>

            <AccessControl allowedRoles={[AGENCY_USER, AGENT]}>
              <div className="border-full--black-100 w--full bg--white radius--md p--md gap--md d--flex  align-items--center justify-content--start  h-max--100 h--full c--pointer overflow--hidden dashboard-page-item">
                <div className="dashboard-page-item-icon bg--black-50 h--full w--full w-max--50 h-min--50 h-max--50 radius--full d--flex  align-items--center justify-content--center">
                  <SignedConsents width={30} height={30} />
                </div>
                <div className="dashboard-page-item-content">
                  <h3 className="m-b--xs font--2xl font--600 text--black">
                    {' '}
                    {totalSendConsents || 0}
                  </h3>
                  <h6 className=" font--2sm font--400 text--secondary">
                    {t('translation:commonWords.SentConsents')}{' '}
                    
                  </h6>
                </div>
              </div>
            </AccessControl>
            <AccessControl allowedRoles={[AGENCY_USER, AGENT]}>
              <div className="border-full--black-100 w--full bg--white radius--md p--md gap--md d--flex  align-items--center justify-content--start  h-max--100 h--full c--pointer overflow--hidden dashboard-page-item">
                <div className="dashboard-page-item-icon bg--black-50 h--full w--full w-max--50 h-min--50 h-max--50 radius--full d--flex  align-items--center justify-content--center">
                  <SignedConsents width={30} height={30} />
                </div>
                <div className="dashboard-page-item-content">
                  <h3 className="m-b--xs font--2xl font--600 text--black">
                    {' '}
                    {totalSignedConsents || 0}
                  </h3>
                  <h6 className=" font--2sm font--400 text--secondary">
                    {t('translation:commonWords.SignedConsents')}{' '}
                  </h6>
                </div>
              </div>
            </AccessControl>

            <AccessControl allowedRoles={[AGENCY_USER, AGENT]}>
              <div className="border-full--black-100 w--full bg--white radius--md p--md gap--md d--flex  align-items--center justify-content--start  h-max--100 h--full c--pointer overflow--hidden dashboard-page-item">
                <div className="dashboard-page-item-icon bg--black-50 h--full w--full w-max--50 h-min--50 h-max--50 radius--full d--flex  align-items--center justify-content--center">
                  <SignedEligibilities width={30} height={30} />
                </div>
                <div className="dashboard-page-item-content">
                  <h3 className="m-b--xs font--2xl font--600 text--black">
                    {' '}
                    {totalSendEligibilities || 0}
                  </h3>
                  <h6 className=" font--2sm font--400 text--secondary">
                    {t('translation:commonWords.SentEligibilities')}{' '}
                  </h6>
                </div>
              </div>
            </AccessControl>
            <AccessControl allowedRoles={[AGENCY_USER, AGENT]}>
              <div className="border-full--black-100 w--full bg--white radius--md p--md gap--md d--flex  align-items--center justify-content--start  h-max--100 h--full c--pointer overflow--hidden dashboard-page-item">
                <div className="dashboard-page-item-icon bg--black-50 h--full w--full w-max--50 h-min--50 h-max--50 radius--full d--flex  align-items--center justify-content--center">
                  <SignedEligibilities width={30} height={30} />
                </div>
                <div className="dashboard-page-item-content">
                  <h3 className="m-b--xs font--2xl font--600 text--black">
                    {' '}
                    {totalSignedEligibilities || 0}
                  </h3>
                  <h6 className=" font--2sm font--400 text--secondary">
                    {t('translation:commonWords.SignedEligibilities')}{' '}
                  </h6>
                </div>
              </div>
            </AccessControl>
          </div>

          <div className="border-full--black-100 w--full p--md bg--white radius--md d--flex flex--column justify-content--center gap--md h-min--100 c--pointer overflow--hidden dashboard-page-chart">
            <h6 className=" font--md font--500 text--black border-bottom--black-100 h-min--32">
              {t('translation:header.Consumer')}
            </h6>
            <CustomChart {...{ graphData: dashboardSatesResponseData }} />
          </div>
        </div>
        {/* <AccessControl allowedRoles={[AGENCY_USER, AGENT]}>
          <div className="d--flex flex--column w--full gap--lg w-max--350">
            <div className="w--full bg--white radius--md p--md gap--md d--flex  align-items--center justify-content--center flex--column h--full c--pointer overflow--hidden dashboard-page-item-circle border-full--black-100">
              <h3 className="font--lg font--600 signed-consents w-min--100 w-min--100 h-max--100 h-min--100 bg--grey-900 m-b--md d--flex  align-items--center justify-content--center radius--full text--white">
                {' '}
                {totalSignedConsents}
              </h3>
              <h6 className=" font--md font--600 text--black">
                Signed Consents
              </h6>
            </div>

            <div className="w--full bg--white radius--md p--md gap--md d--flex  align-items--center justify-content--center flex--column h--full c--pointer overflow--hidden dashboard-page-item-circle border-full--black-100">
              <h3 className="font--lg font--600 signed-eligibilities w-min--100 w-min--100 h-max--100 h-min--100 bg--primary-900 m-b--md d--flex  align-items--center justify-content--center radius--full text--white">
                {' '}
                {totalSignedEligibilities}
              </h3>
              <h6 className=" font--md font--600 text--black">
                Signed Eligibilities
              </h6>
            </div>
          </div>
        </AccessControl> */}
      </div>
      {/* table start here */}
      {/* <div className="d--flex align-items--center justify-content--start gap--xl">
        <AccessControl allowedRoles={[AGENCY_USER, AGENT]}>
          <div className="m-b--lg w--full  bg--white radius--md d--flex flex--column align-items--center justify-content--center gap--md h-min--100  overflow--hidden DashboardPageItem">
            <TableManager
              {...{
                fetcherKey: 'getDashboarConsentAndEligibilityList',
                fetcherFn: getDashboarConsentAndEligibilityList,
                shouldFilter: false,
                showPagination: false,
                shouldRefresh: false,
                name: 'Consumer with No Consent No Eligibility',
                columns: noSignedColumn,
                fullTableClass: 'dashboard-table',
                extraClassesForHeader: '',
                selectCBForTableData: (tblData) => tblData?.data?.data,
              }}
            />
          </div>
        </AccessControl>
        <AccessControl allowedRoles={[AGENCY_USER, AGENT]}>
          <div className="m-b--lg w--full  bg--white radius--md d--flex flex--column align-items--center justify-content--center gap--md h-min--100  overflow--hidden DashboardPageItem">
            <TableManager
              {...{
                fetcherKey: 'getDashboarConsentNoEligibilityList',
                fetcherFn: getDashboarConsentNoEligibilityList,
                shouldFilter: false,
                showPagination: false,
                shouldRefresh: false,
                name: 'Consumer with Signed Consent No Eligibility',
                columns: noSignedColumn,
                fullTableClass: 'dashboard-table',
                extraClassesForHeader: '',
                selectCBForTableData: (tblData) => tblData?.data?.data,
              }}
            />
          </div>
        </AccessControl>
      </div> */}
      {openModal ? (
        <AsideModal
          handleClose={handleClose}
          width={modalLookUp?.[openModal]?.width}
          size={modalLookUp?.[openModal]?.size}
          title={t(`translation:title.${modalLookUp?.[openModal]?.title}`)}
          footerComponent={null}
          headerComponent={null}
        >
          {modalLookUp?.[openModal]?.component}
        </AsideModal>
      ) : null}
      {viewModal ? (
        <Modal
          title={t('translation:button.Upload')}
          headerComponent={null}
          footerComponent={null}
          handleClose={() => setViewModal(false)}
          shouldCloseOnClickOutside={false}
          width="md"
          height="200"
          size="600"
        >
          <UploadClaim
            {...{
              handleClose: () => setViewModal(false),
              rowData,
            }}
          />
        </Modal>
      ) : null}
    </div>
  );
}
