import { useCallback, useState } from 'react';
import Button from '../../../components/Button';
import TableManager from '../../../components/TableManager';
import { agencyStatusLookup } from '../constants';
import AsideModal from '../../../components/AsideModal';
import SendInvites from '../Modal/SendInvites';
import {
  getAgentInviteList,
  inviteAgentDelete,
  resendInviteAgent,
} from '../../../api';
import {
  convertDateFunction,
  getObjectLength,
} from '../../../helpers/functions';
import ActionConfirmation from '../../../components/ActionConfirmation';
import useIcons from '../../../assets/icons/useIcons';
import Modal from '../../../components/Modal';
import ImportInvites from '../Modal/ImportInvites';
import Dropdown from '../../../components/Dropdown';
import { dropEl } from '../../../helpers/constants';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAlert } from '../../../hooks/useAlert';
import { useTranslation } from 'react-i18next';

const AgencyInvitation = () => {
  const { t } = useTranslation();

  const { ImportIcon } = useIcons();
  const { showAlert } = useAlert();
  const queryClient = useQueryClient();

  const [showModal, setShowModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openAsideModal, setOpenAsideModal] = useState(false);
  const [rowData, setRowData] = useState({});

  const { mutate } = useMutation({
    mutationFn: showModal === 'delete' ? inviteAgentDelete : resendInviteAgent,
    mutationKey:
      showModal === 'delete' ? 'inviteAgentDelete' : 'resendInviteAgent',
    onSuccess: ({ data }) => {
      showAlert({ type: 'success', message: data?.data?.message });
      queryClient.invalidateQueries({ queryKey: ['getAgentInviteList'] });
      setShowModal(false);
      setRowData({});
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'danger', message: errMsg });
    },
  });

  const handleProfileDropClick = (clickCase = '', rowData, item) => {
    switch (clickCase) {
      case 'resend':
        setShowModal('resend');
        setRowData(rowData);
        break;

      case 'delete':
        setRowData(rowData);
        setShowModal('delete');
        break;

      case 'edit':
        setOpenAsideModal(true);
        setRowData(rowData);
        break;

      default:
        break;
    }
  };

  const sendColumns = [
    {
      accessorKey: 'agentDetail',
      header: () => 'Agent Name',
      cell: ({ getValue }) =>
        `${getValue()?.firstName} ${getValue()?.middleName || ''} ${
          getValue()?.lastName
        }`,
    },
    {
      accessorKey: 'agentDetail.phone',
      header: () => 'Agent Phone',
    },
    {
      accessorKey: 'agentDetail.email',
      header: () => 'Agent Email',
    },
    {
      accessorKey: 'agentDetail.NPN',
      header: () => 'Agent NPN',
    },
    {
      accessorKey: 'createdAt',
      header: () => 'Invite date',
      cell: ({ getValue }) => convertDateFunction(getValue()),
      meta: {
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'agentInvitationStatus',
      header: () => 'Status',
      cell: ({ getValue }) => {
        return (
          <span className={agencyStatusLookup?.[getValue()]?.class}>
            {t(
              `translation:commonWords.${
                agencyStatusLookup?.[getValue()]?.text
              }`,
            )}
          </span>
        );
      },
      meta: {
        textAlign: 'center',
      },
    },

    {
      accessorKey: 'action',
      header: () => 'Action',
      cell: ({ row }) => {
        const rowData = row?.original;
        if (rowData?.agentInvitationStatus === 'I') {
          return (
            <>
              <div className="w-min--125 w-min--auto-xs">
                <Dropdown
                  closeOnClickOutside={true}
                  dropList={{
                    component: ({ item }) => {
                      return (
                        <div
                          className="text--secondary p--sm w--full text--l"
                          onClick={() =>
                            handleProfileDropClick(item?.value, rowData, item)
                          }
                        >
                          {t(`translation:button.${item?.title}`)}
                        </div>
                      );
                    },
                    data: [
                      {
                        title: 'Resend Invite',
                        value: 'resend',
                        id: 1,
                      },
                      {
                        title: 'Edit',
                        value: 'edit',
                        id: 2,
                      },
                      {
                        title: 'Delete',
                        value: 'delete',
                        id: 3,
                      },
                    ],
                  }}
                >
                  {dropEl}
                </Dropdown>
              </div>
            </>
          );
        }
      },
      meta: {
        textAlign: 'center',
        width: '8%',
      },
    },
  ];

  const tableActions = [
    {
      id: 2,
      access: true,
      label: 'Import Agents',
      component: (
        <Button
          btnClasses="btn w-min--150   text--black white-space--nowrap gap--xs"
          variant="transparent"
          color="black"
          borderColor="primary"
          data-link-hover-primary
          isOutline
          onClick={() => {
            setOpenModal('import_member');
          }}
          icon={<ImportIcon width={18} />}
        >
          {t(`translation:button.Import Agents`)}
        </Button>
      ),
    },
    {
      id: 1,
      access: true,
      component: (
        <Button
          btnClasses="btn w-min--150 border-full--primary bg--primary-200 text--black"
          variant="transprent"
          color="black"
          isOutline
          onClick={() => {
            setOpenAsideModal(true);
            setRowData({});
          }}
        >
          {t(`translation:button.Invite Agent`)}
        </Button>
      ),
    },
  ];
  const handleClose = useCallback(() => {
    setOpenAsideModal(false);
  }, []);
  return (
    <>
      <div className="w--full d--flex flex--column  invitationPage">
        <TableManager
          {...{
            fetcherKey: 'getAgentInviteList',
            fetcherFn: getAgentInviteList,
            shouldFilter: false,
            name: 'Invites',
            columns: sendColumns,
            tableActions,
            // staticData: agencyStaticData,
          }}
        />
      </div>
      {openAsideModal ? (
        <AsideModal
          handleClose={handleClose}
          title={
            !getObjectLength(rowData)
              ? t(`translation:button.Invite Agent`)
              : t(`translation:button.Update Agent`)
          }
          footerComponent={null}
          headerComponent={null}
        >
          <SendInvites handleClose={handleClose} rowData={rowData} />
        </AsideModal>
      ) : null}
      {openModal ? (
        <Modal
          width="md"
          height="200"
          size="600"
          shouldCloseOnClickOutside={false}
          handleClose={() => setOpenModal(false)}
          headerComponent={null}
          footerComponent={null}
          title={t(`translation:button.Import Agents`)}
        >
          <ImportInvites {...{ handleClose: () => setOpenModal(false) }} />
        </Modal>
      ) : null}
      <ActionConfirmation
        message={
          showModal === 'resend'
            ? t('translation:message.ResendInvite')
            : t('translation:message.DeleteInvite')
        }
        onConfirm={() => mutate({ uuid: rowData?._id })}
        onCancel={() => {
          setShowModal(false);
          setRowData({});
        }}
        controlled
        showModal={showModal}
        showCrossIcon={false}
      />
    </>
  );
};

export default AgencyInvitation;
