import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useAlert } from '../../../../hooks/useAlert';
import useIcons, { ApiIcon } from '../../../../assets/icons/useIcons';
import { getKey, manageKey } from '../../../MyAccount/actions';
import {
  convertDateFunction,
  getObjectLength,
} from '../../../../helpers/functions';
import Switch from '../../../../components/Switch';
import ActionConfirmation from '../../../../components/ActionConfirmation';
import Button from '../../../../components/Button';
import Spinner from '../../../../components/Spinner';
import { useDispatch } from 'react-redux';
import { setApiKey } from '../../../../redux/Slices/themeSlice';
import { useAuth } from '../../../../hooks/useAuth';
import { useTranslation } from 'react-i18next';

const GenerateKey = () => {
  const { t } = useTranslation();
  const { showAlert } = useAlert();
  const { RefreshIcon } = useIcons();
  const dispatch = useDispatch();
  const { planDetails } = useAuth();

  const [active, setActive] = useState(true);

  const {
    data,
    refetch,
    isLoading: getkeyLoading,
  } = useQuery({
    queryKey: ['getKey'],
    queryFn: () => getKey(),
    select: (data) => data?.data?.data,
  });
  const { agentWebhookKey = '', status, updatedAt, createdAt } = data || {};

  const { mutate, isLoading } = useMutation({
    mutationFn: manageKey,
    mutationKey: ['manageKey'],
    onSuccess: ({ data }) => {
      showAlert({ type: 'success', message: data?.data?.message });
      refetch();
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'danger', message: errMsg });
    },
  });

  useEffect(() => {
    if (data && getObjectLength(data)) {
      setActive(status);
      dispatch(setApiKey(agentWebhookKey));
    }
  }, [data]);

  if (getkeyLoading) {
    return (
      <div className="w--full d--flex flex--column align-items--center justify-content--center gap--lg h--full manageKeyTab">
        <Spinner size="md" />
      </div>
    );
  }

  return (
    <>
      {/* <div className="d--flex justify-content--end w--full m-b--sm">
        <div className="w-max--150 d--flex w--full bg--primary border-full--primary radius--md ">
          <WebhookModeButton />
        </div>
      </div> */}
      {data && getObjectLength(data) ? (
        <div className="w--full d--flex border-full--black-100 radius--sm flex--column-xs ">
          <div className="w--full d--flex flex--column">
            <div className="w--full font--sm font--700 text--dark border-bottom--black-100 h-min--50 d--flex align-items--center p-l--md">
              {t('translation:thead.Status')}
            </div>
            <div className="w--full font--sm font--400 h-min--50 d--flex align-items--center p-l--md gap--sm">
              {planDetails?.accessPermission ? (
                <>
                  <Switch
                    value={active}
                    onChange={(e) => {
                      setActive(e.target.checked);
                      if (!isLoading) {
                        mutate({
                          type: 'STATUS_UPDATE',
                          status: e.target.checked,
                        });
                      }
                    }}
                    checked={active}
                    id="key"
                  />
                  <label className="label--control font--sm font--500  text--black ">
                    {t('translation:button.Active')}
                  </label>
                </>
              ) : (
                <label className="label--control font--sm font--500  text--black ">
                  {t('translation:button.Deactived')}
                </label>
              )}
            </div>
          </div>
          <div className="w--full d--flex flex--column">
            <div className="w--full font--sm font--700 text--dark border-bottom--black-100 h-min--50 d--flex align-items--center p-l--md">
              {t('translation:thead.API Key')}
            </div>
            <div className="w--full font--sm font--400 h-min--50 d--flex align-items--center p-l--md">
              {agentWebhookKey}
            </div>
          </div>
          {/* <div className="w--full d--flex flex--column">
            <div className="w--full font--sm font--700 text--dark border-bottom--black-100 h-min--50 d--flex align-items--center p-l--md">
              Tags
            </div>
            <div className="w--full font--sm font--400 h-min--50 d--flex align-items--center p-l--md">
              Mongiya
            </div>
          </div> */}
          <div className="w--full d--flex flex--column">
            <div className="w--full font--sm font--700 text--dark border-bottom--black-100 h-min--50 d--flex align-items--center p-l--md">
              {t('translation:thead.Created')}
            </div>
            <div className="w--full font--sm font--400 h-min--50 d--flex align-items--center p-l--md">
              {convertDateFunction(createdAt)}
            </div>
          </div>
          <div className="w--full d--flex flex--column ">
            <div className="w--full font--sm font--700 text--dark border-bottom--black-100 h-min--50 d--flex align-items--center p-l--md">
              {t('translation:thead.Last Used')}
            </div>
            <div className="w--full font--sm font--400 h-min--50 d--flex align-items--center p-l--md">
              {convertDateFunction(updatedAt)}
            </div>
          </div>
          <div className="w--full d--flex flex--column w-max--150">
            <div className="w--full font--sm font--700 text--dark border-bottom--black-100 h-min--50 d--flex align-items--center p-l--md">
              {t('translation:thead.Action')}
            </div>
            {planDetails?.accessPermission ? (
              <div
                className="w--full font--sm font--400 h-min--50 d--flex align-items--center p-l--md gap--sm c--pointer"
                onClick={() => {
                  if (!isLoading) {
                    mutate({ type: 'REGENERATE' });
                  }
                }}
              >
                <div className="d--flex">
                  <RefreshIcon width={20} height={20} />
                </div>
                <div className="white-space--nowrap">
                  {t('translation:button.Re-Generate')}
                </div>
              </div>
            ) : (
              <div className="w--full font--sm font--400 h-min--50 d--flex align-items--center p-l--md gap--sm c--pointer">
                ---
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="w--full d--flex flex--column align-items--center justify-content--center gap--lg h--full manageKeyTabConfig">
          <div className="w--full d--flex align-items--center justify-content--center text--black-200">
            <ApiIcon width={50} height={50} />
          </div>
          <ActionConfirmation
            message="Once created you will be able to view the API Key secret among other info in the table view."
            onConfirm={() => {
              if (!isLoading) {
                mutate({ type: 'CREATE' });
              }
            }}
            confirmBtnText="Create"
            btnText={t('translation:button.Cancel')}
          >
            <Button
              btnClasses="btn  w-max--140 text--black"
              variant="primary"
              color="black"
              type="button"
            >
              {t('translation:button.Create API Key')}
            </Button>
          </ActionConfirmation>
        </div>
      )}
    </>
  );
};

export default GenerateKey;
