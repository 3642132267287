import { useState, useMemo, useEffect } from 'react';
import { useAlert } from '../../hooks/useAlert';
import { useQuery } from '@tanstack/react-query';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import Spinner from '../Spinner';
import AsideModal from '../AsideModal';
import GlobalFilter from '../GlobalFilter';
import TableRow from './TableRow';
import Filter from './Filter';
import Button from '../Button';
import useIcons from '../../assets/icons/useIcons';
import Tooltip from '../Tooltip';
import { useTranslation } from 'react-i18next';

const pageNumberListLimit = 5;
const TableManager = ({
  fetcherKey = '',
  fetcherFn = () => {},
  columns = [],
  tableActions = [],
  filterDefaultValues = [],
  shouldShowColumnName = true,
  extraParams = {},
  shouldRefresh = false,
  showHeader = true,
  initialFilterState,
  isEnabled = true, // only when api function is not avialable we pass isEnabled = false so no api error is thrown
  setApiResponse = () => {},
  selectCBForTableData = (tblData) => tblData?.data?.data,
  fullTableClass = '',
  extraClassesForHeader = 'm-b--sm',
  nameTabs = [],
  staticData = [],
  activeTab,
  setActiveTab,
  enableMoreOption = false,
  showMoreOption = (elm) => true,
  moreDataFunction = () => null,
  moreDataFnPayload = () => null,
  moreOptioncolumns = [],
  isTranslate = false,
}) => {
  const { t } = useTranslation();
  const [filters, setFilters] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const { showAlert } = useAlert();
  const { RefreshIcon } = useIcons();
  const [sorting, setSorting] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [maxPageNumberListLimit, setMaxPageNumberListLimit] = useState(5);
  const [minPageNumberListLimit, setMinPageNumberListLimit] = useState(0);
  const memoizedSorting = useMemo(() => sorting, [sorting]);
  const memoizedColumns = useMemo(() => columns, [columns]);
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 50,
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  // const search_filters = useMemo(() => {
  //   let response = [];
  //   if (filters.length > 0) {
  //     filters.forEach((filter) => {
  //       if (filter.key === 'date' && filter.value.from && filter.value.to) {
  //         response.push({
  //           key: filter.key,
  //           dateType: filter.dateType,
  //           value: filter.value,
  //         });
  //       } else if (filter.key !== 'date' && filter.value) {
  //         response.push({
  //           key: filter.key,
  //           value: filter.value,
  //         });
  //       }
  //     });
  //   }
  //   return response;
  // }, [filters]);

  let payload = {
    page: pageIndex + 1,
    limit: pageSize,
    sort: memoizedSorting[0] || {},
    filters,
    ...extraParams,
  };

  const {
    isSuccess,
    isLoading,
    isFetching,
    refetch,
    data: tbldata,
  } = useQuery({
    queryFn: ({ queryKey }) => fetcherFn(queryKey[1] || {}),
    queryKey: [`${fetcherKey}`, { ...payload }],
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'danger', message: errMsg });
    },
    enabled: isEnabled,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: false,
  });
  useEffect(() => {
    if (isSuccess && tbldata) {
      setApiResponse(tbldata);
    }
  }, [isSuccess, setApiResponse, tbldata]);

  const tableInstance = useReactTable({
    debugAll: false,
    data:
      staticData?.length > 0 ? staticData : selectCBForTableData(tbldata) || [],
    columns: memoizedColumns || [],
    columnResizeMode: 'onChange',
    state: {
      pagination,
      sorting: memoizedSorting,
      rowSelection,
    },
    manualPagination: true,
    manualSorting: true,
    pageCount: tbldata?.data?.lastPage ?? -1,
    onPaginationChange: setPagination,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
  });

  const handleNextPage = () => {
    tableInstance?.nextPage();
    if (pageIndex === tableInstance?.getPageCount() - 1) {
      return false;
    } else {
      if (pageIndex + 2 > maxPageNumberListLimit) {
        setMaxPageNumberListLimit(maxPageNumberListLimit + pageNumberListLimit);
        setMinPageNumberListLimit(minPageNumberListLimit + pageNumberListLimit);
      }
    }
  };
  const handlePreviousPage = () => {
    tableInstance?.previousPage();
    if (pageIndex === 0) {
      return;
    } else {
      if (pageIndex % pageNumberListLimit === 0) {
        setMaxPageNumberListLimit(maxPageNumberListLimit - pageNumberListLimit);
        setMinPageNumberListLimit(minPageNumberListLimit - pageNumberListLimit);
      }
    }
  };
  const handleLastPage = () => {
    tableInstance?.setPageIndex(tableInstance?.getPageCount() - 1);
    const min = tableInstance.getPageCount() - 5;
    const max = tableInstance.getPageCount();

    setMaxPageNumberListLimit(max);
    setMinPageNumberListLimit(min);
  };
  const handleFirstPage = () => {
    tableInstance?.setPageIndex(0);
    const min = 0;
    const max = 5;
    setMaxPageNumberListLimit(max);
    setMinPageNumberListLimit(min);
  };

  return (
    <div className="border-full--black-100 radius--sm">
      {/* ${extraClassesForHeader} */}
      {showHeader && (
        <div
          className={`w--full d--flex align-items--end  justify-content--between border-bottom--black-100 p-l--md p-r--md  h-min--60  p--sm flex--column-xs align-items--start-xs`}
        >
          {nameTabs?.length > 0 ? (
            <div className="d--flex gap--sm profilePageTabe ">
              {nameTabs?.map(({ title, value }) => {
                return (
                  <span
                    className={`p--sm c--pointer font--sm font--600 ${
                      activeTab === value &&
                      'border-bottom--primary text--primary'
                    }`}
                    onClick={() => setActiveTab(value)}
                  >
                    {title}
                  </span>
                );
              })}
            </div>
          ) : null}
          <div className=" d--flex align-items--center gap--md ">
            {filterDefaultValues?.length > 0 && (
              <>
                <Filter
                  {...{
                    filterDefaultValues,
                    isLoading,
                    setFilters,
                    isTranslate,
                  }}
                />
              </>
            )}

            {isEnabled && (isLoading || isFetching) && (
              <span className="d--flex">
                <Spinner />
              </span>
            )}
          </div>
          <div className="d--flex align-items--center justify-content--end gap--sm w--full-xs tableBtn">
            <span className="d--flex gap--sm  w--full tableBtnSpan justify-content--center-xs">
              {tableActions &&
                tableActions.length > 0 &&
                tableActions.map(({ id, component: ElmComponent, access }) => {
                  if (!access) return null;
                  return <span key={id}>{ElmComponent}</span>;
                })}
            </span>
            {/* {shouldFilter && (
              <span className="position--relative">
                {search_filters && search_filters.length > 0 ? (
                  <span className="bg--secondary radius--full p-l--sm p-r--sm w-max--60 text--white d--flex justify-content--center align-items--center position--absolute right--1">
                    {search_filters?.length}
                  </span>
                ) : null}
                <Button
                  btnClasses="btn  w-max--36 border-full--primary bg--primary-200 text--black"
                  variant="transprent"
                  color="black"
                  isOutline
                  type="button"
                  icon={<FilterIcon width={16} height={16} />}
                  onClick={() => setOpenFilter(true)}
                />
              </span>
            )} */}
            {shouldRefresh && (
              <Tooltip text={t('translation:button.Refresh')}>
                <Button
                  disabled={isLoading || isFetching}
                  // variant="white"
                  // btnClasses="btn w-max--36 box-shadow--xs"
                  // color="primary"

                  btnClasses="btn   text--black"
                  variant="transparent"
                  color="black"
                  borderColor="primary"
                  isOutline
                  data-link-hover-primary
                  type="button"
                  icon={<RefreshIcon width={16} height={16} />}
                  onClick={() => refetch()}
                />
              </Tooltip>
            )}
          </div>
        </div>
      )}

      <div
        className={`w--full  bg--white radius--sm p--md p--5-xs overflow--auto w-max--100p  ${fullTableClass}`}
      >
        <div className="w--full table--responsive table--responsive--search-scroll  radius--sm border-full--black-100">
          <table className="table ">
            {shouldShowColumnName && (
              <thead className="d--none-xs">
                {tableInstance?.getHeaderGroups()?.map((headerGroup) => (
                  <tr key={headerGroup?.id}>
                    {enableMoreOption && (
                      <th
                        style={{
                          width: 'fit-content',
                          padding: '0px',
                        }}
                      ></th>
                    )}
                    {headerGroup?.headers?.map((header) => {
                      const headerText = header.column.columnDef.header();
                      return (
                        <th
                          className={header?.column?.columnDef?.meta?.class}
                          key={header?.id}
                          colSpan={
                            header?.column?.columnDef?.meta?.colSpan ??
                            undefined
                          }
                          style={{
                            textAlign:
                              header?.column?.columnDef?.meta?.textAlign ??
                              'left',
                            // columnSpan: header?.column?.columnDef?.meta?.colSpan ?? undefined,
                            width:
                              header?.column?.columnDef?.meta?.width ||
                              `${(
                                100 / header?.headerGroup?.headers?.length
                              ).toFixed(2)}%`,
                            border: header?.column?.columnDef?.meta?.border,
                            color: header?.column?.columnDef?.meta?.color ?? '',
                          }}
                        >
                          {header?.isPlaceholder ? null : header?.column
                              .columnDef.meta?.isSortable ? (
                            <div
                              onClick={header?.column?.getToggleSortingHandler()}
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext(),
                              )}
                            </div>
                          ) : isTranslate ? (
                            <>
                              {headerText
                                ? t(`translation:thead.${headerText}`)
                                : ''}
                            </>
                          ) : (
                            flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )
                          )}
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
            )}

            <tbody>
              {tableInstance?.getRowModel()?.rows?.length > 0 ? (
                tableInstance?.getRowModel()?.rows?.map((row) => {
                  return (
                    <TableRow
                      row={row}
                      flexRender={flexRender}
                      enableMoreOption={enableMoreOption}
                      showMoreOption={showMoreOption}
                      rowSelection={rowSelection}
                      moreDataFunction={moreDataFunction}
                      moreDataFnPayload={moreDataFnPayload}
                      memoizedColumns={moreOptioncolumns || memoizedColumns}
                    />
                  );
                })
              ) : (
                <tr>
                  <td
                    colSpan={tableInstance?.getAllFlatColumns()?.length}
                    align="center"
                  >
                    <span className="w--full d--flex justify-content--center text--black">
                      {t('translation:commonWords.NoRecordFound')}
                    </span>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* // {showPagination && !isLoading && tableInstance?.getRowModel()?.rows?.length > 0 && ( */}
        {/* <div className="w--full d--flex align-items--center justify-content--between   p--sm bg--contrast paginationTable border-full--black-100">
          {showPagination && !isLoading && (
            <>
              <div className=" d--flex gap--sm align-items--center w--full text--black-600 font--sm">
                {' '}
                00 records show
              </div>

              <div className="d--flex align-items--center">
                <div className="font--sm white-space--nowrap m-r--md d--flex text--black-600">
                  0 of 0
                </div>
                <div className="d--flex gap--xs">
                  <Button
                    btnClasses="w-min--28 h-min--28 w-max--28 h-max--28 c--pointer d--flex align-items--center justify-content--center  text--black-600"
                    disabled={!tableInstance?.getCanPreviousPage()}
                    className={` ${
                      !tableInstance?.getCanPreviousPage()
                        ? 'disabled opacity-5'
                        : 'btn'
                    } `}
                    variant="transparent"
                    onClick={() => handleFirstPage()}
                  >
                    <DoubleArrowLeftIcon width={20} height={20} />
                  </Button>

                  <Button
                    btnClasses="w-min--28 h-min--28 w-max--28 h-max--28 c--pointer d--flex align-items--center justify-content--center  text--black-600"
                    type="button"
                    disabled={!tableInstance?.getCanPreviousPage()}
                    className={`${
                      !tableInstance?.getCanPreviousPage()
                        ? 'disabled opacity-5'
                        : 'btn '
                    } `}
                    variant="transparent"
                    onClick={() => handlePreviousPage()}
                  >
                    <ArrowLeftIcon width={20} height={20} />
                  </Button>

                  <div>
                    {tableInstance?.getPageOptions()?.map((page, index) => {
                      const number = page + 1;
                      if (
                        number < maxPageNumberListLimit + 1 &&
                        number > minPageNumberListLimit
                      ) {
                        return (
                          <div className="w-min--28 h-min--28 w-max--28 h-max--28 c--pointer d--flex align-items--center justify-content--center border-full--primary radius--sm bg--primary-100 text--primary">
                            <button
                              type="button"
                              className={` ${
                                tableInstance?.getState()?.pagination
                                  ?.pageIndex === index
                                  ? 'btn--pagination-active'
                                  : 'btn--pagination btn'
                              }`}
                              key={page}
                              onClick={() => tableInstance?.setPageIndex(index)}
                            >
                              <span className="font--sm">{page + 1}</span>
                            </button>
                          </div>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </div>
                  <Button
                    btnClasses="w-min--28 h-min--28 w-max--28 h-max--28 c--pointer d--flex align-items--center justify-content--center  text--black-600"
                    type="button"
                    disabled={!tableInstance?.getCanNextPage()}
                    className={`${
                      !tableInstance?.getCanNextPage()
                        ? 'disabled opacity-5'
                        : 'btn'
                    }`}
                    variant="transparent"
                    onClick={() => handleNextPage()}
                  >
                    <ArrowRightIcon width={20} height={20} />
                  </Button>
                  <Button
                    btnClasses="w-min--28 h-min--28 w-max--28 h-max--28 c--pointer d--flex align-items--center justify-content--center  text--black-600"
                    type="button"
                    disabled={!tableInstance?.getCanNextPage()}
                    className={` ${
                      !tableInstance?.getCanNextPage()
                        ? 'disabled opacity-5'
                        : 'btn '
                    } `}
                    variant="transparent"
                    onClick={() => handleLastPage()}
                  >
                    <DoubleArrowRightIcon width={20} height={20} />
                  </Button>
                </div>
              </div>
            </>
          )}
        </div> */}
      </div>

      {openFilter && (
        <AsideModal
          handleClose={() => setOpenFilter(false)}
          title="Filters"
          footerComponent={null}
          headerComponent={null}
        >
          <GlobalFilter
            filters={filters}
            setFilters={setFilters}
            setOpenFilter={setOpenFilter}
            initialFilterState={initialFilterState}
            isSearching={isLoading || isFetching}
          />
        </AsideModal>
      )}
    </div>
  );
};

export default TableManager;
