import React, { useState } from 'react';
import Button from '../../../components/Button';
import { submitBtnTxt } from '../../../helpers/functions';
import SendAggrementModal from './SendAggrementModal';
import Modal from '../../../components/Modal';
import useIcons from '../../../assets/icons/useIcons';
import { useQueryClient } from '@tanstack/react-query';
import { useAuth } from '../../../hooks/useAuth';
import { useTranslation } from 'react-i18next';

const HRAStep1 = ({ setActiveStep }) => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [tncAgreed, setTncAgreed] = useState(false);
  const [tncError, setTncError] = useState(false);
  const { RefreshIcon } = useIcons();
  const queryClient = useQueryClient();
  const { user } = useAuth();

  return (
    <div className="w--full d--flex flex--column gap--xl HRAPage h--full position--relative">
      <div
        className="position--absolute bg--black-50 text--black c--pointer right--10 top--10 w-min--40 h-min--40 w-max--40 h-max--40 radius--md d--flex align-items--center justify-content--center"
        onClick={() => {
          queryClient.invalidateQueries({
            queryKey: ['getUserDetails'],
            exact: false,
          });
        }}
      >
        <RefreshIcon />
      </div>
      <div className="d--flex flex--column gap--md border-full--black-100 radius--md p--md   justify-content--between">
        <div className="w--full">
          <div className="font--xl font--800 h-min--50  d--flex  p-l--md p-r--md p-t--md ">
            {t('translation:HRA.h1')}
          </div>
          <div className="font--lg font--500 h-min--50 d--flex align-items--center  p-l--md p-r--md">
            {t('translation:HRA.h2')}
          </div>
          <div className="font--sm font--400 h-min--50  d--flex align-items--center  p-l--md p-r--md">
            {t('translation:HRA.h3')}

            {/* {user?.agreementDocumentDetail?.agreementDocumentStatus === 'NO'
            ? 'We have send agreement to your email/sms, please check and sign to continue. If you have not received agreement yet, please try again.'
            : 'To Join and Start, please sign consent agreement.'} */}
          </div>
          <div className="d--flex flex--column gap--sm">
            <div className="font--sm font--600 h-min--28   p-l--md p-r--md">
              {t('translation:HRA.h4')}
            </div>
            <div className="font--sm font--400 h-min--28   p-l--md p-r--md">
              <li>
                <span className="font--600"> {t('translation:HRA.p1h')} </span>
                {t('translation:HRA.p1')}
              </li>
            </div>
            <div className="font--sm font--400 h-min--28   p-l--md p-r--md">
              <li>
                <span className="font--600"> {t('translation:HRA.p2h')} </span>
                {t('translation:HRA.p2')}
              </li>
            </div>

            <div className="font--sm font--400 h-min--28   p-l--md p-r--md">
              <li>
                <span className="font--600"> {t('translation:HRA.p3h')} </span>
                {t('translation:HRA.p3')}
              </li>
            </div>
            <div className="font--lg font--500 h-min--50    p-l--md p-r--md p-t--md">
              {t('translation:HRA.h5')}
            </div>
          </div>
        </div>
        <div className="w--full border-full--black-100 p--md radius--md bg--black-50">
          <div className="font--xl font--800 h-min--50  d--flex  p-l--md p-r--md p-t--md ">
            {t('translation:HRA.h6')}
          </div>
          <div className="font--sm font--400 line-height--1-dot-5 d--flex align-items--center  p-l--md p-r--md">
            {t('translation:HRA.h7')}
          </div>

          <div className="d--flex flex--column gap--sm">
            <label
              htmlFor="carrier-tnc"
              className={`font--sm font--400 h-min--50  d--flex align-items--center gap--sm p-l--md p-r--md c--pointer ${
                tncError ? 'text--danger' : 'text--black'
              }`}
            >
              <input
                type="checkbox"
                name=""
                id="carrier-tnc"
                onChange={(e) => {
                  setTncAgreed(e.target.checked);
                  if (e.target.checked) {
                    setTncError(false);
                  }
                }}
                checked={tncAgreed}
              />
              {t('translation:HRA.h8')}
            </label>
          </div>
        </div>
        {user?.agreementDocumentDetail?.agreementDocumentStatus ===
        'NO' ? null : (
          <div className="d--flex justify-content--center gap--md w--full  m-t--sm m-b--sm">
            <Button
              type="submit"
              variant="primary"
              color="black"
              btnClasses="btn w-max--200 w-min--200"
              disabled={false}
              onClick={() => {
                if (tncAgreed) {
                  setOpenModal(true);
                } else {
                  setTncError(true);
                }
              }}
            >
              {submitBtnTxt(false, t('translation:button.Send HRA Agreement'))}
            </Button>
          </div>
        )}
      </div>
      {user?.agreementDocumentDetail?.agreementDocumentStatus === 'NO' && (
        <div>
          {t('translation:HRA.h9')}

          <span
            onClick={() => {
              if (tncAgreed) {
                setOpenModal(true);
              } else {
                setTncError(true);
              }
            }}
            className="text--primary c--pointer"
          >
            {t('translation:button.Resend Email')}
          </span>
        </div>
      )}

      {openModal ? (
        <Modal
          width="md"
          height="200"
          size="600"
          shouldCloseOnClickOutside={false}
          handleClose={() => setOpenModal(false)}
          headerComponent={null}
          footerComponent={null}
          title={t('translation:button.Send Agreement')}
        >
          <SendAggrementModal
            handleClose={() => setOpenModal(false)}
            {...{ setActiveStep }}
          />
        </Modal>
      ) : null}
    </div>
  );
};

export default HRAStep1;
