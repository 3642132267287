import axios from 'axios';
import { DotVetical } from '../assets/icons/useIcons';
export const AGENCY_USER = 'AGENCY_USER';
export const AGENT = 'AGENT';
export const CARRIER = 'CARRIER';
export const MEMBER = 'MEMBER';

export const PSPDFKITLICENSEKEY =
  'Gp2JlWcVEmXYsgqHcn7wkFnhxzmmSfeUCK_zCBYaR1fqeb04IPTLyJLX6lfqnAJCo09cF2l33gnHG5eXW4KwCZDXjHZG-cbI_OggZISYOGSDccPlkRSaxAgG_iokWIHSLRrQHiXXLVUlcQ7CQIyxrOetLl97Dq-6dYpno9Kx6pPo3s8Ny3zBcIt2YMJFaYIygZSr8vdV5_SmuA8';

export const initialEditInstance = { isEdit: false, editData: {} };

export const DateFilterTypes = [
  { label: 'Today', value: 'Today' },
  { label: 'Yesterday', value: 'Yesterday' },
  { label: 'Last 7 Days', value: 'Last 7 Days' },
  { label: 'Last 30 Days', value: 'Last 30 Days' },
  { label: 'This Month', value: 'This Month' },
  { label: 'Last Month', value: 'Last Month' },
  { label: 'Select Date', value: 'Select Date' },
  { label: 'Custom Range', value: 'Custom' },
];

export const claimStatusOptions = [
  { label: 'New', value: 'NEW' },
  { label: 'Open', value: 'OPEN' },
  { label: 'Completed', value: 'COMPLETED' },
  { label: 'On hold', value: 'ONHOLD' },
  { label: 'In progress', value: 'INPROGRESS' },
];

export const passwordRegx =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;

export const themes = {
  default: {
    '--primary': ' #15EDE7',
    '--secondary': ' #727272',
    '--success': ' #1a9112',
    '--info': ' #7a9efa',
    '--white': ' #ffffff',
    '--whiteDark': ' #ffffff',
    '--black': '#000000',
    '--grey': '#344054',
    '--contrast': '#f8f8f9',
    '--transparent': 'transparent',
    '--surface': '#727793',
    '--light-surface': '#b3bdc7',

    '--primary-rgba': ' 21, 237, 231',
    '--secondary-rgba': ' 114, 114, 114',
    // '--primary-rgba': ' 229, 54, 73',
    // '--secondary-rgba': ' 40, 48, 63',
    '--success-rgba': ' 26, 145, 18',
    '--info-rgba': ' 122, 158, 250',
    '--grey-rgba': ' 52, 64, 84',
    '--black-rgba': ' 0, 0, 0',
    '--contrast-rgba': '248, 248, 249',
  },
  green: {
    '--success': ' #1a9112',
    '--white': ' #ffffff',
    '--whiteDark': ' #ffffff',
    '--black': '#000000',
    '--contrast': '#f8f8f9',
    '--transparent': 'transparent',
    '--light-surface': '#b3bdc7',
    // changed theme
    '--primary': ' #29c28b',
    '--secondary': ' #3c4642',
    '--info': ' #29c28b',
    '--grey': '#696868',
    '--surface': '#818382',

    '--success-rgba': ' 26, 145, 18',
    '--black-rgba': ' 0, 0, 0',
    '--contrast-rgba': '248, 248, 249',
    // changed theme
    '--primary-rgba': ' 41, 194, 139',
    '--secondary-rgba': ' 60, 70, 66',
    '--info-rgba': ' 41, 194, 139',
    '--grey-rgba': ' 105, 104, 104',
  },
  blue: {
    '--success': ' #1a9112',
    '--white': ' #ffffff',
    '--whiteDark': ' #ffffff',
    '--black': '#000000',
    '--contrast': '#f8f8f9',
    '--transparent': 'transparent',
    '--light-surface': '#b3bdc7',
    // changed theme
    '--primary': ' #4694f0',
    '--secondary': ' #475479',
    '--info': ' #4694f0',
    '--grey': '#727a91',
    '--surface': '#8790a8',

    '--success-rgba': ' 26, 145, 18',
    '--black-rgba': ' 0, 0, 0',
    '--contrast-rgba': '248, 248, 249',
    // changed theme
    '--primary-rgba': ' 70, 148, 240',
    '--secondary-rgba': ' 71, 84, 121',
    '--info-rgba': '  70, 148, 240',
    '--grey-rgba': ' 114, 122, 145',
    '--white-rgba': '255, 255, 255',
  },
  yellow: {
    '--success': ' #1a9112',
    '--white': ' #ffffff',
    '--whiteDark': ' #ffffff',
    '--black': '#000000',
    '--contrast': '#f8f8f9',
    '--transparent': 'transparent',
    '--light-surface': '#b3bdc7',
    // changed theme
    '--primary': ' #f06c15',
    '--secondary': ' #413d34',
    '--info': ' #f06c15',
    '--grey': '#78746b',
    '--surface': '#8d877a',

    '--success-rgba': ' 26, 145, 18',
    '--black-rgba': ' 0, 0, 0',
    '--contrast-rgba': '248, 248, 249',
    // changed theme
    '--primary-rgba': ' 240, 108, 21',
    '--secondary-rgba': ' 65, 61, 52',
    '--info-rgba': '  240, 108, 21',
    '--grey-rgba': ' 120, 116, 107',
  },
  purple: {
    '--success': ' #1a9112',
    '--white': ' #ffffff',
    '--whiteDark': ' #ffffff',
    '--black': '#000000',
    '--contrast': '#f8f8f9',
    '--transparent': 'transparent',
    '--light-surface': '#b3bdc7',
    // changed theme
    '--primary': ' #9540a3',
    '--secondary': ' #3d363e',
    '--info': ' #9540a3',
    '--grey': '#6a636b',
    '--surface': '#988a99',

    '--success-rgba': ' 26, 145, 18',
    '--black-rgba': ' 0, 0, 0',
    '--contrast-rgba': '248, 248, 249',
    // changed theme
    '--primary-rgba': ' 149, 164, 163',
    '--secondary-rgba': ' 61, 54, 62',
    '--info-rgba': '  149, 164, 163',
    '--grey-rgba': ' 106, 99, 107',
  },
  black: {
    // '--primary': ' #c7cbd3',
    '--primary': ' #6a758d',
    '--secondary': ' #9399a6',
    '--success': ' #1a9112',
    '--info': ' #7a9efa',
    '--white': ' #161a23',
    '--whiteDark': ' #ffffff',
    '--black': '#ffffff',
    '--grey': '#c7cbd3',
    '--contrast': '#242a37',
    '--transparent': 'transparent',
    '--surface': '#c7cbd3',
    '--light-surface': '#b3bdc7',

    '--primary-rgba': ' 193, 203, 211',
    '--secondary-rgba': ' 40, 48, 63',
    '--success-rgba': ' 26, 145, 18',
    '--info-rgba': ' 122, 158, 250',
    '--black-rgba': ' 255, 255, 255',
    '--grey-rgba': ' 199, 203, 211',
    '--contrast-rgba': '36, 42, 55',
    '--white-rgba': '0, 0, 0',
  },
};

export const getLocationPermission = () => {
  const onSuccess = (position, resolve) => {
    const { latitude, longitude } = position.coords;
    resolve({ latitude, longitude });
  };
  const onError = (positionError, reject) => {
    reject(positionError.message);
  };
  const locationPromise = new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) => onSuccess(position, resolve),
      (positionError) => onError(positionError, reject),
    );
  });
  return locationPromise;
};
export const getLocationFromLatLong = async (lat, lon) => {
  const apiKey = 'YOUR_API_KEY'; // Replace with your Google Maps API key
  const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&key=${apiKey}`;

  try {
    const response = await axios.get(url);
    const data = response.data;

    if (data.results && data.results.length > 0) {
      let country = '';
      let state = '';
      let city = '';

      data.results[0].address_components.forEach((component) => {
        if (component.types.includes('country')) {
          country = component.long_name;
        }
        if (component.types.includes('administrative_area_level_1')) {
          state = component.long_name;
        }
        if (component.types.includes('locality')) {
          city = component.long_name;
        }
      });
      return { country, state, city };
    }
  } catch (error) {
    console.error('Error fetching location data', error);
  }
};

export const emojiRegex = /([\uD800-\uDBFF][\uDC00-\uDFFF])/g;
export const urlPattern =
  /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;

export const dropEl = (
  <div className="d--flex align-items--center font--sm font--600 gap--sm text--black-800">
    <div className="w-min--32 h-min--32 h-max--32 w-max--32 radius--full text--black text--white d--flex align-items--center justify-content--center ">
      <DotVetical fallbackWidth={18} fallbackHeight={18} />
    </div>
  </div>
);
