import React, { useEffect } from 'react';
import { router } from './router';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { RouterProvider } from 'react-router-dom';
import { Auth } from './hooks/useAuth';
import { AlertProvider } from './hooks/useAlert';
import { useSelector } from 'react-redux';
import i18next from 'i18next';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

export default function App() {
  const { language } = useSelector((state) => state?.language);
  useEffect(() => {
    if (language) {
      i18next.changeLanguage(language);
    }
  }, [language]);
  return (
    <AlertProvider>
      <Auth>
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router} />
        </QueryClientProvider>
      </Auth>
    </AlertProvider>
  );
}
