import React from 'react';
import TableManager from '../../../components/TableManager';
import { agentStatusLookup, useUpdateInviteStatusMutate } from '../constants';
import Button from '../../../components/Button';
import { getAgentInviteList } from '../../../api';
import { convertDateFunction } from '../../../helpers/functions';
import ActionConfirmation from '../../../components/ActionConfirmation';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

const AgentInvitation = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { updateInviteStatusMutate } = useUpdateInviteStatusMutate();

  const handleUpdateStatus = (status, rowData) => {
    updateInviteStatusMutate(
      { uuId: rowData?._id, status },
      {
        onSuccess: () => {
          queryClient?.invalidateQueries(['getAgentInviteList']);
        },
      },
    );
  };

  const columns = [
    {
      accessorKey: 'agencyDetail.agencyName',
      header: () => 'Agency Name',
    },
    {
      accessorKey: 'agencyDetail.agencyPhone',
      header: () => 'Agency Phone',
    },
    {
      accessorKey: 'agencyDetail.agencyEmail',
      header: () => 'Agency Email',
    },
    {
      accessorKey: 'agencyDetail.agencyNPN',
      header: () => 'Agency NPN',
    },
    {
      accessorKey: 'createdAt',
      header: () => 'Invite Date',
      cell: ({ getValue }) => convertDateFunction(getValue()),
    },
    {
      // accessorKey: 'status',
      accessorKey: 'agentInvitationStatus',
      header: () => 'Status',
      cell: ({ getValue }) => {
        return (
          <span className={agentStatusLookup?.[getValue()]?.class}>
            {t(
              `translation:commonWords.${
                agentStatusLookup?.[getValue()]?.text
              }`,
            )}
          </span>
        );
      },
    },
    {
      accessorKey: 'action',
      header: () => 'Action',
      cell: ({ row }) => {
        const rowData = row?.original;
        if (rowData?.agentInvitationStatus === 'I') {
          return (
            <div className="d--flex gap--sm justify-content--center align-items--center">
              <ActionConfirmation
                message={t('translation:message.AcceptInvite')}
                onConfirm={() => handleUpdateStatus('A', rowData)}
              >
                <Button
                  btnClasses="btn  "
                  variant="success-100"
                  color="success"
                  size="sm"
                >
                  {t('translation:button.Accept')}
                </Button>
              </ActionConfirmation>
              <ActionConfirmation
                message={t('translation:message.DeclineInvite')}
                onConfirm={() => handleUpdateStatus('D', rowData)}
              >
                <Button
                  btnClasses="btn  "
                  variant="danger-100"
                  color="danger"
                  size="sm"
                >
                  {t('translation:button.Decline')}
                </Button>
              </ActionConfirmation>
            </div>
          );
        } else if (rowData?.agentInvitationStatus === 'A') {
          return (
            <ActionConfirmation
              message={t('translation:message.ReleaseInvite')}
              onConfirm={() => handleUpdateStatus('R', rowData)}
            >
              <Button
                btnClasses="btn  "
                variant="danger-100"
                color="danger"
                size="sm"
              >
                {t('translation:button.Release')}
              </Button>
            </ActionConfirmation>
          );
        }
      },
      meta: {
        textAlign: 'center',
        width: '8%',
      },
    },
  ];
  return (
    <>
      <div className="w--full d--flex flex--column  invitationPage">
        <TableManager
          {...{
            fetcherKey: 'getAgentInviteList',
            fetcherFn: getAgentInviteList,
            shouldFilter: false,
            name: 'Invites',
            columns,
            isTranslate: true,
          }}
        />
      </div>
    </>
  );
};

export default AgentInvitation;
