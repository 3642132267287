import React from 'react';
import {
  addictionUseArr,
  diagnosedOptions,
  dietOptions,
  exerciseArr,
  exerciseOptions,
  familyDiagnosedOptions,
  healthPrioritiesOptions,
  pastYearOptions,
  psaTestOptions,
  sleepCycleOptions,
  stressLevelOptions,
  timeSpanOptions,
  vaccinationOptions,
  wellnessOptions,
  yesNoQuestionsArr,
  yesOrNo,
} from '../constants';
import FormInput from '../../../components/FormInput';
import FormSelect from '../../../components/FormSelect';
import { useTranslation } from 'react-i18next';

const HealthCheckForm = ({ register, errors, watch, setValue, index }) => {
  const { t } = useTranslation();
  const handleCheckBox = (key, value) => {
    const sendViaWatch = watch(`member.${index}.data.${key}`);
    const updatedValues = sendViaWatch?.includes(value)
      ? sendViaWatch.filter((v) => v !== value)
      : [...(sendViaWatch ?? []), value];

    setValue(`member.${index}.data.${key}`, updatedValues, {
      shouldValidate: true,
    });
  };
  return (
    <>
      <div className="d--flex flex--column  border-full--black-100 radius--md ">
        <div className="font--md font--600 h-min--50 border-bottom--black-100 d--flex  p-l--md p-r--md flex--column justify-content--center">
          {t('translation:HRA.DiagnosedCondition')}

          <div className="font--2sm font--black-600 font--400">
            {t('translation:HRA.SelectAllThatApply')}
          </div>
        </div>
        <div className="w--full d--flex flex--column gap--lg p--lg h-min--100">
          <div className="d--grid grid--4 w--full gap--lg">
            {diagnosedOptions.map((val) => {
              return (
                <div className="w--full d--flex" key={val?.value + index}>
                  <label className="label--control font--sm font--500 m-b--sm   text--black-600 d--flex gap--sm align-items--center c--pointer">
                    <input
                      type="checkbox"
                      value={val?.value}
                      onChange={(e) => {
                        const { value } = e.target;

                        handleCheckBox(`diagnosed_with_conditions`, value);

                        if (value === 'others') {
                          setValue(
                            `member.${index}.data.diagnosed_with_conditions_otherValue`,
                            '',
                          );
                        }
                      }}
                      checked={watch(
                        `member.${index}.data.diagnosed_with_conditions`,
                      )?.includes(val?.value)}
                    />
                    {val?.label}
                  </label>
                </div>
              );
            })}
            <div className="w--full d--flex ">
              {watch(
                `member.${index}.data.diagnosed_with_conditions`,
              )?.includes('others') ? (
                <FormInput
                  error={errors?.diagnosed_with_conditions_otherValue?.message}
                  {...register(
                    `member.${index}.data.diagnosed_with_conditions_otherValue`,
                  )}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <div className="d--flex  gap--md w--full">
        <div className="d--flex flex--column gap--md w--full">
          {yesNoQuestionsArr.map((item) => {
            return (
              <div
                key={item.label}
                className="d--flex gap--xl align-items--center justify-content--between  radius--md p-t--md p-b--md w--full"
              >
                <div className="label--control font--sm font--700 text--black-600 d--flex gap--sm w--full">
                  {t(`translation:HRA.${item.label}`)}
                </div>
                <div className="w--full d--flex gap--md">
                  <label className="label--control font--sm font--400 text--black-600 d--flex gap--xs align-items--center c--pointer">
                    <input
                      type="radio"
                      id={item?.value}
                      value={yesOrNo.yes}
                      onChange={(e) => {
                        setValue(
                          `member.${index}.data.${item?.value}`,
                          e.target.checked,
                        );
                      }}
                      checked={watch(`member.${index}.data.${item?.value}`)}
                    />
                    {t('translation:labels.Yes')}
                  </label>
                  <label className="label--control font--sm font--400   text--black-600 d--flex gap--xs align-items--center c--pointer">
                    <input
                      type="radio"
                      value={yesOrNo.no}
                      id={item?.value}
                      onChange={(e) => {
                        setValue(
                          `member.${index}.data.${item?.value}`,
                          !e.target.checked,
                        );
                      }}
                      checked={!watch(`member.${index}.data.${item?.value}`)}
                    />
                    No
                  </label>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="d--flex flex--column  border-full--black-100 radius--md ">
        <div className="font--md font--600 h-min--50 border-bottom--black-100 d--flex align-items--center p-l--md p-r--md">
          {t('translation:HRA.FamilyDiagnosedCondition')}
        </div>
        <div className="d--grid grid--4 w--full gap--lg p--lg h-min--100">
          {familyDiagnosedOptions.map((val) => {
            return (
              <div className="w--full d--flex" key={val?.value + index}>
                <label className="label--control font--sm font--500 m-b--sm   text--black-600 d--flex gap--sm align-items--center c--pointer">
                  <input
                    type="checkbox"
                    value={val?.value}
                    onChange={(e) => {
                      const { value } = e.target;
                      handleCheckBox('family_diagnosed_with_conditions', value);
                    }}
                    checked={watch(
                      `member.${index}.data.family_diagnosed_with_conditions`,
                    )?.includes(val?.value)}
                  />

                  {val?.label}
                </label>
              </div>
            );
          })}
          <div className="w--full d--flex ">
            {watch(
              `member.${index}.data.family_diagnosed_with_conditions`,
            )?.includes('others') && (
              <FormInput
                error={errors?.family_diagnosed_with_conditions_other?.message}
                {...register(
                  `member.${index}.data.family_diagnosed_with_conditions_other`,
                )}
              />
            )}
          </div>
        </div>
      </div>

      <div className="d--flex flex--column  border-full--black-100 radius--md ">
        <div className="font--md font--600 h-min--50 border-bottom--black-100 d--flex align-items--center p-l--md p-r--md">
          {t('translation:HRA.DoYouCurrentlyUseAnyofTheFollowing')}
        </div>
        <div className="w--full d--flex flex--column  gap--md p--lg h-min--100">
          <div className="w--full d--flex  gap--3xl ">
            {addictionUseArr.map((item) => {
              return (
                <div
                  key={item.label + index}
                  className="d--flex gap--xl align-items--center justify-content--between  radius--md p-t--md p-b--md w--full"
                >
                  <div className="label--control font--sm font--700 text--black-600 d--flex gap--sm w--full">
                    {item.label}
                  </div>
                  <div className="w--full d--flex gap--md   ">
                    <label className="label--control font--sm font--400 text--black-600 d--flex gap--xs align-items--center c--pointer">
                      <input
                        type="radio"
                        id={item?.value}
                        value={yesOrNo.yes}
                        onChange={(e) => {
                          setValue(
                            `member.${index}.data.substance_use.${item?.value}`,
                            e.target.checked,
                          );
                        }}
                        checked={watch(
                          `member.${index}.data.substance_use.${item?.value}`,
                        )}
                      />
                      {t('translation:labels.Yes')}
                    </label>
                    <label className="label--control font--sm font--400   text--black-600 d--flex gap--xs align-items--center c--pointer">
                      <input
                        type="radio"
                        value={yesOrNo.no}
                        id={item?.value}
                        onChange={(e) => {
                          setValue(
                            `member.${index}.data.substance_use.${item?.value}`,
                            e.target.checked,
                          );
                        }}
                        checked={
                          !watch(
                            `member.${index}.data.substance_use.${item?.value}`,
                          )
                        }
                      />
                      No
                    </label>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="d--flex flex--column  border-full--black-100 radius--md ">
        <div className="font--md font--600 h-min--50 border-bottom--black-100 d--flex align-items--center p-l--md p-r--md">
          {t('translation:HRA.SleepHours')}
        </div>
        <div className="w--full d--flex flex--column gap--lg p--lg h-min--100">
          <div className="d--flex w--full gap--lg">
            {sleepCycleOptions.map((item) => {
              return (
                <div key={item.label + index} className="w--full d--flex">
                  <label className="label--control font--sm font--500 m-b--sm   text--black-600 d--flex gap--sm align-items--center c--pointer">
                    <input
                      type="radio"
                      value={item.value}
                      checked={
                        item.value === watch(`member.${index}.data.sleep_hours`)
                      }
                      onChange={(e) => {
                        const { value } = e.target;
                        setValue(`member.${index}.data.sleep_hours`, value);
                      }}
                    />
                    {t(`translation:HRA.${item?.label}`)}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="d--flex flex--column  border-full--black-100 radius--md ">
        <div className="font--md font--600 h-min--50 border-bottom--black-100 d--flex align-items--center p-l--md p-r--md">
          {t('translation:HRA.ExerciseSchedule')}
        </div>
        <div className="w--full d--flex flex--column gap--lg p--lg h-min--100">
          <div className="d--grid grid--4 w--full gap--lg">
            {exerciseOptions.map((item) => {
              return (
                <div key={item.label + index} className="w--full d--flex">
                  <label className="label--control font--sm font--500 m-b--sm   text--black-600 d--flex gap--sm align-items--center c--pointer">
                    <input
                      type="radio"
                      value={item.value}
                      checked={
                        item.value ===
                        watch(`member.${index}.data.exercise_schedule`)
                      }
                      onChange={(e) => {
                        const { value } = e.target;
                        setValue(
                          `member.${index}.data.exercise_schedule`,
                          value,
                        );
                      }}
                    />
                    {t(`translation:HRA.${item?.label}`)}
                  </label>
                </div>
              );
            })}
            <div className="w--full d--flex ">
              <label className="label--control font--sm font--500 m-b--sm   text--black-600 d--flex gap--sm align-items--center c--pointer">
                <input
                  type="checkbox"
                  onChange={(e) => {
                    const { checked } = e.target;
                    setValue(`member.${index}.data.typeofEx`, checked);
                    setValue(`member.${index}.data.typeofExValue`, '');
                  }}
                  checked={watch(`member.${index}.data.typeofEx`)}
                />
                Type of exercise
              </label>
            </div>
            <div className="w--full d--flex ">
              {watch(`member.${index}.data.typeofEx`) && (
                <FormSelect
                  placeholder="Select type of exercise"
                  error={errors?.typeofExValue?.message}
                  options={exerciseArr}
                  {...register(`member.${index}.data.typeofExValue`)}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="d--flex flex--column  border-full--black-100 radius--md ">
        <div className="font--md font--600 h-min--50 border-bottom--black-100 d--flex align-items--center p-l--md p-r--md">
          {t('translation:HRA.StressLevel')}
        </div>
        <div className="w--full d--flex flex--column gap--lg p--lg h-min--100">
          <div className="d--flex w--full gap--lg">
            {stressLevelOptions.map((item) => {
              return (
                <div key={item.label + index} className="w--full d--flex">
                  <label className="label--control font--sm font--500 m-b--sm   text--black-600 d--flex gap--sm align-items--center c--pointer">
                    <input
                      type="radio"
                      value={item.value}
                      checked={
                        item.value ===
                        watch(`member.${index}.data.stress_level`)
                      }
                      onChange={(e) => {
                        const { value } = e.target;
                        setValue(`member.${index}.data.stress_level`, value);
                      }}
                    />
                    {t(`translation:HRA.${item?.label}`)}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="d--flex flex--column  border-full--black-100 radius--md ">
        <div className="font--md font--600 h-min--50 border-bottom--black-100 d--flex align-items--center p-l--md p-r--md">
          {t('translation:HRA.CurrentDiet')}
        </div>
        <div className="w--full d--flex flex--column gap--lg p--lg h-min--100">
          <div className="d--flex w--full gap--lg">
            {dietOptions.map((item) => {
              return (
                <div key={item.label + index} className="w--full d--flex">
                  <label className="label--control font--sm font--500 m-b--sm   text--black-600 d--flex gap--sm align-items--center c--pointer">
                    <input
                      type="radio"
                      value={item.value}
                      checked={
                        item.value ===
                        watch(`member.${index}.data.current_diet_rating`)
                      }
                      onChange={(e) => {
                        const { value } = e.target;
                        setValue(
                          `member.${index}.data.current_diet_rating`,
                          value,
                        );
                      }}
                    />
                    {t(`translation:HRA.${item?.label}`)}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="d--flex flex--column  border-full--black-100 radius--md ">
        <div className="font--md font--600 h-min--50 border-bottom--black-100 d--flex align-items--center p-l--md p-r--md">
          {t('translation:HRA.LastPhysicalExam')}
        </div>
        <div className="w--full d--flex flex--column gap--lg p--lg h-min--100">
          <div className="d--flex w--full gap--lg">
            {pastYearOptions.map((item) => {
              return (
                <div key={item.label + index} className="w--full d--flex">
                  <label className="label--control font--sm font--500 m-b--sm   text--black-600 d--flex gap--sm align-items--center c--pointer">
                    <input
                      type="radio"
                      value={item.value}
                      checked={
                        item.value ===
                        watch(`member.${index}.data.last_physical_exam`)
                      }
                      onChange={(e) => {
                        const { value } = e.target;
                        setValue(
                          `member.${index}.data.last_physical_exam`,
                          value,
                        );
                      }}
                    />
                    {t(`translation:HRA.${item?.label}`)}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="d--flex flex--column  border-full--black-100 radius--md ">
        <div className="font--md font--600 h-min--50 border-bottom--black-100 d--flex align-items--center p-l--md p-r--md">
          {t('translation:HRA.VaccinationRecieved')}
        </div>
        <div className="w--full d--flex flex--column gap--lg p--lg h-min--100">
          <div className="d--grid grid--4 w--full gap--lg">
            {vaccinationOptions.map((val) => {
              return (
                <div className="w--full d--flex" key={val?.value}>
                  <label className="label--control font--sm font--500 m-b--sm   text--black-600 d--flex gap--sm align-items--center c--pointer">
                    <input
                      type="checkbox"
                      value={val?.value}
                      onChange={(e) => {
                        const { value } = e.target;
                        handleCheckBox('vaccinations_received_in_past', value);
                      }}
                      checked={watch(`
member.${index}.data.vaccinations_received_in_past`)?.includes(val?.value)}
                    />

                    {val?.label}
                  </label>
                </div>
              );
            })}
            <div className="w--full d--flex ">
              {watch(
                `member.${index}.data.vaccinations_received_in_past`,
              )?.includes('others') && (
                <FormInput
                  error={errors?.vaccinations_received_in_past_other?.message}
                  {...register(
                    `member.${index}.data.vaccinations_received_in_past_other`,
                  )}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="d--flex flex--column  border-full--black-100 radius--md ">
        <div className="font--md font--600 h-min--50 border-bottom--black-100 d--flex align-items--center p-l--md p-r--md">
          {t('translation:HRA.ForWomenOnly')}:
        </div>
        <div className="w--full d--flex flex--column gap--lg p--lg h-min--100">
          <div className="d--flex flex--column gap--sm">
            <div className="font--sm font--600 m-b--md">
              {t('translation:HRA.LastMammogram')}
            </div>
            <div className="d--flex w--full gap--lg">
              {pastYearOptions.map((item) => {
                return (
                  <div key={item.label + index} className="w--full d--flex">
                    <label className="label--control font--sm font--500 m-b--sm   text--black-600 d--flex gap--sm align-items--center c--pointer">
                      <input
                        type="radio"
                        value={item.value}
                        checked={
                          item.value ===
                          watch(`member.${index}.data.last_mammogram`)
                        }
                        onChange={(e) => {
                          const { value } = e.target;
                          setValue(
                            `member.${index}.data.last_mammogram`,
                            value,
                          );
                        }}
                      />
                      {t(`translation:HRA.${item?.label}`)}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="d--flex flex--column gap--sm">
            <div className="font--sm font--600 m-b--md">
              {t('translation:HRA.LastPapSmear')}
            </div>
            <div className="d--flex w--full gap--lg">
              {pastYearOptions.map((item) => {
                return (
                  <div key={item.label + index} className="w--full d--flex">
                    <label className="label--control font--sm font--500 m-b--sm   text--black-600 d--flex gap--sm align-items--center c--pointer">
                      <input
                        type="radio"
                        value={item.value}
                        checked={
                          item.value ===
                          watch(`member.${index}.data.last_pap_smear`)
                        }
                        onChange={(e) => {
                          const { value } = e.target;
                          setValue(
                            `member.${index}.data.last_pap_smear`,
                            value,
                          );
                        }}
                      />
                      {t(`translation:HRA.${item?.label}`)}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="d--flex flex--column  border-full--black-100 radius--md ">
        <div className="font--md font--600 h-min--50 border-bottom--black-100 d--flex align-items--center p-l--md p-r--md">
          {t('translation:HRA.ForMenOnly')}:
        </div>
        <div className="w--full d--flex flex--column gap--lg p--lg h-min--100">
          <div className="d--flex flex--column gap--sm">
            <div className="font--sm font--600 m-b--md">
              {t('translation:HRA.PSATest')}
            </div>
            <div className="d--flex w--full gap--lg">
              {psaTestOptions.map((item) => {
                return (
                  <div key={item.label + index} className="w--full d--flex">
                    <label className="label--control font--sm font--500 m-b--sm   text--black-600 d--flex gap--sm align-items--center c--pointer">
                      <input
                        type="radio"
                        value={item.value}
                        checked={
                          item.value ===
                          watch(`member.${index}.data.prostate_exam_psa`)
                        }
                        onChange={(e) => {
                          const { value } = e.target;
                          setValue(
                            `member.${index}.data.prostate_exam_psa`,
                            value,
                          );
                        }}
                      />
                      {t(`translation:HRA.${item?.label}`)}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="d--flex flex--column gap--md w--full">
        <div className="d--flex gap--2xl w--full">
          <div className="d--flex align-items--center justify-content--between  radius--md p-t--md p-b--md w--full">
            <div className="label--control font--sm font--700   text--black-600 d--flex gap--sm ">
              {t('translation:HRA.Colonoscopy')}
            </div>
            <div className="w--full d--flex gap--md w-max--150 justify-content--end ">
              <label className="label--control font--sm font--400 text--black-600 d--flex gap--xs align-items--center c--pointer">
                <input
                  type="radio"
                  value={'Yes'}
                  onChange={(e) => {
                    setValue(
                      `member.${index}.data.had_colonoscopy`,
                      e.target.checked,
                    );
                  }}
                  checked={watch(`member.${index}.data.had_colonoscopy`)}
                />
                                      {t('translation:labels.Yes')}

              </label>
              <label className="label--control font--sm font--400   text--black-600 d--flex gap--xs align-items--center c--pointer">
                <input
                  type="radio"
                  value={'No'}
                  onChange={(e) => {
                    setValue(
                      `member.${index}.data.had_colonoscopy`,
                      !e.target.checked,
                    );
                  }}
                  checked={!watch(`member.${index}.data.had_colonoscopy`)}
                />
                No
              </label>
            </div>
          </div>
          <div className="d--flex align-items--center justify-content--between  radius--md p-t--md p-b--md w--full"></div>
        </div>
      </div>

      <div className="d--flex flex--column  border-full--black-100 radius--md ">
        <div className="font--md font--600 h-min--50 border-bottom--black-100 d--flex align-items--center p-l--md p-r--md">
          {t('translation:HRA.PastTwoWeek')}
        </div>
        <div className="w--full d--flex flex--column gap--lg p--lg h-min--100">
          <div className="d--flex flex--column gap--sm">
            <div className="font--sm font--600 m-b--md">
              {t('translation:HRA.FeelDown')}
            </div>
            <div className="d--flex w--full gap--lg">
              {timeSpanOptions.map((item) => {
                return (
                  <div key={item.label + index} className="w--full d--flex">
                    <label className="label--control font--sm font--500 m-b--sm   text--black-600 d--flex gap--sm align-items--center c--pointer">
                      <input
                        type="radio"
                        value={item.value}
                        checked={
                          item.value ===
                          watch(
                            `member.${index}.data.past_two_week.feeling_down_depressed`,
                          )
                        }
                        onChange={(e) => {
                          const { value } = e.target;
                          setValue(
                            `member.${index}.data.past_two_week.feeling_down_depressed`,
                            value,
                          );
                        }}
                      />

                      {t(`translation:HRA.${item?.label}`)}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="d--flex flex--column gap--sm">
            <div className="font--sm font--600 m-b--md">
              {t('translation:HRA.LittleInterest')}
            </div>
            <div className="d--flex w--full gap--lg">
              {timeSpanOptions.map((item) => {
                return (
                  <div key={item.label + index} className="w--full d--flex">
                    <label className="label--control font--sm font--500 m-b--sm   text--black-600 d--flex gap--sm align-items--center c--pointer">
                      <input
                        type="radio"
                        value={item.value}
                        checked={
                          item.value ===
                          watch(
                            `member.${index}.data.past_two_week.little_please_in_things`,
                          )
                        }
                        onChange={(e) => {
                          const { value } = e.target;
                          setValue(
                            `member.${index}.data.past_two_week.little_please_in_things`,
                            value,
                          );
                        }}
                      />
                      {t(`translation:HRA.${item?.label}`)}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="d--flex flex--column gap--sm w--full">
        <div className="d--flex gap--sm flex--column w--full">
          <div className="d--flex align-items--center justify-content--between  radius--md p-t--md p-b--md w--full">
            <div className="label--control font--sm font--700   text--black-600 d--flex gap--sm ">
              {t('translation:HRA.DiagnosedConditionMentalHealth')}
            </div>
            <div className=" d--flex gap--md w-max--250 ">
              <label className="label--control font--sm font--400 text--black-600 d--flex gap--xs align-items--center c--pointer">
                <input
                  type="radio"
                  value={'Yes'}
                  onChange={(e) => {
                    setValue(
                      `member.${index}.data.diagnosed_with_mental_health_condition`,
                      e.target.checked,
                    );
                  }}
                  checked={watch(
                    `member.${index}.data.diagnosed_with_mental_health_condition`,
                  )}
                />
                Yes
              </label>
              <label className="label--control font--sm font--400   text--black-600 d--flex gap--xs align-items--center c--pointer">
                <input
                  type="radio"
                  value={'No'}
                  onChange={(e) => {
                    setValue(
                      `member.${index}.data.diagnosed_with_mental_health_condition`,
                      !e.target.checked,
                    );
                  }}
                  checked={
                    !watch(
                      `member.${index}.data.diagnosed_with_mental_health_condition`,
                    )
                  }
                />
                No
              </label>
            </div>
          </div>
          <div className="d--flex align-items--center justify-content--between  radius--md p-t--md p-b--md w--full">
            <div className="label--control font--sm font--700   text--black-600 d--flex gap--sm ">
              {t('translation:HRA.MentalHealthProf')}
            </div>
            <div className=" d--flex gap--md w-max--250 ">
              <label className="label--control font--sm font--400 text--black-600 d--flex gap--xs align-items--center c--pointer">
                <input
                  type="radio"
                  value={'Yes'}
                  onChange={(e) => {
                    setValue(
                      `member.${index}.data.seeing_mental_health_professional`,
                      e.target.checked,
                    );
                  }}
                  checked={watch(
                    `member.${index}.data.seeing_mental_health_professional`,
                  )}
                />
                                      {t('translation:labels.Yes')}

              </label>
              <label className="label--control font--sm font--400   text--black-600 d--flex gap--xs align-items--center c--pointer">
                <input
                  type="radio"
                  value={'No'}
                  onChange={(e) => {
                    setValue(
                      `member.${index}.data.seeing_mental_health_professional`,
                      !e.target.checked,
                    );
                  }}
                  checked={
                    !watch(
                      `member.${index}.data.seeing_mental_health_professional`,
                    )
                  }
                />
                No
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="d--flex flex--column  border-full--black-100 radius--md ">
        <div className="font--md font--600 h-min--50 border-bottom--black-100 d--flex align-items--center p-l--md p-r--md">
          {t('translation:HRA.TopHealthPriorities')}
        </div>
        <div className="w--full d--flex flex--column gap--lg p--lg h-min--100">
          <div className="d--flex w--full gap--lg">
            {healthPrioritiesOptions.map((val) => {
              return (
                <div className="w--full d--flex" key={val?.value + index}>
                  <label className="label--control font--sm font--500 m-b--sm   text--black-600 d--flex gap--sm align-items--center c--pointer">
                    <input
                      type="checkbox"
                      value={val?.value}
                      onChange={(e) => {
                        const { value } = e.target;
                        handleCheckBox('top_health_priorities', value);
                      }}
                      checked={watch(
                        `member.${index}.data.top_health_priorities`,
                      )?.includes(val?.value)}
                    />
                    {val?.label}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="d--flex flex--column  border-full--black-100 radius--md ">
        <div className="font--md font--600 h-min--50 border-bottom--black-100 d--flex align-items--center p-l--md p-r--md">
          {t('translation:HRA.Wellness')}
        </div>
        <div className="w--full d--flex flex--column gap--lg p--lg h-min--100">
          <div className="d--grid grid--4 w--full gap--lg">
            {wellnessOptions.map((val) => {
              return (
                <div className="w--full d--flex" key={val?.value + index}>
                  <label className="label--control font--sm font--500 m-b--sm   text--black-600 d--flex gap--sm align-items--center c--pointer">
                    <input
                      type="checkbox"
                      value={val?.value}
                      onChange={(e) => {
                        const { value } = e.target;
                        handleCheckBox('interests_in_wellness_programs', value);
                      }}
                      checked={watch(
                        `member.${index}.data.interests_in_wellness_programs`,
                      )?.includes(val?.value)}
                    />
                    {val?.label}
                  </label>
                </div>
              );
            })}
            <div className="w--full d--flex ">
              {watch(
                `member.${index}.data.interests_in_wellness_programs`,
              )?.includes('others') && (
                <FormInput
                  error={errors?.interests_in_wellness_programs_other?.message}
                  {...register(
                    `member.${index}.data.interests_in_wellness_programs_other`,
                  )}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="d--flex flex--column gap--md w--full">
        <div className="d--flex flex--column gap--sm w--full">
          <div className="d--flex align-items--center justify-content--between  radius--md p-t--md p-b--md w--full">
            <div className="label--control font--sm font--700   text--black-600 d--flex gap--sm ">
              {t('translation:HRA.HealthInfo')}
            </div>
            <div className="w--full d--flex gap--md w-max--150 justify-content--end ">
              <label className="label--control font--sm font--400 text--black-600 d--flex gap--xs align-items--center c--pointer">
                <input
                  type="radio"
                  value={'Yes'}
                  onChange={(e) => {
                    setValue(
                      `member.${index}.data.health_info_usage_permission`,
                      e.target.checked,
                    );
                  }}
                  checked={watch(
                    `member.${index}.data.health_info_usage_permission`,
                  )}
                />
                                      {t('translation:labels.Yes')}

              </label>
              <label className="label--control font--sm font--400   text--black-600 d--flex gap--xs align-items--center c--pointer">
                <input
                  type="radio"
                  value={'No'}
                  onChange={(e) => {
                    setValue(
                      `member.${index}.data.health_info_usage_permission`,
                      !e.target.checked,
                    );
                  }}
                  checked={
                    !watch(`member.${index}.data.health_info_usage_permission`)
                  }
                />
                No
              </label>
            </div>
          </div>

          <div className="d--flex align-items--center justify-content--between  radius--md p-t--md p-b--md w--full">
            <div className="label--control font--sm font--700   text--black-600 d--flex gap--sm ">
              {t('translation:HRA.CareManagement')}
            </div>
            <div className="w--full d--flex gap--md w-max--150 justify-content--end ">
              <label className="label--control font--sm font--400 text--black-600 d--flex gap--xs align-items--center c--pointer">
                <input
                  type="radio"
                  value={'Yes'}
                  onChange={(e) => {
                    setValue(
                      `member.${index}.data.care_management_consent`,
                      e.target.checked,
                    );
                  }}
                  checked={watch(
                    `member.${index}.data.care_management_consent`,
                  )}
                />
                                      {t('translation:labels.Yes')}

              </label>
              <label className="label--control font--sm font--400   text--black-600 d--flex gap--xs align-items--center c--pointer">
                <input
                  type="radio"
                  value={'No'}
                  onChange={(e) => {
                    setValue(
                      `member.${index}.data.care_management_consent`,
                      !e.target.checked,
                    );
                  }}
                  checked={
                    !watch(`member.${index}.data.care_management_consent`)
                  }
                />
                No
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HealthCheckForm;
