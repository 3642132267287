import { useForm, Controller } from 'react-hook-form';
import FormInput from '../../../../components/FormInput';
import Button from '../../../../components/Button';
import { submitBtnTxt } from '../../../../helpers/functions';
import { requiredFieldFunction } from '../../../../helpers/yupValidations';
import { object } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { getDropboxConfigure } from '../../actions';
import { useEffect } from 'react';
import PasswordInput from '../../../../components/PasswordInput';
import { useTranslation } from 'react-i18next';

const schema = object().shape({
  dropboxClientId: requiredFieldFunction('Client Id'),
  dropboxClientSecret: requiredFieldFunction('Secret Client'),
});
export default function DropboxConfig({ rowData }) {
  const { t } = useTranslation();
  const { dropboxClientId = '', dropboxClientSecret = '' } = rowData || {};
  const isReConfig = Boolean(dropboxClientId) && Boolean(dropboxClientSecret);
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: { dropboxClientId: '', dropboxClientSecret: '' },
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: getDropboxConfigure,
    onSuccess: ({ data }) => {
      console.log(data?.data?.url);
      window.open(data?.data?.url, '');
      // window.open(data?.data?.url, '_self');
    },
  });
  const onSubmit = (_) => mutate(_);

  useEffect(() => {
    if (isReConfig) {
      reset({ dropboxClientId, dropboxClientSecret });
    }
  }, [isReConfig]);
  return (
    <div className="w--full">
      <form
        className="w--full d--flex flex--column gap--md"
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
      >
        <div className=" d--flex flex--column gap--md ">
          <div className="w--full">
            <Controller
              name="dropboxClientId"
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  label="Client Id"
                  error={errors?.dropboxClientId?.message}
                />
              )}
            />
          </div>

          <div className="w--full">
            <Controller
              name="dropboxClientSecret"
              control={control}
              render={({ field }) => (
                <PasswordInput
                  {...field}
                  label="Client Secret"
                  error={errors?.dropboxClientSecret?.message}
                />
              )}
            />
          </div>
          <div>
            <span className="font--600 ">Redirect URI</span>:{' '}
            {process.env.REACT_APP_BASE_API_URL}/
            configuration/storage?type=dropbox
          </div>
          <div>
            <span className="font--600">{t('translation:button.Info')}</span>:{' '}
            {t('translation:message.RedirectURl')}
          </div>
        </div>
        <div className="w--full d--flex align-items--center justify-content--center m-t--md">
          <Button
            btnClasses="btn  w-max--150 text--black"
            variant="primary"
            color="black"
            type="submit"
            disabled={isLoading}
          >
            {submitBtnTxt(isLoading, isReConfig ? 'Re-Configure' : 'Configure')}
          </Button>
        </div>
      </form>
    </div>
  );
}
