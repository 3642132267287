import React from 'react';
import useIcons from '../../../../../assets/icons/useIcons';
import gmailSmtpVideo from '../../../../../assets/video/emailSmtp.mp4';
import { useTranslation } from 'react-i18next';

const GmailInfo = ({ setShowInfo = () => {} }) => {
  const { t } = useTranslation();
  const { CrossIcon } = useIcons();

  return (
    <div className="d--flex flex--column  gap--xl flex--column border-full--black-100 radius--sm w--full p--md m-t--md position--relative">
      <div
        className="toggle-sidebar-icon d--flex align-items--center justify-content--center radius--full  bg--black  text--white 
position--absolute w-max--28 w-min--28 h-max--28 h-min--28 box--shadow c--pointer  top---13 right---10 c--pointer"
        onClick={() => setShowInfo(false)}
      >
        <CrossIcon width={12} height={12} />
      </div>
      <div className="d--flex flex--column gap--sm w--full ">
        <div className="font--sm font--600 bg--primary-100 border-full--primary text--black radius--sm h-min--40 d--flex align-items--center p-l--md">
          - {t('translation:GmailInfo.h1')}:
        </div>
        <div className="w--full d--flex flex--column gap--lg font--sm">
          <div className="w--full">
            <span className="font--600">
              {t('translation:labels.SMTP Host')}
            </span>
            : smtp.gmail.com
          </div>
          <div className="w--full">
            <span className="font--600">
              {t('translation:labels.SMTP Port and Type')}
            </span>
            :{t('translation:GmailInfo.h2')}
          </div>
          <div className="w--full">
            <span className="font--600">
              {t('translation:labels.Username and From Address')}
            </span>
            :{t('translation:GmailInfo.h3')}
          </div>
          <div className="w--full">
            <span className="font--600">
              {t('translation:labels.Password')}
            </span>
            :{t('translation:GmailInfo.h4')}
          </div>
        </div>
        <div className="w--full d--flex align-items--center justify-content--center mx--auto m-t--xl w-max--600">
          <video src={gmailSmtpVideo} controls className="w--full"></video>
        </div>
      </div>
    </div>
  );
};

export default GmailInfo;
