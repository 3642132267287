import * as yup from 'yup';
import {
  email,
  firstName,
  lastName,
  npn,
  phone,
  requiredFieldFunction,
} from '../../helpers/yupValidations';

export const API_ROUTES = {
  CREATE_CLAIM: {
    METHOD: 'POST',
    URL: 'claim/create',
  },
  UPDATE_CLAIM: {
    METHOD: 'POST',
    URL: 'claim/update',
  },
  CREATE_LIST: {
    METHOD: 'POST',
    URL: 'claim/list',
  },
  CLAIM_DETAILS: {
    METHOD: 'POST',
    URL: 'claim/detail',
  },
};

export const initialValues = {
  consumerFirstname: '',
  consumerMiddleName: '',
  consumerLastname: '',
  consumerPhone: '',
  consumerEmail: '',
  consumerDob: '',

  agentFirstname: '',
  agentMiddleName: '',
  agentLastname: '',
  agentEmail: '',
  agentPhone: '',
  agentNPN: '',

  subject: '',
  policyId: '',
  policyEffectiveDate: '',
  enrollmentYear: '2024',
  description: '',
  claimType: [],

  uploadDocuments: [
    {
      file: null,
    },
  ],
};

export const claimSchema = yup.object().shape({
  consumerFirstname: firstName,
  consumerLastname: lastName,
  consumerPhone: phone,
  consumerEmail: email,
  consumerDob: requiredFieldFunction('Date of birth'),

  agentFirstname: firstName,
  agentLastname: lastName,
  agentEmail: email,
  agentPhone: phone,
  agentNPN: npn,

  subject: requiredFieldFunction('Subject'),
  policyId: requiredFieldFunction('Policy ID'),
  policyEffectiveDate: requiredFieldFunction('Effective Date'),
  enrollmentYear: requiredFieldFunction('Enrollment Year'),
  claimType: yup.array().min(1, 'Select atleast one type'),
  uploadDocuments: yup.array().when('$isEdit', (isEdit, schema) => {
    if (isEdit) return schema;
    else
      return schema
        .of(
          yup.object({
            file: yup.mixed().required('Document is required'),
          }),
        )
        .min(0, 'Select atleast one document');
  }),
});

export const claimTypeOptions = [
  {
    label: 'Consent',
    value: 'Consent',
  },
  {
    label: 'Eligibility Review',
    value: 'Eligibility Review',
  },
  {
    label: 'SSN',
    value: 'SSN',
  },
  {
    label: 'Medicaid Attestation',
    value: 'Medicaid Attestation',
  },
  {
    label: 'Application Duplicated',
    value: 'Application Duplicated',
  },
  {
    label: 'Medicaid Special enrollment',
    value: 'Medicaid Special enrollment',
  },
  {
    label: 'CSR Available',
    value: 'CSR Available',
  },
];
export const statusLookup = {
  NEW: {
    text: 'New',
    class: 'text--success-800 bg--primary-200 p--sm radius--xs',
  },
  ONHOLD: {
    text: 'On Hold',
    class: 'text--danger bg--danger-100 p--sm radius--xs',
  },
  INPROGRESS: {
    text: 'In Progress',
    class: 'text--warning bg--warning-100 p--sm radius--xs',
  },
  COMPLETED: {
    text: 'Completed',
    class: 'text--success bg--success-100 p--sm radius--xs',
  },
  OPEN: { text: 'Open', class: 'text--blue bg--blue-100 p--sm radius--xs' },
};
export const agencyfilterDefaultValues = [
  {
    id: 1,
    type: 'date',
    placeholder: 'Consent Date',
    key: 'createdAt',
  },
  {
    id: 2,
    type: 'text',
    placeholder: 'Claim ID',
    key: 'claimId',
  },
  {
    id: 3,
    type: 'email',
    placeholder: 'Carrier Email',
    key: 'carrierEmail',
  },
  {
    id: 4,
    type: 'email',
    placeholder: 'Consumer Email',
    key: 'memberEmail',
  },
];
export const carrierfilterDefaultValues = [
  {
    id: 1,
    type: 'date',
    placeholder: 'Consent Date',
    key: 'createdAt',
  },
  {
    id: 2,
    type: 'text',
    placeholder: 'Claim ID',
    key: 'claimId',
  },
  {
    id: 3,
    type: 'number',
    placeholder: 'Agent NPN',
    key: 'agentNPN',
  },
];
