import React, { useEffect, useState } from 'react';
import FormSelect from '../../../components/FormSelect';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import Button from '../../../components/Button';
import { useMutation, useQuery } from '@tanstack/react-query';
import { HRAList } from '../../Dashboard/actions';
import { defaultValues, hraSchema, relationArr } from '../constants';
import { getObjectLength, submitBtnTxt } from '../../../helpers/functions';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import HRAForm from './HRAForm';
import useIcons from '../../../assets/icons/useIcons';
import { State, City } from 'country-state-city';
import FormInput from '../../../components/FormInput';
import { yupResolver } from '@hookform/resolvers/yup';
import ActionConfirmation from '../../../components/ActionConfirmation';
import { hraDelete } from '../actions';
import { useAlert } from '../../../hooks/useAlert';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import HRAFormView from './HRAFormView';
import { useTranslation } from 'react-i18next';

const fetchStateApi = async (payload) => {
  const response = await fetch(
    `https://marketplace.api.healthcare.gov/api/v1/counties/by/zip/${payload?.zipCode}?apikey=d687412e7b53146b2631dc01974ad0a4&year=${payload?.year}`,
  );
  const result = await response.json();
  return result;
};
const fetchCareerApi = async (payload) => {
  const response = await fetch(
    `https://marketplace.api.healthcare.gov/api/v1/plans/search?apikey=d687412e7b53146b2631dc01974ad0a4&year=${payload?.year}`,
    {
      method: 'POST',
      body: JSON.stringify(payload),
    },
  );
  const result = await response.json();
  return result;
};

let fetchTimeout;
const statesList = State.getStatesOfCountry('US')?.map((state) => ({
  value: state.isoCode,
  label: state.name,
}));
const HRAStep3 = () => {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const consumerId = searchParams.get('id');
  const { showAlert } = useAlert();
  const { state } = useLocation();

  const navigate = useNavigate();
  const { CrossRoundIcon, AddIcon, RefreshIcon, BackRoundIcon } = useIcons();
  const [show, setShow] = useState(false);
  const [selectedField, setSelectedField] = useState(0);

  const [cityList, setCityList] = useState([]);
  const [relationList, setRelationList] = useState(relationArr);
  const [carrierList, setCarrierList] = useState([]);

  const { data, refetch } = useQuery({
    queryKey: ['HRAList', consumerId],
    queryFn: () => HRAList({ consumerId }),
    select: (data) => data?.data?.data,
  });

  const { mutate } = useMutation({
    mutationFn: hraDelete,
    mutationKey: 'hraDelete',
    onSuccess: ({ data }) => {
      showAlert({ type: 'success', message: data?.data?.message });
      refetch();
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'danger', message: errMsg });
    },
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    getValues,
    trigger,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(hraSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
  });

  const { mutateAsync: getStatesMutate, isLoading: isLoadingState } =
    useMutation({
      mutationKey: ['fetchStateApi'],
      mutationFn: fetchStateApi,
      onSuccess: (data) => {
        setValue('consumer_info.state', data?.counties?.[0]?.state);
        if (data?.counties?.length > 0) {
          fetchCarriers(data?.counties?.[0]);
        } else {
          setCarrierList([]);
        }
      },
      onError: (error) => {
        const errMsg = error?.message ?? 'unexpected error';
        showAlert({ type: 'danger', message: errMsg });
      },
    });

  const { mutateAsync: getCareerMutate, isLoading: isLoadingCarriers } =
    useMutation({
      mutationKey: ['fetchCareerApi'],
      mutationFn: fetchCareerApi,
      onSuccess: (data) => {
        if (data?.message) {
          showAlert({ type: 'danger', message: data?.message });
          return;
        }
        if (data?.facet_groups) {
          const issuerArr = data?.facet_groups.find(
            (value) => value?.name === 'issuers',
          );
          const carriers = issuerArr.facets
            ? issuerArr.facets.map((item) => {
                return {
                  label: item?.value,
                  value: item?.value,
                };
              })
            : [] || [];
          setCarrierList(carriers || []);
        }
      },
      onError: (error) => {
        const errMsg = error?.message ?? 'unexpected error';
        showAlert({ type: 'danger', message: errMsg });
      },
    });

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: 'member',
  });

  const handleSubmitForm = (data) => {
    console.log('🚀 ~ handleSubmitForm ~ data:', data);
  };

  let [watchYear, watchState, watchZipCode] = watch([
    'consumer_info.year',
    'consumer_info.state',
    'consumer_info.zipCode',
  ]);

  useEffect(() => {
    if (data && getObjectLength(data)) {
      const { consumerHraAssessmentInfo, consumerHraAssessmentList = [] } =
        data || {};

      if (consumerHraAssessmentInfo || consumerHraAssessmentList?.length > 0) {
        reset({
          consumer_info: consumerHraAssessmentInfo?.consumerInfo,
          member: consumerHraAssessmentList?.map((val) => {
            const { consumerRelationship, _id } = val || {};
            return { ...consumerRelationship, relationId: _id };
          }),
        });
      }
      if (consumerHraAssessmentList?.length === 0) {
        append({
          relation: 'Self',
          isChild: false,
          data: {
            personal_demographic_info: {
              name: state?.firstName + ' ' + state?.lastName,
              date_of_birth: '',
              gender: '',
              marital_status: '',
              occupation: '',
              primary_care_provider: '',
              carrierId: '',
              carrierName: '',
              relation: 'Self',
              email: state?.email || '',
              phone: state?.phone || '',
            },
          },
        });
      }
      if (!consumerHraAssessmentInfo) {
        // setValue('consumer_info', defaultValues?.consumer_info);
        if (state?.enrollmentYear) {
          setValue('consumer_info.year', state?.enrollmentYear);
        }
      }
    }
  }, [data]);

  useEffect(() => {
    if (data?.consumerHraAssessmentList) {
      for (
        let index = 0;
        index < data?.consumerHraAssessmentList.length;
        index++
      ) {
        const member = data?.consumerHraAssessmentList[index];
        setValue(
          `member.${index}.data.personal_demographic_info.carrierId`,
          member?.consumerRelationship?.data?.personal_demographic_info
            ?.carrierId,
        );
      }
    }
  }, [carrierList]);

  useEffect(() => {
    if (watchState) {
      const cities = City.getCitiesOfState('US', watchState).map((city) => ({
        value: city.name,
        label: city.name,
      }));
      setCityList(cities);
    } else {
      setCityList([]);
    }
  }, [watchState]);

  useEffect(() => {
    if (fields.length === 1) {
      setSelectedField(0);
    }
  }, [fields]);

  useEffect(() => {
    if (watch('member')) {
      // const existingRelations = watch('member').map((item) =>
      //   item.relation?.trim(),
      // );
      // const filteredArr = relationArr.filter(
      //   (item) => !existingRelations.includes(item.value),
      // );
      const filteredArr = relationArr.filter(
        (item) => !['Self'].includes(item.value),
      );

      setRelationList(filteredArr);
    }
  }, [JSON.stringify(watch('member') || [])]);

  const fetchStates = async (val) => {
    if (fetchTimeout) {
      clearTimeout(fetchTimeout);
    }
    fetchTimeout = setTimeout(async () => {
      const isValid = await trigger('consumer_info.zipCode');
      if (!isValid) {
        setCarrierList([]);
        setValue('consumer_info.state', '');
        setValue('consumer_info.city', '');
        return;
      }

      getStatesMutate({ zipCode: watchZipCode, year: watchYear });
    }, 1);
  };

  const fetchCarriers = async (data) => {
    const { zipcode, fips, state } = data || {};

    let payload = {
      household: {
        income: 42000,
        people: [
          {
            aptc_eligible: true,
            dob: '1992-01-01',
            has_mec: false,
            is_pregnant: false,
            is_parent: false,
            uses_tobacco: false,
            gender: 'Male',
            utilization_level: 'Low',
          },
        ],
        has_married_couple: false,
      },
      market: 'Individual',
      place: {
        countyfips: fips,
        state,
        zipcode,
      },
      limit: 10,
      offset: 0,
      order: 'asc',
      year: parseInt(watchYear),
    };
    getCareerMutate(payload);
  };

  useEffect(() => {
    if (watchZipCode) {
      fetchStates();
    }
  }, [watchZipCode]);

  const statesOptions = async (inputValue) => {
    if (inputValue) {
      return statesList.filter((i) =>
        i?.label?.toLowerCase().includes(inputValue?.toLowerCase()),
      );
    } else {
      return statesList;
    }
  };

  return (
    <form
      onSubmit={handleSubmit(handleSubmitForm)}
      className="d--flex flex--column gap--xl "
    >
      <div className="d--flex justify-content--start w--full w-max--600 ">
        <div
          onClick={() =>
            navigate('/HRA', {
              state: { activeStep: 2 },
            })
          }
          className="d--flex align-items--center gap--xs text--black c--pointer font--sm"
        >
          <span className="d--flex">
            <BackRoundIcon width={25} height={25} />
          </span>
          <span className="font--500">{t('translation:button.Back')}</span>
        </div>
      </div>
      <div className="d--flex flex--column  border-full--black-100 radius--md gap--xl ">
        <div className="w--full d--flex  gap--lg p--lg h-min--100 flex--column-xs">
          <div className="w--full d--flex">
            <FormInput
              label="Year"
              disabled
              {...register('consumer_info.year')}
              error={errors?.consumer_info?.year?.message}
            />
          </div>
          <div className="w--full d--flex">
            <Controller
              name="consumer_info.zipCode"
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  label="Zip Code"
                  error={errors?.consumer_info?.zipCode?.message}
                  height="40"
                />
              )}
            />
          </div>
          {carrierList.length > 0 ? (
            <>
              <div className="w--full d--flex">
                <FormInput
                  label="Address"
                  error={errors?.consumer_info?.address?.message}
                  {...register(`consumer_info.address`)}
                />
              </div>

              <div className="w--full">
                <label
                  htmlFor=""
                  className={`"label--control font--sm font--500 m-b--sm d--flex w--full ${
                    errors?.consumer_info?.city?.message
                      ? 'text--danger'
                      : 'text--grey'
                  } `}
                >
                  {errors?.consumer_info?.city?.message
                    ? t(
                        `translation:errors.${errors?.consumer_info?.city?.message}`,
                      )
                    : t('translation:labels.City')}
                  <span className=" font--sm text--danger m-l--xs">*</span>
                </label>
                <Controller
                  name="consumer_info.city"
                  control={control}
                  render={() => (
                    <Select
                      isClearable
                      defaultOptions
                      classNamePrefix={'react-select-input'}
                      options={cityList}
                      placeholder={t('translation:placeholders.Enter City')}
                      value={cityList.find(
                        (i) => i?.value === watch('consumer_info.city'),
                      )}
                      onChange={(e) => {
                        setValue('consumer_info.city', e?.value, {
                          shouldValidate: true,
                        });
                      }}
                      className="async-select-input"
                    />
                  )}
                  rules={{ required: true }}
                />
              </div>

              <div className="w--full">
                <label
                  htmlFor=""
                  className={`label--control font--sm font--500 m-b--sm d--flex w--full ${
                    errors?.consumer_info?.state?.message
                      ? 'text--danger'
                      : 'text--grey'
                  }`}
                >
                  {errors?.consumer_info?.state?.message
                    ? errors?.consumer_info?.state?.message
                    : t('translation:labels.State')}
                  <span className=" font--sm text--danger m-l--xs">*</span>
                </label>
                <Controller
                  name="consumer_info.state"
                  control={control}
                  render={() => (
                    <AsyncSelect
                      isClearable
                      cacheOptions
                      defaultOptions
                      classNamePrefix={'react-select-input'}
                      loadOptions={statesOptions}
                      placeholder={t('translation:placeholders.Enter State')}
                      value={statesList.find(
                        (i) => i?.value === watch('consumer_info.state'),
                      )}
                      onChange={(e) => {
                        setValue('consumer_info.state', e?.value);
                      }}
                      className="async-select-input"
                      isDisabled
                    />
                  )}
                  rules={{ required: true }}
                />
              </div>

              {!show ? (
                <div className="d--flex justify-content--center gap--md w--full m-t--lg ">
                  <Button
                    type="button"
                    variant="primary"
                    color="black"
                    icon={<AddIcon width={16} height={16} />}
                    btnClasses="btn w-max--200 w-min--200 gap--sm"
                    onClick={() => {
                      if (
                        data?.consumerHraAssessmentList?.[0]?.documentStatus ===
                        'SIGNED'
                      ) {
                        setShow(true);
                      }
                    }}
                    disabled={
                      data?.consumerHraAssessmentList?.[0]?.documentStatus !==
                      'SIGNED'
                    }
                  >
                    {submitBtnTxt(false, t('translation:button.Add Member'))}
                  </Button>
                </div>
              ) : (
                <div className="w--full d--flex">
                  <FormSelect
                    label="Relation"
                    placeholder="Select relationship"
                    options={relationList}
                    onChange={(e) => {
                      append({
                        relation: e?.target?.value,
                        isChild: false,
                        data: {
                          personal_demographic_info: {
                            name: '',
                            // name: state?.firstName + ' ' + state?.lastName,
                            date_of_birth: '',
                            gender: '',
                            marital_status: '',
                            occupation: '',
                            primary_care_provider: '',
                            carrierId:
                              data?.consumerHraAssessmentList[0]
                                ?.consumerRelationship?.data
                                ?.personal_demographic_info?.carrierId || '',
                            carrierName:
                              data?.consumerHraAssessmentList[0]
                                ?.consumerRelationship?.data
                                ?.personal_demographic_info?.carrierName || '',
                            relation: e?.target?.value,
                            email: state?.email || '',
                            phone: state?.phone || '',
                          },
                          diagnosed_with_conditions: [],
                          diagnosed_with_conditions_otherValue: '',
                          prescription_medications: '',
                          allergies: '',
                          surgery_in_past_5_years: '',
                          chronic_pain: '',
                          family_diagnosed_with_conditions: [],
                          family_diagnosed_with_conditions_other: '',
                          substance_use: {
                            tobacco: false,
                            alcohol: false,
                            recreational_drugs: false,
                          },
                          sleep_hours: '',
                          exercise_schedule: '',
                          typeofEx: '',
                          typeofExValue: '',
                          stress_level: '',
                          current_diet_rating: '',
                          last_physical_exam: '',
                          vaccinations_received_in_past: [],
                          vaccinations_received_in_past_other: '',
                          last_mammogram: '',
                          last_pap_smear: '',
                          prostate_exam_psa: '',
                          had_colonoscopy: '',
                          past_two_week: {
                            feeling_down_depressed: '',
                            little_please_in_things: '',
                          },
                          diagnosed_with_mental_health_condition: '',
                          seeing_mental_health_professional: '',
                          top_health_priorities: [],
                          interests_in_wellness_programs: [],
                          interests_in_wellness_programs_other: '',
                          health_info_usage_permission: '',
                          care_management_consent: '',
                        },
                      });
                      setShow(false);
                    }}
                  />
                </div>
              )}
            </>
          ) : (
            <>
              {isLoadingCarriers || isLoadingState ? (
                <div className="w--full font--600 text--sm">
                  {t('translation:button.PleaseWait')}
                </div>
              ) : (
                <div className="w--full">
                  {watchZipCode.length === 5 &&
                    !watchState &&
                    carrierList.length === 0 && (
                      <>{t('translation:message.NoStateFound')}</>
                    )}
                </div>
              )}
              <div className="w--full"></div>
              <div className="w--full"></div>
              <div className="w--full"></div>
            </>
          )}
        </div>
      </div>

      <div className="d--flex gap--lg profilePageTabe justify-content--between border-bottom--black-100  overflow--auto">
        <div className="d--flex gap--lg ">
          {fields.map((field, index) => {
            const isErrorExist = Boolean(errors?.member?.[index]?.data);
            return (
              <div className="d--flex align-items--center">
                <div
                  className={`p-t--sm p-b--sm p-l--md p-r--md h-min--40 c--pointer font--sm font--600 d--flex align-items--center border-bottom--transparent justify-content--center gap--sm white-space--nowrap radius--topLeftRight--sm  ${
                    selectedField === index
                      ? `border-bottom--black ${
                          isErrorExist ? 'text--danger' : 'text--black'
                        }  bg--black-50`
                      : `  ${
                          isErrorExist ? 'text--danger-600' : 'text--black-600'
                        }`
                  }`}
                  onClick={(e) => {
                    e.preventDefault();
                    setSelectedField(index);
                  }}
                  key={field.id}
                >
                  {watch(`member.${index}.relation`)}

                  {watch(`member.${index}.relation`) === 'Self' ? null : !watch(
                      `member.${index}.relationId`,
                    ) ? (
                    <div
                      className="c--pointer d--flex"
                      onClick={(e) => {
                        e.stopPropagation();

                        remove(index);
                        setSelectedField(index - 1);
                      }}
                    >
                      <CrossRoundIcon width={18} height={18} />
                    </div>
                  ) : (
                    <ActionConfirmation
                      message={t('translation:message.WantToRemove')}
                      onConfirm={(e) => {
                        e.stopPropagation();
                        mutate({ uuId: watch(`member.${index}.relationId`) });
                      }}
                      confirmBtnText={t('translation:labels.Yes')}
                      btnText={t('translation:button.Cancel')}
                    >
                      <div className="c--pointer d--flex">
                        <CrossRoundIcon width={18} height={18} />
                      </div>
                    </ActionConfirmation>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <div
          className=" bg--black-50 text--black c--pointer  w-min--40 h-min--40 w-max--40 h-max--40 radius--topLeftRight--sm d--flex align-items--center justify-content--center"
          onClick={() => {
            refetch();
          }}
        >
          <RefreshIcon />
        </div>
      </div>

      {fields.map((field, index) => {
        if (index !== selectedField) return null;
        const err = errors?.member?.[index]?.data;

        if (
          data?.consumerHraAssessmentList[index]?.formDocumentStatus ===
          'SIGNED'
        ) {
          return (
            <HRAFormView
              {...{
                register,
                errors,
                watch,
                setValue,
                index,
                rowData: data?.consumerHraAssessmentList[index],
                control,
              }}
            />
          );
        }
        return (
          <HRAForm
            {...{
              control,
              register,
              errors: err,
              watch,
              setValue,
              index: selectedField,
              getValues,
              trigger,
              refetch,
              consumerId,
              carrierList,
              relationList,
              rowData: data?.consumerHraAssessmentList[index],
              isLoadingCarriers,
            }}
          />
        );
      })}
    </form>
  );
};

export default HRAStep3;
