import * as yup from 'yup';

export const requiredFieldFunction = (fieldName) =>
  yup
    .string()
    .required(`${fieldName} is required`)
    .typeError(`${fieldName} is required`);

export const requiredFileFunction = (fieldName) =>
  yup.mixed().required(`${fieldName} is required`);

export const requiredFieldText = (fieldName) =>
  `${fieldName} field is required`;

export const requiredNumberField = (fieldName) =>
  yup
    .number()
    .required(`${fieldName} field is required`)
    .min(1, 'Must be greater than or equal to 1')
    .typeError(`${fieldName} field is required`);

export const name = yup
  .string()
  .required('Name is required')
  .matches(/^[A-Za-z'-]+[ A-Za-z'-]*$/, 'Name required alphabets');

export const firstName = yup
  .string()
  .required('First name is required')
  .matches(/^[A-Za-z'-]+[ A-Za-z'-]*$/, 'First name required alphabets');
// .matches(/^[A-Za-z'-]+[ A-Za-z'-]*$/, 'First name cannot contain numbers or special characters');
// export const middleName = yup
//   .string()
//   .notRequired() // Allows the field to be null or undefined
//   .matches(
//     /^[A-Za-z'-]+[ A-Za-z'-]*$/,
//     'Middle name cannot contain numbers or special characters',
//   );
// // .matches(/^[A-Za-z'-]+[ A-Za-z'-]*$/, 'First name cannot contain numbers or special characters');

export const lastName = yup
  .string()
  .required('Last name is required')
  .matches(
    /^[A-Za-z'-]+[ A-Za-z'-]*$/,
    'Last name cannot contain numbers or special characters',
  );

export const email = yup
  .string()
  .email('Must be a valid email')
  .required('Email is required')
  .max(50, 'Maximum length can be 50 characters');

export const phone = yup
  .string()
  .nullable()
  .matches(
    /^[+]?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
    'Enter a valid phone number',
  )
  .required('Enter a valid phone number')
  .typeError('Enter a valid phone number');

export const passwordValidation = yup
  .string()
  .required('Password is required')
  .matches(
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=!_]).{8,}$/,
    'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
  );

export const confirm_password = yup.string().when('password', {
  is: (val) => (val && val.length > 0 ? true : false),
  then: () =>
    yup
      .string()
      .oneOf([yup.ref('password')], 'Password not matched')
      .required(requiredFieldText('Confirm password')),
});

export const npn = yup
  // .number()
  .string()
  .required('NPN is required')
  .typeError('NPN is required')
  // .positive('Must be a positive number')
  .test('len', 'NPN should not be less than 6 digits', (val) => {
    const length = val ? val.toString().length : 0;
    return length >= 6 && length <= 15;
  });
// .test('len', 'Must be between 5 and 15 digits', (val) => {
//   const length = val ? val.toString().length : 0;
//   return length >= 5 && length <= 15;
// });

export const ssn = yup
  .string()
  .required('SSN is required')
  .typeError('SSN is required')
  // .positive('Must be a positive number')
  .test('len', 'Must be between 5 and 12 digits', (val) => {
    const length = val ? val.toString().length : 0;
    return length >= 5 && length <= 12;
  });

export const postalCode = yup
  .string()
  .required(requiredFieldText('Zip code'))
  .matches(
    /^[A-Z0-9]+$/,
    'Zip code can contain only capital letters and numbers',
  )
  .min(5, 'Please enter a valid Zip code')
  .max(8, 'Zip code cannot be more than 8 digits long');

export const domainValidation = yup
  .string()
  .nullable()
  .notRequired()
  .test('is-valid-domain', 'Valid domain name', (value) => {
    if (!value || value.length === 0) {
      // If the value is empty, return true (no error)
      return true;
    }
    // Check if it matches the domain regex
    return /^(?!:\/\/)([a-zA-Z0-9-_]+\.)+[a-zA-Z]{2,}$/.test(value);
  });

// Custom IBAN validation function
const isValidIBAN = (iban) => {
  // Remove spaces and convert to uppercase
  const cleanIBAN = iban.replace(/\s+/g, '').toUpperCase();

  // Check basic structure
  const ibanRegex = /^[A-Z]{2}[0-9]{2}[A-Z0-9]{1,30}$/;
  if (!ibanRegex.test(cleanIBAN)) {
    return false;
  }

  // Rearrange and convert to integers for checksum validation
  const rearranged = cleanIBAN.slice(4) + cleanIBAN.slice(0, 4);
  const numericIBAN = rearranged
    .split('')
    .map(
      (char) => (isNaN(char) ? char.charCodeAt(0) - 55 : char), // A = 10, B = 11, ..., Z = 35
    )
    .join('');

  // Perform modulo 97 operation
  const mod97 = numericIBAN
    .match(/\d{1,7}/g) // Split into chunks of max 7 digits
    .reduce((prev, curr) => (parseInt(prev + curr, 10) % 97).toString(), '0');

  return parseInt(mod97, 10) === 1;
};

// Add custom method to yup
yup.addMethod(yup.string, 'iban', function (message) {
  return this.test('iban', message || 'Invalid IBAN', (value) => {
    if (!value) return true; // Allow empty values for optional fields
    return isValidIBAN(value);
  });
});

export const IBAN = yup
  .string()
  .required('IBAN is required')
  .iban('Please enter a valid IBAN');
