import React, { useCallback, useState } from 'react';
import TableManager from '../../../components/TableManager';
import AsideModal from '../../../components/AsideModal';
import AddClaim from '../AddClaim';
import Button from '../../../components/Button';
import { getClaimList } from '../actions';
import Modal from '../../../components/Modal';
import ViewCarrierDetails from './ViewCarrierDetails';
import useIcons, {
  DotVetical,
  ClaimCompletedIcon,
  ClaimUholdIcon,
  UploadCloud,
  ClaimInprogressIcon,
  EyeOutlineIcon,
} from '../../../assets/icons/useIcons';
import ReplyClaim from './ReplyClaim';
import UploadClaim from './UploadClaim';
import Dropdown from '../../../components/Dropdown';
import { useNavigate } from 'react-router-dom';
import { useChat } from '../../../hooks/useChat';
import { useSocket } from '../../../hooks/useSocket';
import { convertDateFunction } from '../../../helpers/functions';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateClaimStatus } from '../../../api';
import { useAlert } from '../../../hooks/useAlert';
import { carrierfilterDefaultValues, statusLookup } from '../constants';
import { useTranslation } from 'react-i18next';

const CarrierClaim = () => {
  const { t } = useTranslation();
  let queryClient = useQueryClient();
  const [openModal, setOpenModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [rowData, setRowData] = useState(false);
  const { ChatIcon } = useIcons();

  const { showAlert } = useAlert();

  const { mutate: updateClaimStatusAction } = useMutation({
    mutationFn: updateClaimStatus,
    mutationKey: 'updateClaimStatus',
    onSuccess: () => {
      queryClient?.invalidateQueries(['getClaimList']);
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'danger', message: errMsg });
    },
  });
  const navigate = useNavigate();
  const { chats } = useChat();
  const { socket } = useSocket();

  function handleCreateChat(rowData) {
    if (!rowData?.agentDetail?.agentId) {
      showAlert({ type: 'danger', message: 'Agent does not exist.' });
      return;
    }

    if (rowData?.claimStatus === 'NEW') {
      updateClaimStatusAction({
        claimUuId: rowData?._id,
        type: 'DIRECT',
        status: 'INPROGRESS',
      });
    }
    const ifElementExist =
      chats && chats?.length > 0
        ? chats?.find((chat) => chat?.chatId === rowData._id)
        : null;

    if (ifElementExist) {
      navigate(`/chat/${ifElementExist?.chatId}`);
    } else {
      socket?.emit('create-new-chat', {
        chatId: rowData?._id,
        claimId: rowData?.claimId,
        users: [rowData?.agentDetail, rowData?.carrierDetail],
      });

      navigate(`/chat/${rowData?._id}`);
    }
  }

  const dropEl = (
    <div className="d--flex align-items--center font--sm font--600 gap--sm text--black-800">
      <div className="w-min--32 h-min--32 h-max--32 w-max--32 radius--full  text--black  d--flex align-items--center justify-content--center">
        <DotVetical fallbackWidth={18} fallbackHeight={18} />
      </div>
    </div>
  );
  const handleProfileDropClick = (clickCase = '', rowData, item) => {
    switch (clickCase) {
      case 'upload':
        setRowData(rowData);
        setViewModal(true);
        break;

      case 'reply':
        handleCreateChat(rowData);
        setRowData(rowData);
        break;

      case 'edit':
        setOpenModal('edit');
        setRowData({ _id: rowData?._id });
        break;

      case 'ONHOLD':
        updateClaimStatusAction({
          claimUuId: rowData._id,
          status: clickCase,
          type: 'MANUAL',
        });
        break;

      case 'INPROGRESS':
        updateClaimStatusAction({
          claimUuId: rowData._id,
          status: clickCase,
          type: 'MANUAL',
        });
        break;

      case 'COMPLETED':
        updateClaimStatusAction({
          claimUuId: rowData._id,
          status: clickCase,
          type: 'MANUAL',
        });
        break;
      case 'view_details':
        setOpenModal('view');
        setRowData(rowData);
        break;

      default:
        break;
    }
  };

  const carrierColumns = [
    {
      accessorKey: 'createdAt',
      header: () => 'Date',
      cell: ({ getValue }) => convertDateFunction(getValue()),
    },
    {
      accessorKey: 'claimId',
      header: () => 'Claim Id',
    },
    {
      accessorKey: 'agentDetail.firstname',
      header: () => 'Agent Name',
      cell: ({ row }) => {
        let value = row?.original?.agentDetail;
        return `${value?.firstname} ${value?.middleName || ''} ${
          value?.lastname
        }`;
      },
    },
    {
      accessorKey: 'agentDetail.email',
      header: () => 'Agent Email',
    },
    {
      accessorKey: 'agentDetail.phone',
      header: () => 'Agent Phone',
    },
    {
      accessorKey: 'agentDetail.agentNPN',
      header: () => 'Agent NPN',
    },

    {
      accessorKey: 'claimStatus',
      header: () => 'Status',
      cell: ({ row }) => {
        return (
          <span className={statusLookup?.[row?.original?.claimStatus]?.class}>
            {t(
              `translation:commonWords.${
                statusLookup?.[row?.original?.claimStatus]?.text
              }`,
            )}
          </span>
        );
      },
    },

    {
      accessorKey: 'action',
      header: () => 'Action',
      cell: ({ row }) => {
        let claimStatus = row?.original?.claimStatus;

        return (
          <>
            <div className="d--inline-block">
              <Dropdown
                closeOnClickOutside={true}
                extraClasses="w-min--125 w-min--auto-xs"
                dropList={{
                  component: ({ item }) => {
                    const { access = true, id, value, title, icon } = item;
                    if (!access) return;
                    return (
                      <div
                        key={id}
                        className="text--secondary p--sm w--full d--flex align-items--center  justify-content--start gap--sm"
                        onClick={() =>
                          handleProfileDropClick(value, row?.original, item)
                        }
                      >
                        {icon}

                        {t(`translation:button.${title}`)}
                      </div>
                    );
                  },

                  data: [
                    {
                      id: 1,
                      title: 'Upload',
                      value: 'upload',
                      icon: <UploadCloud width={16} height={16} />,
                    },
                    {
                      title: 'Chat',
                      value: 'reply',
                      id: 2,
                      icon: <ChatIcon width={16} height={16} />,
                    },
                    {
                      title: 'On Hold',
                      value: 'ONHOLD',
                      id: 3,
                      icon: <ClaimUholdIcon width={16} height={16} />,

                      access: ['NEW', 'OPEN', 'INPROGRESS'].includes(
                        claimStatus,
                      ),
                    },
                    {
                      title: 'In Progress',
                      value: 'INPROGRESS',
                      id: 4,
                      icon: <ClaimInprogressIcon width={16} height={16} />,
                      access: ['ONHOLD', 'COMPLETED'].includes(claimStatus),
                    },
                    {
                      title: 'Complete',
                      value: 'COMPLETED',
                      id: 5,
                      icon: <ClaimCompletedIcon width={17} height={17} />,
                      access: ['NEW', 'OPEN', 'INPROGRESS', 'ONHOLD'].includes(
                        claimStatus,
                      ),
                    },
                    {
                      title: 'View',
                      value: 'view_details',
                      id: 6,
                      icon: <EyeOutlineIcon width={17} height={17} />,
                    },
                  ],
                }}
              >
                {dropEl}
              </Dropdown>
            </div>
          </>
        );
      },
      meta: {
        textAlign: 'center',
        width: '8%',
      },
    },
  ];
  const tableActions = [
    {
      id: 1,
      access: true,
      component: (
        <Button
          btnClasses="btn w-min--150 "
          variant="transparent"
          color="black"
          borderColor="primary"
          data-link-hover-primary
          isOutline
          onClick={() => {
            setOpenModal('add');
            setRowData({});
          }}
        >
          {t(`translation:button.New Claim`)}
        </Button>
      ),
    },
  ];

  const handleClose = useCallback(() => {
    setOpenModal(false);
  }, []);

  const modalLookUp = {
    add: {
      component: <AddClaim handleClose={handleClose} rowData={rowData} />,
      width: 'lg',
      size: 1200,
      title: 'New Claims',
    },
    edit: {
      component: <AddClaim handleClose={handleClose} rowData={rowData} />,
      width: 'lg',
      size: 1200,
      title: 'Update Claims',
    },
    view: {
      component: (
        <ViewCarrierDetails handleClose={handleClose} rowData={rowData} />
      ),
      width: 'lg',
      size: 1200,
      title: 'Claim Details',
    },
    reply: {
      component: <ReplyClaim handleClose={handleClose} rowData={rowData} />,
      width: 'lg',
      size: 1200,
      title: 'Claim Chat',
    },
  };
  return (
    <>
      {' '}
      <div className="w--full d--flex flex--column gap--lg memberPage">
        <div className="w--full thead-doubble-tr">
          <TableManager
            {...{
              fetcherKey: 'getClaimList',
              fetcherFn: getClaimList,
              shouldFilter: false,
              name: 'Claims',
              columns: carrierColumns,
              tableActions,
              filterDefaultValues: carrierfilterDefaultValues,
              isTranslate: true,
            }}
          />
        </div>
      </div>
      {openModal ? (
        <AsideModal
          handleClose={handleClose}
          width={modalLookUp?.[openModal]?.width}
          size={modalLookUp?.[openModal]?.size}
          title={t(`translation:title.${modalLookUp?.[openModal]?.title}`)}
          footerComponent={null}
          headerComponent={null}
        >
          {modalLookUp?.[openModal]?.component}
        </AsideModal>
      ) : null}
      {viewModal ? (
        <Modal
          title={'Upload'}
          headerComponent={null}
          footerComponent={null}
          handleClose={() => setViewModal(false)}
          shouldCloseOnClickOutside={false}
          width="md"
          height="200"
          size="600"
        >
          <UploadClaim
            {...{
              handleClose: () => setViewModal(false),
              rowData,
            }}
          />
        </Modal>
      ) : null}
    </>
  );
};

export default CarrierClaim;
