import React from 'react';
import { useTranslation } from 'react-i18next';

const FormInput = (
  {
    placeholder,
    label,
    error,
    extraClasses = '',
    paddingLeft = 'md',
    paddingRight = 'md',
    paddingBottom = 'md',
    paddingTop = 'md',
    height = '40',
    border = true,
    radiusLeft = 'sm',
    radiusRight = 'sm',
    type = 'text',
    borderStyle = 'border-full--black-200',
    required = true,
    isTranslate = true,
    ...rest
  },
  ref,
) => {
  const { t } = useTranslation();
  const text =
    (isTranslate && error ? t(`translation:errors.${error}`) : error) ||
    // error ||
    (isTranslate && label ? t(`translation:labels.${label}`) : label) ||
    '';
  return (
    <div className="w--full  d--flex flex--column position--relative">
      {text && (
        <label
          className={`label--control font--sm font--500 m-b--sm   ${
            error ? 'text--danger' : 'text--grey'
          }`}
        >
          {text}{' '}
          {required && <span className=" font--sm text--danger ">*</span>}
        </label>
        //    <label
        //    className={`label--control font--2sm font--500  position--absolute top---8  left--15 bg--white  ${
        //      error ? 'text--danger' : 'text--grey'
        //    }`}
        //  >
        //    {text}{' '}
        //    {required && <span className=" font--sm text--danger ">*</span>}
        //  </label>
      )}
      <input
        {...rest}
        type={type}
        ref={ref}
        // placeholder={placeholder}
        placeholder={
          isTranslate && placeholder
            ? t(`translation:placeholders.${placeholder}`)
            : placeholder
        }
        className={`form--control w--full h-min--${height}  r-t-left--${radiusLeft} r-t-right--${radiusRight} r-b-left--${radiusLeft} r-b-right--${radiusRight} p-l--${paddingLeft} p-r--${paddingRight} ${extraClasses} ${
          error
            ? 'border-full--danger'
            : border
            ? borderStyle
            : 'border-full--transparent'
        }`}
      />
    </div>
  );
};

export default React.forwardRef(FormInput);
