import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import FormInput from '../../../../../components/FormInput';

import {
  getObjectLength,
  openInNewTab,
  submitBtnTxt,
} from '../../../../../helpers/functions';
import { useAuth } from '../../../../../hooks/useAuth';
import SubmitButton from '../../../../../components/Button/SubmitButton';
import { useTranslation } from 'react-i18next';

const Step1 = ({ control, errors, setValue, isLoading }) => {
  const { t } = useTranslation();
  const { user } = useAuth();

  useEffect(() => {
    if (user && getObjectLength(user)) {
      setValue('step_one.firstName', user.firstName);
      setValue('step_one.lastName', user.lastName);
    }
  }, [user]);
  return (
    <>
      <div className="w--full d--flex gap--md justify-content--center align-items--center flex--column m-t--xl ">
        <h3 className="m-b--0 font--lg text--c font--500 m-b--lg">
          {t('translation:message.ProvideNPNAndSSN')}
        </h3>
        <div className="w--full d--flex gap--lg flex--column-xs">
          <Controller
            name="step_one.firstName"
            control={control}
            render={({ field }) => (
              <FormInput
                type="text"
                {...field}
                label="First Name"
                error={errors?.step_one?.firstName?.message}
                height="40"
              />
            )}
          />
          <Controller
            name="step_one.lastName"
            control={control}
            render={({ field }) => (
              <FormInput
                {...field}
                label="Last Name"
                error={errors?.step_one?.lastName?.message}
                height="40"
              />
            )}
          />
        </div>{' '}
        <div className="w--full d--flex gap--lg flex--column-xs">
          <Controller
            name="step_one.npn"
            control={control}
            render={({ field }) => (
              <FormInput
                type="text"
                {...field}
                label="NPN"
                error={errors?.step_one?.npn?.message}
                height="40"
              />
            )}
          />
          <Controller
            name="step_one.ssn"
            control={control}
            render={({ field }) => (
              <FormInput
                {...field}
                type="text"
                label="SSN"
                error={errors?.step_one?.ssn?.message}
                height="40"
              />
            )}
          />
        </div>{' '}
        <h5 className="font--400 font--sm text--c">
          <span className="text--danger">*</span>
          {t('translation:message.SSNisrequiredForValidAgent')}
        </h5>
        <div className="d--flex align-items--center justify-content--center gap--sm w--full">
          <SubmitButton
            btnClasses="btn w-max--170"
            disabled={isLoading}
            size="md"
          >
            {submitBtnTxt(isLoading, t('translation:button.Verify'))}
          </SubmitButton>
        </div>
        <h5 className="font--400 font--sm m-b--sm text--c  m-t--sm">
          {t('translation:message.NIPRLookup')}{' '}
          <span
            className="text--primary c--pointer"
            onClick={() =>
              openInNewTab('https://nipr.com/help/look-up-your-npn')
            }
          >
            https://nipr.com/help/look-up-your-npn
          </span>
        </h5>
      </div>
    </>
  );
};

export default Step1;
