import React from 'react';
import Button from '../../../components/Button';
import { UploadCloud } from '../../../assets/icons/useIcons';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { uploadConsentDoc } from '../actions';
import { useAlert } from '../../../hooks/useAlert';
import { object } from 'yup';
import {
  requiredFieldFunction,
  requiredFileFunction,
} from '../../../helpers/yupValidations';
import FormSelect from '../../../components/FormSelect';
import FormInput from '../../../components/FormInput';
import { useTranslation } from 'react-i18next';
import { submitBtnTxt } from '../../../helpers/functions';

const initialValues = {
  submittedDate: '',
  signedDate: '',
  enrollmentYear: '',
  reason: 'CONSENT',
  file: null,
};
const schema = object({
  file: requiredFileFunction('File'),
  submittedDate: requiredFieldFunction('Submitted Date'),
  signedDate: requiredFieldFunction('Signed Date'),
  enrollmentYear: requiredFieldFunction('Enrollment Year'),
  reason: requiredFieldFunction('Reason'),
});

const UploadConsentDoc = ({ handleClose, uuId, docType }) => {
  const { t } = useTranslation();

  const queryclient = useQueryClient();
  const { showAlert } = useAlert();

  const {
    handleSubmit,
    register,
    watch,
    control,
    setError,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
    mode: 'onSubmit',
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: uploadConsentDoc,
    mutationKey: 'uploadConsentDoc',
    onSuccess: ({ data }) => {
      showAlert({ type: 'success', message: data?.data?.message });
      queryclient.invalidateQueries({
        queryKey: ['getMemberList'],
        exact: false,
      });
      handleClose();
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'danger', message: errMsg });
    },
  });
  const onSubmit = (data) => {
    console.log(data);
    mutate({ ...data, uuId, docType });
  };

  return (
    <form
      className="w--full h--full  d--flex gap--md flex--column"
      onSubmit={handleSubmit(onSubmit)}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="d--flex gap--lg w--full">
        <div className="w--full">
          {' '}
          <Controller
            name="submittedDate"
            control={control}
            render={({ field }) => (
              <FormInput
                {...field}
                type="date"
                label="Submission Date"
                error={errors?.submittedDate?.message}
              />
            )}
          />
        </div>
        <div className="w--full">
          {' '}
          <Controller
            name="signedDate"
            control={control}
            render={({ field }) => (
              <FormInput
                {...field}
                type="date"
                label="Signed Date"
                error={errors?.signedDate?.message}
                min={watch('submittedDate')}
              />
            )}
          />
        </div>
      </div>
      <div className="d--flex gap--lg w--full">
        <div className="w--full">
          {' '}
          <Controller
            name="enrollmentYear"
            control={control}
            render={({ field }) => (
              <FormSelect
                {...field}
                label="Enrollment Year"
                error={errors?.enrollmentYear?.message}
                placeholder="Select Year"
                options={[
                  {
                    label: '2020',
                    value: '2020',
                  },
                  {
                    label: '2021',
                    value: '2021',
                  },
                  {
                    label: '2022',
                    value: '2022',
                  },
                  {
                    label: '2023',
                    value: '2023',
                  },
                  {
                    label: '2024',
                    value: '2024',
                  },
                  {
                    label: '2025',
                    value: '2025',
                  },
                ]}
                height="40"
              />
            )}
          />
        </div>
        <div className="w--full">
          <Controller
            name="reason"
            control={control}
            render={({ field }) => (
              <FormSelect
                {...field}
                label="Reason"
                error={errors?.reason?.message}
                options={[
                  {
                    label: 'Consent',
                    value: 'CONSENT',
                  },
                  {
                    label: 'App Review',
                    value: 'APP_REVIEW',
                  },
                  {
                    label: 'Change Approval',
                    value: 'CHANGE_APPROVAL',
                  },
                  {
                    label: 'Multi Type',
                    value: 'MULTI_TYPE',
                  },
                ]}
                height="40"
              />
            )}
          />
        </div>
      </div>

      <div>
        {errors?.file?.message && (
          <label
            className={`label--control font--sm font--500 m-t--md  text--danger`}
          >
            {errors?.file?.message}
          </label>
        )}
        <label
          className="d--flex m-b--md m-t--sm border-full--primary border--dashed p--xl radius--sm flex--column align-items--center justify-content--center c--pointer"
          htmlFor="file"
        >
          <input
            {...register('file')}
            type="file"
            accept=".pdf"
            id="file"
            onChange={(e) => {
              const file = e.target.files?.[0];
              if (file?.type.includes('pdf')) {
                if (file.size <= 1048576) {
                  // 1 MB in bytes
                  setValue(`file`, file, {
                    shouldValidate: true,
                  });
                  e.target.value = null;
                } else {
                  setError(`file`, {
                    type: 'manual',
                    message: t('translation:labels.FileLessThan1MB'),
                  });
                  setValue(`file`, null);
                }
              } else {
                setError(`file`, {
                  type: 'manual',
                  message: t('translation:labels.PlsUploadPDFFile'),
                });
                setValue('file', null);
              }
            }}
            hidden
          />
          <span className="text--primary">
            <UploadCloud width={30} height={30} />
          </span>
          <h3 className="text--grey">
            {' '}
            {t('translation:labels.Upload New Document')}
          </h3>
          <p className="m-t--sm font--sm text--grey-600">
            {t('translation:labels.Click to upload')}
          </p>
          <p className="m-t--sm text--danger">{watch('file')?.name ?? ''}</p>
        </label>
      </div>

      <div className="d--flex justify-content--end gap--sm p-t--md border-top--black-100">
        <Button
          type="button"
          variant="transparent"
          borderColor="primary"
          data-link-hover
          isOutline
          color="black"
          btnClasses="btn w-max--150"
          onClick={handleClose}
        >
          {t('translation:button.Cancel')}
        </Button>
        <Button
          btnClasses="btn  w-max--150"
          type="submit"
          variant="primary"
          color="black"
          disabled={isLoading}
        >
          {submitBtnTxt(isLoading, t('translation:button.Upload'))}
        </Button>
      </div>
    </form>
  );
};

export default UploadConsentDoc;
