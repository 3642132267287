import { combineReducers } from 'redux';
import themeSlice from './Slices/themeSlice';
import pdfSlice from './Slices/pdfSlice';
import languageSlice from './Slices/languageSlice';

const rootReducer = combineReducers({
  theme: themeSlice,
  pdfSlice: pdfSlice,
  language: languageSlice,
});

export default rootReducer;
