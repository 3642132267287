import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import FormInput from '../../../../../components/FormInput';
import PhoneInput from 'react-phone-number-input/react-hook-form';
import FormSelect from '../../../../../components/FormSelect';
import { State, City } from 'country-state-city';
import Button from '../../../../../components/Button';
import RadioBoxInput from '../../../../../components/RadioBoxInput';
import SubmitButton from '../../../../../components/Button/SubmitButton';
import { useTranslation } from 'react-i18next';

const statesList = State.getStatesOfCountry('US')?.map((state) => ({
  value: state.isoCode,
  label: state.name,
}));

const Step2 = ({ control, errors, watch, setValue, clearErrors }) => {
  const { t } = useTranslation();

  const [cityList, setCityList] = useState([]);
  let [watchisAssociatedAgency, watchState] = watch([
    'step_two.isAssociatedAgency',
    'step_two.associatedState',
  ]);

  useEffect(() => {
    if (watchState) {
      const cities = City.getCitiesOfState('US', watchState).map((city) => ({
        value: city.name,
        label: city.name,
      }));
      setCityList(cities);
    }
  }, [watchState]);

  useEffect(() => {
    if (watchisAssociatedAgency && watchisAssociatedAgency === 'N') {
      clearErrors();
    }
  }, [watchisAssociatedAgency]);

  return (
    <>
      <div className="w--full d--flex gap--md justify-content--center align-items--center flex--column m-t--xl m-b--xl">
        <h3 className="m-b--0 font--lg text--c font--500">
          {t('translation:AgencyInfo.h1')}{' '}
        </h3>
        <div className="w--full d--flex justify-content--center align-items--center gap--md">
          <Controller
            name="step_two.isAssociatedAgency"
            control={control}
            render={({ field }) => (
              <RadioBoxInput
                {...field}
                htmlFor="isAssociatedAgency_y"
                label=                      {t('translation:labels.Yes')}

                value="Y"
                checked={watchisAssociatedAgency === 'Y'}
              />
            )}
          />
          <Controller
            name="step_two.isAssociatedAgency"
            control={control}
            render={({ field }) => (
              <RadioBoxInput
                {...field}
                htmlFor="isAssociatedAgency_n"
                label="No"
                value="N"
                checked={watchisAssociatedAgency === 'N'}
              />
            )}
          />
        </div>
      </div>
      {watchisAssociatedAgency === 'Y' ? (
        <>
          <span className="  font--sm font--600 bg--white d--flex justify-content--center align-items--center  text--primary m-b--lg">
            {t('translation:AgencyInfo.h4')}{' '}
          </span>
          <div className="w--full d--flex gap--lg m-b--lg flex--column-xs">
            <Controller
              name="step_two.associatedAgencyName"
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  label="Agency Name"
                  error={errors?.step_two?.associatedAgencyName?.message}
                  height="40"
                />
              )}
            />

            <Controller
              name="step_two.associatedAgencyEmail"
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  label="Agency Email"
                  error={errors?.step_two?.associatedAgencyEmail?.message}
                  height="40"
                />
              )}
            />
          </div>
          <div className="w--full d--flex gap--lg m-b--lg flex--column-xs">
            <div className="w--full">
              <label
                className={`label--control font--sm font--500 m-b--sm d--inline-block ${
                  errors?.step_two?.associatedAgencyPhone?.message
                    ? 'text--danger'
                    : 'text--grey'
                }`}
              >
                {errors?.step_two?.associatedAgencyPhone?.message
                  ? t(
                      `translation:errors.${errors?.step_two?.associatedAgencyPhone?.message}`,
                    )
                  : t('translation:labels.Agency Phone')}{' '}
                <span className=" font--sm text--danger ">*</span>
              </label>
              <PhoneInput
                name="step_two.associatedAgencyPhone"
                control={control}
                defaultCountry={'US'}
                international={true}
                withCountryCallingCode={true}
                placeholder="Enter your phone"
                internationalIcon={() => (
                  <span>
                    <i className="fa fa-phone icon-circle bg-danger"></i>
                  </span>
                )}
                limitMaxLength={true}
                className={`form--control w--full h-min--36  radius--sm p-l--md p-r--md  d--flex align-items--center ${
                  errors?.step_two?.associatedAgencyPhone?.message
                    ? 'border-full--danger'
                    : 'border-full--black-200'
                }`}
              />
            </div>
            <Controller
              name="step_two.associatedAgencyNPN"
              control={control}
              render={({ field }) => (
                <FormInput
                  type="number"
                  {...field}
                  label="Agency NPN"
                  error={errors?.step_two?.associatedAgencyNPN?.message}
                  height="40"
                  required={false}
                />
              )}
            />
          </div>
          <div className="w--full d--flex gap--lg m-b--lg flex--column-xs">
            <Controller
              name="step_two.associatedAddressOne"
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  label="Address 1"
                  error={errors?.step_two?.associatedAddressOne?.message}
                  height="40"
                />
              )}
            />
            <Controller
              name="step_two.associatedAddressTwo"
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  label="Address 2"
                  error={errors?.step_two?.associatedAddressTwo?.message}
                  height="40"
                  required={false}
                />
              )}
            />
          </div>
          <div className="w--full d--flex gap--lg m-b--lg flex--column-xs">
            <Controller
              name="step_two.associatedState"
              control={control}
              rules={{
                onChange() {
                  setValue('step_two.associatedCity', '');
                },
              }}
              render={({ field }) => (
                <FormSelect
                  {...field}
                  label="State"
                  placeholder="Select State"
                  error={errors?.step_two?.associatedState?.message}
                  height="40"
                  options={statesList}
                />
              )}
            />
            <Controller
              name="step_two.associatedCity"
              control={control}
              render={({ field }) => (
                <FormSelect
                  {...field}
                  label="City"
                  placeholder="Select City"
                  error={errors?.step_two?.associatedCity?.message}
                  height="40"
                  options={cityList}
                />
              )}
            />
          </div>
          <div className="w--full d--flex gap--lg m-b--lg flex--column-xs">
            <Controller
              name="step_two.associatedZipCode"
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  label="Zip Code"
                  error={errors?.step_two?.associatedZipCode?.message}
                  height="40"
                />
              )}
            />
          </div>
        </>
      ) : null}
      <div className="d--flex align-items--center justify-content--center gap--sm w--full ">
        <SubmitButton btnClasses="btn w-max--170" size="md">
          {t('translation:button.Next')}
        </SubmitButton>
      </div>
    </>
  );
};

export default Step2;
