import { t } from 'i18next';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

export function generateRandomNumber(length = 4) {
  return Math.random()
    .toString(36)
    .substring(2, length + 2);
}

export const getToken = () => {
  return localStorage.getItem('nph-agency-acc-tkn') || null;
};

export const convertDateFunction = (date, format = 'DD-MMM-YYYY') => {
  return moment(date).format(format);
};

export const dataURLtoBlob = (dataURL) => {
  const arr = dataURL?.split(',');
  const mime = arr[0]?.match(/:(.*?);/)?.[1];
  const bstr = atob(arr?.[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};
export const submitBtnTxt = (
  condition,
  btnTxt = t('translation:button.Submit'),
) => {
  if (condition) {
    return t('translation:button.PleaseWait');
  } else {
    return btnTxt;
  }
};
export const get_browser = () => {
  var ua = navigator.userAgent,
    tem,
    M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i,
      ) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return { name: 'IE', version: tem[1] || '' };
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\bOPR|Edge\/(\d+)/);
    if (tem != null) {
      return { name: 'Opera', version: tem[1] };
    }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) {
    M.splice(1, 1, tem[1]);
  }
  return {
    name: M[0],
    version: M[1],
  };
};
export const isPlatform = () => {
  const { userAgent, maxTouchPoints, platform } = navigator;
  let OS;
  if (userAgent.match(/Android/i)) {
    OS = 'android';
  } else if (
    userAgent.match(/iP(ad|hone|od)/i) ||
    (maxTouchPoints && maxTouchPoints > 2 && /MacIntel/.test(platform))
  ) {
    OS = 'ios';
  } else if (userAgent.match(/Mac(intosh| OS X)/i)) {
    OS = 'macos';
  } else if (userAgent.match(/Windows/i)) {
    OS = 'windows';
  }
  return OS;
};
export const getSessionInfo = () => {
  const browser = get_browser();
  const os = isPlatform();
  const browser_version = `${browser?.name} - ${browser?.version}`;
  const os_version = os;
  return {
    device_type: 'desktop',
    browser_version: browser_version ? browser_version : null,
    os_version: os_version ? os_version : null,
  };
};

export const createPrivateChatId = (obj) => {
  const sortAlphaNum = (a, b) => a.localeCompare(b, 'en', { numeric: true });
  return obj.sort(sortAlphaNum).join('_');
};
export const stringToColour = (str) => {
  if (!str || !str.length) return;
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = '#';
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xff;
    colour += ('00' + value.toString(16)).substr(-2);
  }
  return colour;
};

export const darkenColor = (hexColor, factor = 20) => {
  if (!hexColor) return '#fff';
  // Remove the '#' character if present
  hexColor = hexColor.replace('#', '');

  // Convert the hex color to RGB
  const r = parseInt(hexColor.slice(0, 2), 16);
  const g = parseInt(hexColor.slice(2, 4), 16);
  const b = parseInt(hexColor.slice(4, 6), 16);

  // Darken the color by reducing the RGB values
  const newR = Math.max(0, r - factor);
  const newG = Math.max(0, g - factor);
  const newB = Math.max(0, b - factor);

  // Convert the darkened RGB back to hex
  const darkenedColor = `#${newR.toString(16).padStart(2, '0')}${newG
    .toString(16)
    .padStart(2, '0')}${newB.toString(16).padStart(2, '0')}`;

  return darkenedColor;
};

export const lightenColor = (hexColor, factor = 20) => {
  if (!hexColor) return '#fff';

  // Remove the '#' character if present
  hexColor = hexColor.replace('#', '');

  // Convert the hex color to RGB
  const r = parseInt(hexColor.slice(0, 2), 16);
  const g = parseInt(hexColor.slice(2, 4), 16);
  const b = parseInt(hexColor.slice(4, 6), 16);

  // Lighten the color by increasing the RGB values
  const newR = Math.min(255, r + factor);
  const newG = Math.min(255, g + factor);
  const newB = Math.min(255, b + factor);

  // Convert the lightened RGB back to hex
  const lightenedColor = `#${newR.toString(16).padStart(2, '0')}${newG
    .toString(16)
    .padStart(2, '0')}${newB.toString(16).padStart(2, '0')}`;

  return lightenedColor;
};

export function capitalizeFirstLetter(string) {
  if (!string) return string;
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export const formatUTCDate = (timestring) => {
  if (!timestring) return '';
  const utcMoment = moment.utc(timestring);
  const localMoment = utcMoment.local();
  let timeStrings = localMoment?.format();
  if (!timeStrings) return '';
  return moment(timeStrings).format('hh:mm A');
};

export const getObjectLength = (obj) => {
  try {
    return Object.keys(obj).length;
  } catch (_) {
    return 0;
  }
};

export const downloadFileFromURL = (
  url = '',
  cb = () => {
    return;
  },

  fileName = '',
) => {
  if (!url) return;
  cb(true);
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = fileName ? fileName : 'New download';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      cb(false);
    })
    .catch(() => {
      cb(false);
    });
};

export const generateUniqueId = () => {
  return uuidv4();
};
export const openInNewTab = (url) => {
  window.open(url, '_blank', 'noopener,noreferrer');
};

export function createArrayWithLength(length) {
  // Initialize an empty array
  var array = [];
  // Fill the array with the specified number of elements
  for (var i = 0; i < length; i++) {
    array.push(i); // You can push any default value here
  }
  return array;
}

export function getOS() {
  if (window) {
    let OS = 'windows';
    const { userAgent, maxTouchPoints, platform } = window.navigator;
    if (userAgent.match(/Android/i)) {
      OS = 'android';
    } else if (
      userAgent.match(/iP(ad|hone|od)/i) ||
      (maxTouchPoints && maxTouchPoints > 2 && /MacIntel/.test(platform))
    ) {
      OS = 'ios';
    } else if (userAgent.match(/Mac(intosh| OS X)/i)) {
      OS = 'macos';
    } else if (userAgent.match(/Windows/i)) {
      OS = 'windows';
    }
    return OS;
  }
}
