import React from 'react';
import useIcons from '../../../../assets/icons/useIcons';
import DriveVideo from '../../../../assets/video/vidDrive.mp4';
import { useTranslation } from 'react-i18next';

const DriveInfo = ({ setShowInfo }) => {
  const { t } = useTranslation();

  const { CrossIcon } = useIcons();

  return (
    <div className="d--flex flex--column  gap--xl flex--column border-full--black-100 radius--sm w--full p--md m-t--md position--relative">
      <div
        className="toggle-sidebar-icon d--flex align-items--center justify-content--center radius--full  bg--black  text--white 
position--absolute w-max--28 w-min--28 h-max--28 h-min--28 box--shadow c--pointer  top---13 right---10 c--pointer"
        onClick={() => setShowInfo(false)}
      >
        <CrossIcon width={12} height={12} />
      </div>
      <div className="d--flex flex--column gap--sm w--full ">
        <div className="font--sm font--600 bg--primary-100 border-full--primary text--black radius--sm h-min--40 d--flex align-items--center p-l--md">
          {t('translation:DriveInfo.h1')}
        </div>
        <div className="w--full d--flex flex--column gap--lg font--sm">
          <div className="w--full ">
            <div className="font--600">
              {' '}
              1.
              {t('translation:DriveInfo.h2')}:
            </div>
            <div className="d--flex flex--column gap--sm font--sm p--md">
              <div className="w--full">
                -{t('translation:DriveInfo.h3')}.
                (https://console.cloud.google.com)
              </div>
              <div className="w--full">-{t('translation:DriveInfo.h4')}</div>
            </div>
          </div>

          <div className="w--full ">
            <div className="font--600">
              2.
              {t('translation:DriveInfo.h5')}:
            </div>
            <div className="d--flex flex--column gap--sm font--sm p--md">
              <div className="w--full">-{t('translation:DriveInfo.h6')}</div>
            </div>
          </div>

          <div className="w--full ">
            <div className="font--sm font--600">
              3.
              {t('translation:DriveInfo.h7')}:
            </div>
            <div className="d--flex flex--column gap--sm font--sm p--md">
              <div className="w--full">-{t('translation:DriveInfo.h8')}</div>
              <div className="w--full">-{t('translation:DriveInfo.h9')}</div>
              <div className="w--full">-{t('translation:DriveInfo.h10')}</div>
              <div className="w--full">-{t('translation:DriveInfo.h11')}</div>
            </div>
          </div>

          <div className="w--full">
            <span className="font--600 w--full">Redirect URI</span>:{' '}
            {process.env.REACT_APP_BASE_API_URL}
            configuration/storage?type=google_drive
          </div>
          <div className="w--full">
            <span className="font--600"> {t('translation:button.Info')}</span>:{' '}
            {t('translation:message.RedirectURl')}
          </div>
        </div>
        <div className="w--full d--flex align-items--center justify-content--center mx--auto m-t--xl w-max--600">
          <video src={DriveVideo} controls className="w--full"></video>
        </div>
      </div>
    </div>
  );
};

export default DriveInfo;
