import React, { useEffect, useState } from 'react';
import Step1 from './Components/Step1';
import Step2 from './Components/Step2';
import Step3 from './Components/Step3';
import nextereLogo from '../../../../assets/images/nextere-logo.png';
import {
  initialValues,
  stepOneSchema,
  stepThreeSchema,
  stepTwoSchema,
} from './constants';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '../../../../components/Button';
import AlertContainer from '../../../../components/AlertContainer';
import { LogoutIcon } from '../../../../assets/icons/useIcons';
import { useAuth } from '../../../../hooks/useAuth';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createUser, verifyUser } from './actions';
import { useAlert } from '../../../../hooks/useAlert';
import Spinner from '../../../../components/Spinner';
import { getObjectLength } from '../../../../helpers/functions';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';
import { setLanguage } from '../../../../redux/Slices/languageSlice';

let stepShema = {
  1: stepOneSchema,
  2: stepTwoSchema,
  3: stepThreeSchema,
};
const VerifySignup = () => {
  const { t } = useTranslation();
  const { language } = useSelector((state) => state?.language);

  const [activeStep, setActiveStep] = useState(1);
  let queryClient = useQueryClient();

  const { showAlert } = useAlert();
  const { clearUser, user } = useAuth();
  const { verifySSN, verifyNPN } = user || {};
  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(stepShema[activeStep]),
    mode: 'onSubmit',
  });
  const { mutate, isLoading } = useMutation({
    mutationFn: verifyUser,
    mutationKey: 'verifyUser',
    onSuccess: ({ data }) => {
      showAlert({ type: 'success', message: data?.data?.message });
      queryClient.invalidateQueries({ queryKey: ['getUserDetails'] });
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'danger', message: errMsg });
    },
  });
  const { mutate: createUserMutate, isLoading: createLoading } = useMutation({
    mutationFn: createUser,
    mutationKey: 'createUser',
    onSuccess: ({ data }) => {
      showAlert({ type: 'success', message: data?.data?.message });
      queryClient.invalidateQueries({ queryKey: ['getUserDetails'] });
      setActiveStep(2);
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'danger', message: errMsg });
    },
  });

  const onSubmit = (values) => {
    if (activeStep === 1) {
      mutate(values?.step_one);
    }

    if (activeStep === 2) {
      setActiveStep(3);
    }

    if (activeStep === 3) {
      const { isAssociatedAgency, ...rest } = values?.step_two;
      const { isOwnAgency, ...ownRest } = values?.step_three;
      let payload = {
        isAssociatedAgency: isAssociatedAgency === 'Y',
        ...(isAssociatedAgency === 'Y' ? rest : {}),

        isOwnAgency: isOwnAgency === 'Y',
        ...(isOwnAgency === 'Y' ? ownRest : {}),
      };
      createUserMutate(payload);
    }
  };

  useEffect(() => {
    if (user && getObjectLength(user) > 0) {
      const { verifySSN, verifyNPN } = user || {};
      if (verifySSN && verifyNPN) {
        setActiveStep(2);
      }
    }
  }, [user]);

  useEffect(() => {
    if (language) {
      i18next.changeLanguage(language);
    }
  }, [language]);

  if (!user) {
    return (
      <div className="d--flex align-items--center justify-content--center h--full w--full">
        <Spinner size="lg" />
      </div>
    );
  }
  return (
    <div className="w--full h--full  d--flex  flex--column loginPage bg--black-50 overflow--y-auto ">
      <div className="w--full h-min--60 d--flex justify-content--between align-items--center bg--white p-t--xs p-l--md p-r--md border-bottom--black-50">
        <img src={nextereLogo} alt="nextereLogo" className="w-max--150" />

        <div className="d--flex gap--sm">
          <select
            name="language"
            id="language"
            className="border--0 font--15 "
            value={language}
            onChange={(e) => dispatch(setLanguage(e.target.value))}
          >
            <option value="en">English</option>
            <option value="es">Spanish</option>
          </select>
          <Button
            btnClasses="btn w-max--100 "
            size="md"
            variant="primary"
            color="black"
            onClick={() => {
              clearUser();
            }}
          >
            <span className="m-r--xs">
              {' '}
              <LogoutIcon />
            </span>
            Logout
          </Button>
        </div>
      </div>
      <div className="w--full h--full overflow--auto p-l--md p-r--md">
        <div className="w--full  p-t--xl d--flex align-items--center justify-content--center p-b--xl flex--column">
          {/* <div className="w--full gap--sm d--flex flex--column align-items--center justify-content--center m-b--sm">
          <h3 className="font--2xl text--primary ">
            <img src={nextereLogo} alt="nextereLogo" className="w-max--150" />
          </h3>
        </div> */}
          <div className="radius--md  gap--xl w-max--600 w--full bg--secondary p--xl z-index--xs bg--white box--shadow d--flex align-items--center justify-content--center flex--column gap--xs h-min--300">
            <div className="w--full">
              <form
                className="w--full d--flex flex--column gap--sm"
                onSubmit={handleSubmit(onSubmit)}
                autoComplete="off"
              >
                <div className="w--full overflow--y-auto">
                  {!verifySSN && !verifyNPN && activeStep === 1 && (
                    <Step1
                      {...{
                        control,
                        errors,
                        getValues,
                        setValue,
                        isLoading,
                        clearErrors,
                      }}
                    />
                  )}

                  {verifySSN && verifyNPN && activeStep === 2 && (
                    <Step2
                      {...{
                        control,
                        errors,
                        watch,
                        setValue,
                        clearErrors,
                      }}
                    />
                  )}

                  {verifySSN && verifyNPN && activeStep === 3 && (
                    <Step3
                      {...{
                        control,
                        errors,
                        setValue,
                        watch,
                        setActiveStep,
                        isLoading: createLoading,
                        clearErrors,
                      }}
                    />
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="main-alert position--absolute d--flex flex--column gap--sm">
        <AlertContainer />
      </div>
    </div>
  );
};

export default VerifySignup;
