import React, { useEffect, useState } from 'react';
import GoogleDrive from '../../assets/images/google-drive.png';
import Dropbox from '../../assets/images/dropbox.png';
import OneDrive from '../../assets/images/office.png';
import Icloud from '../../assets/images/icloud.png';
import ActionConfirmation from '../../components/ActionConfirmation';
import { useQuery } from '@tanstack/react-query';
import {
  dropBoxGetAccessToken,
  getDefaultStorage,
  googleDriveGetAccessToken,
} from './actions';
import { useSetDefaultStorageMutate } from './constants';
import { useAlert } from '../../hooks/useAlert';
import useIcons, {
  ClaimIcon,
  ConfigureIcon,
  FolderIcon,
} from '../../assets/icons/useIcons';
import Modal from '../../components/Modal';
import { useNavigate, useSearchParams } from 'react-router-dom/dist';
import { useMutation } from '@tanstack/react-query';
import DriveFolders from './Configuration/Drive/DriveFolders';
import StorageInfo from './Configuration/Drive/DriveInfo';
import Tooltip from '../../components/Tooltip';
import DriveSetting from './Configuration/Drive/DriveSetting';
import DriveConfig from './Configuration/Drive/DriveConfig';
import DropboxConfig from './Configuration/DropBox/DropboxConfig';
import DropboxFolders from './Configuration/DropBox/DropboxFolders';
import DropboxSetting from './Configuration/DropBox/DropboxSetting';
import { useTranslation } from 'react-i18next';

export const storageIntegrationArr = [
  {
    id: 1,
    img: GoogleDrive,
    label: 'Google Drive',
    type: 'GOOGLE_DRIVE',
  },
  {
    id: 2,
    img: Dropbox,
    label: 'Dropbox',
    type: 'DROPBOX',
  },
  { id: 3, img: OneDrive, label: 'One Drive', type: 'ONE_DRIVE' },
  { id: 4, img: Icloud, label: 'iCloud', type: 'I_CLOUD' },
];

const Storage = () => {
  const { t } = useTranslation();
  const { SettingIcon } = useIcons();
  const { setDefaultStorageMutate } = useSetDefaultStorageMutate();
  const [showInfo, setShowInfo] = useState({ value: '', type: '' });
  const [openModal, setOpenModal] = useState(false);
  const params = useSearchParams();
  const navigate = useNavigate();
  const code = params?.[0]?.get('code');
  const apiType = params?.[0]?.get('type');

  const { showAlert } = useAlert();
  const { data: defaultStorage, refetch } = useQuery({
    queryFn: getDefaultStorage,
    queryKey: ['getDefaultStorage'],
    select: (data) => data?.data?.data,
  });

  const handleUpdateStatus = (type) => {
    setDefaultStorageMutate(
      { defaultType: type },
      {
        onSuccess: ({ data }) => {
          showAlert({
            type: 'success',
            message:
              data?.data?.message ?? 'Default storage saved successfully.',
          });

          refetch();
        },
      },
    );
  };

  const { mutate } = useMutation({
    mutationFn: googleDriveGetAccessToken,
    onSuccess: ({ data }) => {
      showAlert({ type: 'success', message: data?.data?.message });
      navigate('/configuration/storage');
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'danger', message: errMsg });
    },
  });

  const { mutate: dropBoxAccessTokenMutate } = useMutation({
    mutationFn: dropBoxGetAccessToken,
    onSuccess: ({ data }) => {
      showAlert({ type: 'success', message: data?.data?.message });
      navigate('/configuration/storage');
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'danger', message: errMsg });
    },
  });

  useEffect(() => {
    if (code) {
      if (apiType === 'dropbox') {
        dropBoxAccessTokenMutate({ code });
      } else {
        mutate({ code });
      }
    }
  }, [code]);

  const handleClose = () => setOpenModal(false);

  const modalLookUp = {
    GOOGLE_DRIVE: {
      component: (
        <DriveConfig
          {...{
            handleClose: () => setOpenModal(false),
            rowData: { ...defaultStorage },
          }}
        />
      ),

      title: 'Drive Configuration',
    },
    DROPBOX: {
      component: (
        <DropboxConfig
          {...{
            handleClose: () => setOpenModal(false),
            rowData: { ...defaultStorage },
          }}
        />
      ),
      title: 'Dropbox Configuration',
    },
  };
  const foldersLookup = {
    GOOGLE_DRIVE: <DriveFolders {...{ setShowInfo }} />,
    DROPBOX: <DropboxFolders {...{ setShowInfo }} />,
  };
  const settingLookup = {
    GOOGLE_DRIVE: (
      <DriveSetting {...{ setShowInfo, rowData: { ...defaultStorage } }} />
    ),
    DROPBOX: (
      <DropboxSetting {...{ setShowInfo, rowData: { ...defaultStorage } }} />
    ),
  };

  return (
    <>
      {/* <h3 className="font--md font--600 m-b--md text--black">Storage</h3> */}
      <div className="d--flex flex--column border-full--black-100 radius--sm w--full">
        <div className="label--control font--md font--500   border-bottom--secondary-100 h-min--60 p-l--lg p-r--md d--flex align-items--center justify-content--between w--full">
          {t('translation:storage.h1')}
          <div></div>
        </div>
        <div className=" d--flex flex--wrap gap--md  p--lg">
          <p className="font--sm m-b--md text--secondary ">
            {t('translation:storage.h2')}
          </p>
          <div className="w--full d--flex flex--wrap gap--md ">
            {storageIntegrationArr.map(({ id, label, img, type }) => {
              return (
                <div className="d--flex flex--column gap--sm w--full-xs">
                  <div
                    key={id}
                    className="w-min--200 d--flex flex--column gap--sm  align-items--center radius--sm border-full--secondary-600  border--dashed p--md  position--relative"
                  >
                    <div className="d--flex align-items--center justify-content--between w--full position--absolute top--5 p-r--sm p-l--sm">
                      {/* <div className=""> */}
                      {defaultStorage?.defaultType === type ? (
                        <Tooltip
                          text="Setting"
                          className="d--flex w-min--20 c--pointer justify-content--center text--black-400"
                          onClick={() => {
                            if (['GOOGLE_DRIVE', 'DROPBOX'].includes(type)) {
                              setShowInfo({ value: 'setting', type });
                            }
                          }}
                        >
                          <SettingIcon width={16} height={16} />
                        </Tooltip>
                      ) : (
                        <div> </div>
                      )}

                      <div className="d--flex gap--sm">
                        <Tooltip
                          text={t('translation:button.Configure')}
                          className="d--flex w-min--20 c--pointer justify-content--center text--black-400"
                          onClick={() => {
                            if (['GOOGLE_DRIVE', 'DROPBOX'].includes(type)) {
                              setOpenModal(type);
                            }
                          }}
                        >
                          <ConfigureIcon width={16} />
                        </Tooltip>
                        {defaultStorage?.defaultType === type && (
                          <Tooltip
                            text="View Files"
                            className="d--flex w-min--20 c--pointer justify-content--center text--black-400"
                            onClick={() => {
                              if (['GOOGLE_DRIVE', 'DROPBOX'].includes(type)) {
                                setShowInfo({ value: 'folder', type });
                              }
                            }}
                          >
                            <FolderIcon width={16} />
                          </Tooltip>
                        )}
                      </div>
                    </div>
                    <div className="w-min--75 h-min--75 d--flex align-items--center h-max--75">
                      <img src={img} alt="..." className="w-max--75 w--full" />
                    </div>
                    <h3 className="font--400   text--secondary m-t--sm">
                      {label}
                    </h3>
                  </div>
                  {defaultStorage?.defaultType === type ? (
                    <div className="d--flex gap--sm justify-content--between p-r--sm">
                      <div className=" text--success  gap--xs d--flex align-items--center c--pointer ">
                        <ClaimIcon width={16} height={16} />
                        {t('translation:button.Default')}
                      </div>
                      <div
                        className=" text--black  d--flex align-items--center c--pointer"
                        onClick={() => {
                          if (type === 'GOOGLE_DRIVE') {
                            setShowInfo({ value: 'info', type });
                          }
                        }}
                      >
                        {t('translation:button.Info')}
                      </div>
                    </div>
                  ) : (
                    <div className="d--flex gap--sm justify-content--between p-r--sm">
                      {['GOOGLE_DRIVE', 'DROPBOX'].includes(type) && (
                        <>
                          <ActionConfirmation
                            message={t('translation:message.ActiveStorage')}
                            onConfirm={() => handleUpdateStatus(type)}
                          >
                            <div className=" text--primary  d--flex align-items--center c--pointer">
                              {t('translation:button.ClickDefault')}
                            </div>
                          </ActionConfirmation>

                          <div
                            className=" text--black  d--flex align-items--center justify-content--end c--pointer"
                            onClick={() => {
                              if (type === 'GOOGLE_DRIVE') {
                                setShowInfo({ value: 'info', type });
                              }
                            }}
                          >
                            {t('translation:button.Info')}
                          </div>
                        </>
                      )}

                      {['ONE_DRIVE', 'I_CLOUD'].includes(type) && (
                        <div className="d--flex align-items--center justify-content--center w--full text--danger">
                          {t('translation:commonWords.ComingSoon')}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {showInfo?.value === 'info' ? (
        <StorageInfo {...{ setShowInfo }} />
      ) : showInfo?.value === 'folder' ? (
        <div className="m-t--sm">{foldersLookup[showInfo?.type]}</div>
      ) : showInfo?.value === 'setting' ? (
        <div className="m-t--sm">
          {settingLookup[showInfo?.type]}
          {/* <DriveSetting {...{ setShowInfo, rowData: { ...defaultStorage } }} /> */}
        </div>
      ) : null}

      {openModal ? (
        <Modal
          footerComponent={null}
          headerComponent={null}
          shouldCloseOnClickOutside={true}
          width="xl"
          height="200"
          size="700"
          handleClose={handleClose}
          title={t(`translation:title.${modalLookUp?.[openModal]?.title}`)}
        >
          {modalLookUp[openModal]?.component}
        </Modal>
      ) : null}
    </>
  );
};

export default Storage;
