import React, { useState } from 'react';
import useIcons from '../../assets/icons/useIcons';
import FormInput from '../../components/FormInput';
import { useChat } from '../../hooks/useChat';
import { useAuth } from '../../hooks/useAuth';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Avatar from '../../components/Avatar';
import { useSocket } from '../../hooks/useSocket';
import Lottie from 'lottie-react';
import ChatAnimation from '../../assets/animations/chat-animation.json';
import { useTranslation } from 'react-i18next';

export const ChatPage = () => {
  const { t } = useTranslation();
  const { ArrowLeftIcon } = useIcons();
  const { chats, handleResetUnread, typingList } = useChat();
  const [search, setSearch] = useState('');
  const { SearchIcon } = useIcons();
  const navigate = useNavigate();
  const { user } = useAuth();
  const location = useLocation();
  const { socket = null } = useSocket();

  function handleChatClick(chat) {
    if (chat?.unread > 0 || chat?.metaData?.unReads?.[user?._id] > 0) {
      handleResetUnread(chat, socket);

      setTimeout(() => {
        socket?.emit('fetch-chat', {
          userID: user?._id,
        });
      }, 3000);
    }

    navigate(chat?.chatId);
  }

  return (
    <div className="chatPage w--full d--flex gap--md h--full flex--column">
      <div className="d--flex w--full h--full gap--md flex--column-xs">
        <div className="chatListSec w--full w-max--300 bg--white d--flex flex--column radius--sm border-full--black-100 h-min--250 w--full-xs w-max--auto-xs">
          {/* <div className="font--md font--600 d--flex align-items--center justify-content--between p--md text--black">
            Chats
          </div> */}
          <div className="w--full   d--flex gap--sm bg--black-50 p--md">
            <div className="w--full position--relative searchFilter d--flex align-items--center">
              <FormInput
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                name="search"
                placeholder="Search"
                // height="32"
                paddingLeft="2xl"
              />
              <span className=" position--absolute text--grey-600 left--15 top--10">
                <SearchIcon width={21} height={21} />
              </span>
            </div>
          </div>
          {/* <div className="p-l--md p-r--md p-t--sm p-b--sm w--full radius--sm">
            <div className="bg--black-50  radius--sm d--flex align-items--center justify-content--between p-t--xs p-b--xs p-l--sm p-r--sm ">
              <label className="label--control font--sm font--500 text--grey d--flex">
                Carrier:
              </label>
              <div className="w-max--100">
                <FormSelect height="28" extraClasses="border--0" />
              </div>
            </div>
          </div> */}
          {/* <div className="h-min--1 bg--black-50 m-t--xs"></div> */}
          <div className="w--full h--full overflow--auto chatList p--sm gap--xs d--flex flex--column">
            {chats && chats?.length > 0
              ? chats?.map((chat) => {
                  return chat?.users
                    ?.filter((u) => {
                      return u?.firstname
                        ?.toLowerCase()
                        .includes(search.toLowerCase());
                    })
                    ?.map((u) => {
                      const isActive =
                        chat?.chatId === location?.pathname?.split('/')?.[2];

                      const typingUser =
                        typingList && typingList?.length > 0
                          ? typingList?.filter(
                              (c) => c?.chatId === chat?.chatId,
                            )?.[0]?.users ?? {}
                          : {};

                      if (u?.id !== user?._id) {
                        return (
                          <div
                            key={chat?.chatId}
                            className={`chatListItem c--pointer  d--flex gap--sm p-l--sm p-r--sm p-b--md p-t--md radius--sm align-items--center ${
                              isActive ? 'bg--black-50' : ''
                            }`}
                            onClick={() => handleChatClick(chat)}
                          >
                            {typingUser?.[u?.id] ? (
                              <div
                                className="chtaAvtar radius--full bg--white text--primary d--flex align-items--center justify-content--center position--relative"
                                style={{
                                  height: '40px',
                                  width: '40px',
                                  minWidth: '40px',
                                }}
                              >
                                <Lottie
                                  animationData={ChatAnimation}
                                  loop={true}
                                />
                              </div>
                            ) : (
                              <Avatar
                                firstname={u?.firstname ?? ''}
                                lastname={u?.lastname ?? ''}
                                userId={u?.id}
                              />
                            )}

                            <div className="w--full d--flex flex--column align-items--center gap--xs">
                              <div className="w--full d--flex justify-content--between align-items--start">
                                <div className="w--full font--sm font--500 w-max--150 ellipsis">
                                  {u?.firstname ?? ''} {u?.lastname ?? ''}
                                </div>
                                {/* <div className=" font--xs font--500  text--black-600  d--flex align-items--center ">
                                  12/10/24
                                </div> */}
                              </div>
                              <div className="w--full d--flex justify-content--between align-items--center">
                                <div className="font--500 font--xs text--black-600 white--space-none">
                                  {chat?.claimId}
                                </div>
                                {chat?.unread > 0 ||
                                chat?.metaData?.unReads?.[user?._id] > 0 ? (
                                  <div className=" font--xs font--500 bg--primary text--black w-min--20 w-max--20 h-min--20 h-max--20 radius--xs d--flex align-items--center justify-content--center">
                                    {chat?.unread ||
                                      chat?.metaData?.unReads?.[user?._id]}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        );
                      }
                    });
                })
              : null}
          </div>
        </div>
        {chats && chats?.length > 0 ? (
          location?.pathname?.split('/')?.[2] ? (
            <Outlet />
          ) : (
            <div className="w--full h--full font--md font--600 d--flex align-items--center justify-content--center gap--sm">
              <ArrowLeftIcon />
              Select any agent to view chat
            </div>
          )
        ) : (
          <div className="w--full h--full font--md font--600 d--flex align-items--center justify-content--center">
            {t('translation:commonWords.NoRecordFound')}
          </div>
        )}
      </div>
    </div>
  );
};
